import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Cookies from 'js-cookie';
import LoadingSpinner from './LoadingSpinner';
import { toast } from 'react-toastify'; // Import toast for notifications
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import Terms from './Terms'; // Import the Terms component
import { FaXTwitter } from 'react-icons/fa6'; // Import Twitter icon

const API_URL = process.env.REACT_APP_API_URL; // Accessing the API URL from the environment variable

function PizzaShop() {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [processingTaskId, setProcessingTaskId] = useState(null);
    const [showTerms, setShowTerms] = useState(false);
    const [isLoadingButton, setIsLoadingButton] = useState(false);

    useEffect(() => {
        setLoading(false);
    }, []);

    const handleFollowClick = async (e) => {
        e.preventDefault();
        const twitterUrl = 'https://twitter.com/intent/follow?screen_name=PepeRuneyPizza';
        window.open(twitterUrl, '_blank', 'noopener,noreferrer');

        const authToken = Cookies.get('authToken');
        setIsLoadingButton(true);

        try {
            await fetch(`${API_URL}/api/tasks/view`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify({ task_id: 1 }),
            });

            await fetch(`${API_URL}/api/tasks/claim`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify({ task_id: 1 }),
                retryCount: 3,
                retryDelay: 1000,
                retryStatusCodes: [408, 500, 502, 503, 504]
            });

            setTimeout(() => {
                setShowTerms(true);
                setIsLoadingButton(false);
            }, 100);
        } catch (error) {
            console.error('Error during API calls:', error);
            toast.error('An error occurred while processing your request.');
            setIsLoadingButton(false);
        }
    };

    if (showTerms) {
        return (
            <div className="flex justify-center items-center">
                {isLoadingButton ? (
                    <LoadingSpinner small />
                ) : (
                    <Terms />
                )}
            </div>
        );
    }

    return (
        <div className="min-h-screen relative">
            {/* Logo Section */}
            <div className="flex justify-center px-4">
                <img
                    src={process.env.PUBLIC_URL + '/logo.PNG'}
                    alt="Peperuney Pizza"
                    className="w-full max-w-[450px] h-auto object-contain animate-fadeIn" // Increased from 250px to 350px
                />

            </div>
            <div className="pizza-shop-container p-3 rounded-lg mx-4 mt-0 shadow-md bg-white">
                <div className="space-y-6">
                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            {/* Title & Description */}
                            <div className="space-y-1">
                                <h1 className="text-2xl font-luckiest-guy text-center text-[#2f7821]">
                                    WELCOME TO THE $PEPERUNEY SLICE CLUB!
                                </h1>

                                <p className="text-black-600 text-center leading-[.90] font-regularmax-w-[85%] mx-auto" 
                                   style={{ fontFamily: 'Palmer Lake', fontSize: '20.9pt' }}>
                                    WE ARE BUILDING THE ULTIMATE 
                                    HANGOUT SPOT FOR THE BROS.
                                    THE HOME OF BAKE-TO-EARNS🍕, 
                                    DEGEN CASINO FUN🎰,
                                    AND EVERYTHING IN THE PEPERUNEY PIZZA RUNEYVERSE🤪.
                                </p>
                            </div>

                            {/* Twitter Section */}
                            <div className="space-y-2">
                                <p className="text-black-700 text-center font-medium mx-auto max-w-[80%]"
                                   style={{ 
                                       fontFamily: 'Palmer Lake',
                                       fontSize: '20.9pt',
                                       lineHeight: '1.25rem'
                                   }}>
                                    FOLLOW US ON X AND EARN YOUR FIRST PIZZAS:
                                </p>

                                <button
                                    onClick={handleFollowClick}
                                    disabled={isLoadingButton}
                                    className="w-full bg-[#2f7821] 
                                             text-white
                                             py-2 px-2 rounded-full
                                             flex items-center justify-center gap-3
                                             transition-all duration-300
                                             disabled:opacity-50
                                             active:scale-[0.98]
                                             shadow-md hover:shadow-lg"
                                >
                                    {isLoadingButton ? (
                                        <LoadingSpinner small />
                                    ) : (
                                        <>
                                            <FaXTwitter className="text-xl" />
                                            <span className="font-luckiest-guy" style={{ 
                                                marginTop: window.Telegram?.WebApp?.platform !== 'tdesktop' ? '6px' : '0'
                                            }}>
                                                Follow Us on X
                                            </span>
                                        </>
                                    )}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* Add main character */}
            <img
                src={window.Telegram?.WebApp?.platform !== 'tdesktop' 
                    ? 'main-character-mobile.PNG' 
                    : 'main-character.png'}
                alt="Main Character"
                className={`absolute bottom-0 left-0 h-auto z-10 ${
                    window.Telegram?.WebApp?.platform !== 'tdesktop'
                        ? 'w-52' // Larger for mobile
                        : 'w-48' // Original size for desktop
                }`}
                style={{ 
                    transform: window.Telegram?.WebApp?.platform !== 'tdesktop'
                        ? 'translateY(0)' // Flush against bottom for mobile
                        : 'translateY(20%)' // Keep original positioning for desktop
                }}
            />
        </div>
    );
}

export default PizzaShop;
import React from 'react';
import Footer from './Footer'; // Import Footer

function Layout({ children }) {
    return (
        <div className="App flex flex-col h-screen overflow-hidden touch-none">
            <div className="flex-grow pb-10 overflow-hidden">
                {children}
            </div>
        </div>
    );
}

export default Layout;

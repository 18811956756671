import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            "welcome": "Hi fren, {{name}}",
            "bakeNow": "Bake Now",
            "streak": "Streak: {{count}}",
            "nextBake": "Next Bake: {{time}}",
            "tagline": "Earn. HODL. Bake. Repeat.",
            "selectLanguage": "Select Language",
            "Dashboard": "Dashboard",
            "Streak": "Streak",
            "Tasks": "Tasks",
            "Pizza": "Pizza",
            "Betting": "Slots",
            "Rewards": "Rewards",
            "Wallet": "Wallet",
            "StreakConfig": "Streak Config",
            "InviteFriends": "Invite Friends",
            "TotalReferrals": "Total Referrals",
            "ConnectWallet": "Connect Wallet",
            "Points": "$PepeRuney Rewards",
            "Done": "Done",
            "Claim": "Claim",
            "View": "View",
            "Viewing": "Viewing...",
            "Referral": "Referral",
            "ConnectPhantomWallet": "Connect Phantom Wallet",
            "SolanaWallet": "PLEASE ADD YOUR SOLANA WALLET ADDRESS FOR AIRDROP ALLOCATION, TOKEN HOLDER INCENTIVES INCLUDING HODL BONUSES, AND TO BECOME A SLICE CLUB MEMBER",
            "AddWalletPrefix": "Add ",
            "SolanaText": "SOLANA",
            "AddWalletSuffix": " WALLET",
            "AddWallet": "Add Solana Wallet",
            "WalletInfo": "To add the Solana wallet, use the Telegram mini app on your mobile device.",
            "BettingHub": "Betting Hub",
            "BetSmart": "Bet smart, Win big!",
            "ExploreBet": "COMING SOON: PVP Rock Paper Scissors, LOTTOS, FLIPCOIN, AND MORE!",
            "sen1": "COMMUNITY BUILT TELEGRAM MINI APP DESIGNED TO BRING DEGEN EGAMES ON SOLANA TO THE TELEGRAM COMMUNITY.",
            "sen2": "THINK STAKE AND POLYMARKET WITH BONKBOT THAT ALLOWS USERS TO DEPOSIT, WITHDRAW, AND HAVE FUN",
            "sen3": "REMEMBER BY JOINING THE SLICE CLUB CASINO BOT YOU ARE STILL OBLIGATED TO OUR TERMS AND CONDITIONS",
            "sliceClubModal": "THE SLICE CLUB CASINO OPERATES THROUGH THE $PEPERUNEY TOKEN AND WILL BE AVAILABLE IN THE WEEKS AFTER TOKEN LAUNCH!",
            "sliceClubModalTitle": "SLICE CLUB CASINO",
            "Disp1": "Keep baking those slices, soldier. $PepeRuney rewards don’t mint themselves. Stack 'em or snack 'em.",
            "Disp2":  "Hot oven, hotter wallet. Bake now, flex later. 🍕💸",
            "Disp3": "You’re not just baking pizzas; you’re cooking generational wealth, one slice at a time. Don’t stop now.",
            "Disp4":  "Pizza? Delicious. $PepeRuney rewards? Legendary. Earn the slice or regret it forever.",
            "Disp5":  "The oven’s open, and so is the door to meme glory. Bake your way to the top, champ.",
            "Disp6":  "A pizza party isn’t a party without the homies. Share the slice, grow the vibes. 🍕👊",
            "Disp7": "Your squad called—they want in on the pizza grind. Share the bake-to-earn secret, and flex together.",
            "Disp8":  "Invite your friends, mint more slices, get richer together. The pizza gods reward generosity (and $PepeRuney stacks)",
            "Disp9":  "More slices, more dough. Bring your crew, stack rewards, and let the memes fly.",
            "Disp10":  "Pizza without friends is like a rug pull—sad and empty. Fix it. Invite your people to mint.",
            "Disp11":  "Mint another slice. It’s what PepeRuney would do. Don’t disappoint the Chief.",
            "Disp12":  "Every slice baked is one step closer to meme coin king status. Are you just a baker or a legend in the making?",
            "Disp13":  "Losers miss the slice, winners mint and share. Be the winner. Don’t let your oven go cold.",
            "Disp14":  "Bake. Share. Conquer. It’s not just pizza—it’s the path to meme greatness.",
            "Disp15":  "Your wallet’s hungry, and your friends are slacking. Feed both by sharing the slice hustle.",
            "peplost": "⚠️ $PepeRuney Rewards will be lost if emojis are removed.",
            "pepslice": "Add the slice club emojis to your telegram name 🐸🍕",
            "morefren": "more fren for your next",
            "preward": "$Peperuney Rewards for your last",
            "rels": "referrals! 🎉",
            "you_earned": "You earned",
            "congrats": "🎉 Congratulations! 🎉",
            "invt": "Invite",
            "lod": "Loading...",
            "spin_earn": "Spin daily and earn",
            "sr_reward": "Streak Rewards 🔥",
            "sr_how": "How Streaks Work",
            "sr_how_explain": `Keep your streak alive by baking pizza daily! The longer your streak, the more points you earn.
                        Each level unlocks bigger rewards and exclusive benefits.`,
            "sr_daily": "🎯 Daily Check-in",
            "bake_expl": "Bake pizza every day to maintain your streak",
            "h_streak": "Higher streaks multiply your point earnings",
            "sr_earn": "💎 Earn Points",
            "sr_level": "🏆 Level Up",
            "sr_level_expl": "Unlock special rewards at milestone streaks",
            "se_lvl": "Streak Level 🔥",
            "se_points": "Points Reward",
            // Add more English translations here
        }
    },
    es: {
        translation: {
            "welcome": "Hola amigo, {{name}}",
            "bakeNow": "Hornear ahora",
            "streak": "Racha: {{count}}",
            "nextBake": "Próximo horneo: {{time}}",
            "tagline": "Hornea a diario. Construye rachas. Gana tokens.",
            "selectLanguage": "Seleccionar idioma",
            "Dashboard": "Tablero",
            "Streak": "Racha",
            "Tasks": "Tareas",
            "Pizza": "Pizza",
            "Betting": "Apuestas",
            "Rewards": "Recompensas",
            "Wallet": "Cartera",
            "StreakConfig": "Configuración de Racha",
            "InviteFriends": "Invitar Amigos",
            "TotalReferrals": "Referidos Totales",
            "ConnectWallet": "Conectar Cartera",
            "Points": "Puntos",
            "Done": "Hecho",
            "Claim": "Reclamar",
            "View": "Ver",
            "Viewing": "Viendo...",
            "Referral": "Referencia",
            "ConnectPhantomWallet": "Conectar Phantom Wallet",
            "SolanaWallet": "Por favor, proporcione su dirección de billetera Solana para recibir asignaciones de airdrop y otras oportunidades futuras.",
            "AddWallet": "Agregar Billetera Solana",
            "WalletInfo": "Para agregar la billetera de Solana, utilice la mini aplicación de Telegram en su dispositivo móvil.",
            "BettingHub": "Centro de Apuestas",
            "BetSmart": "Apuesta inteligente, ¡Gana a lo grande!",
            "ExploreBet": "Explora el contexto de apuestas",
            "sen1": "Mini aplicación de Telegram diseñada para llevar mercados de predicción descentralizados a los usuarios.",
            "sen2": "Los usuarios hacen predicciones sobre los resultados de eventos seleccionados, aprovechando su conocimiento y comprensión de las tendencias del mercado.",
            "sen3": "Las predicciones exitosas son recompensadas con criptomonedas, que pueden retirarse o reinvertirse en futuras apuestas.",
            "Disp1": "Ipsum Lupsum Ipsum Lupsum 1 Es",
            "Disp2": "Ipsum Lupsum 2 Es",
            "Disp3": "Ipsum Lupsum 3 Es",
            "Disp4": "Ipsum Lupsum 4 Es",
            "Disp5": "Ipsum Lupsum 5 Es",
            "Disp6": "Ipsum Lupsum 6 Es",
            "Disp7": "Ipsum Lupsum 7 Es",
            "Disp8": "Ipsum Lupsum 8 Es",
            "Disp9": "Ipsum Lupsum 9 Es",
            "Disp10": "Ipsum Lupsum 10 Es",
            "Disp11": "Ipsum Lupsum 11 Es",
            "Disp12": "Ipsum Lupsum 12 Es",
            "Disp13": "Ipsum Lupsum 13 Es",
            "Disp14": "Ipsum Lupsum 14 Es",
            "Disp15": "Ipsum Lupsum 15 Es",
            "peplost": "⚠️ $PepeRuney Rewards will be lost if emojis are removed.",
            "pepslice": "Add the slice club  emojis to your telegram name 🐸🍕",
            "morefren": "more fren for your next",
            "preward": "$Peperuney Rewards for your last",
            "rels": "referrals! 🎉",
            "you_earned": "You earned",
            "congrats": "🎉 Congratulations! 🎉",
            "invt": "Invite",
            "lod": "Loading...",
            "spin_earn": "Spin daily and earn",
            "sr_reward": "Streak Rewards 🔥",
            "sr_how": "How Streaks Work",
            "sr_how_explain": `Keep your streak alive by baking pizza daily! The longer your streak, the more points you earn.
                        Each level unlocks bigger rewards and exclusive benefits.`,
            "sr_daily": "🎯 Daily Check-in",
            "bake_expl": "Bake pizza every day to maintain your streak",
            "h_streak": "Higher streaks multiply your point earnings",
            "sr_earn": "💎 Earn Points",
            "sr_level": "🏆 Level Up",
            "sr_level_expl": "Unlock special rewards at milestone streaks",
            "se_lvl": "Streak Level 🔥",
            "se_points": "PEPERUNEY REWARDS",
            // Add more Spanish translations here
        }
    },
    fr: {
        translation: {
            "welcome": "Salut l'ami, {{name}}",
            "bakeNow": "Cuire maintenant",
            "streak": "Série: {{count}}",
            "nextBake": "Prochaine cuisson: {{time}}",
            "tagline": "Cuisinez quotidiennement. Construisez des séries. Gagnez des jetons.",
            "selectLanguage": "Choisir la langue",
            "Dashboard": "Tableau de bord",
            "Streak": "Série",
            "Tasks": "Tâches",
            "Pizza": "Pizza",
            "Betting": "Pari",
            "Rewards": "Récompenses",
            "Wallet": "Portefeuille",
            "StreakConfig": "Configuration de Série",
            "InviteFriends": "Inviter des Amis",
            "TotalReferrals": "Références Totales",
            "ConnectWallet": "Connecter le Portefeuille",
            "Points": "Points",
            "Done": "Fait",
            "Claim": "Réclamer",
            "View": "Voir",
            "Viewing": "Visualisation...",
            "Referral": "Référence",
            "ConnectPhantomWallet": "Connecter le portefeuille Phantom",
            "SolanaWallet": "Veuillez fournir votre adresse de portefeuille Solana pour recevoir les allocations d'airdrop et d'autres opportunités futures.",
            "AddWallet": "Ajouter un portefeuille Solana",
            "WalletInfo": "Pour ajouter le portefeuille Solana, veuillez utiliser la mini application Telegram sur votre appareil mobile.",
            "BettingHub": "Centre de Paris",
            "BetSmart": "Pariez intelligemment, Gagnez gros!",
            "ExploreBet": "Explorez le contexte des paris",
            "sen1": "Mini application Telegram conçue pour amener les marchés de prédiction décentralisés aux utilisateurs.",
            "sen2": "Les utilisateurs font des prédictions sur les résultats d'événements sélectionnés, tirant parti de leurs connaissances et de leur compréhension des tendances du marché.",
            "sen3": "Les prédictions réussies sont récompensées par des cryptomonnaies, qui peuvent être retirées ou réinvesties dans des paris futurs.",
            "Disp1": "Ipsum Lupsum Ipsum Lupsum 1",
            "Disp2": "Ipsum Lupsum Ipsum Lupsum 2",
            "Disp3": "Ipsum Lupsum Ipsum Lupsum 3",
            "Disp4": "Ipsum Lupsum Ipsum Lupsum 4",
            "Disp5": "Ipsum Lupsum Ipsum Lupsum 5",
            "Disp6": "Ipsum Lupsum Ipsum Lupsum 6",
            "Disp7": "Ipsum Lupsum Ipsum Lupsum 7",
            "Disp8": "Ipsum Lupsum Ipsum Lupsum 8",
            "Disp9": "Ipsum Lupsum Ipsum Lupsum 9",
            "Disp10": "Ipsum Lupsum Ipsum Lupsum 10",
            "Disp11": "Ipsum Lupsum Ipsum Lupsum 11",
            "Disp12": "Ipsum Lupsum Ipsum Lupsum 12",
            "Disp13": "Ipsum Lupsum Ipsum Lupsum 13",
            "Disp14": "Ipsum Lupsum Ipsum Lupsum 14",
            "Disp15": "Ipsum Lupsum Ipsum Lupsum 15",
            "peplost": "⚠️ $PepeRuney Rewards will be lost if emojis are removed.",
            "pepslice": "Add the slice club  emojis to your telegram name 🐸🍕",
            "morefren": "more fren for your next",
            "preward": "$Peperuney Rewards for your last",
            "rels": "referrals! 🎉",
            "you_earned": "You earned",
            "congrats": "🎉 Congratulations! 🎉",
            "invt": "Invite",
            "lod": "Loading...",
            "spin_earn": "Spin daily and earn",
            "sr_reward": "Streak Rewards 🔥",
            "sr_how": "How Streaks Work",
            "sr_how_explain": `Keep your streak alive by baking pizza daily! The longer your streak, the more points you earn.
                        Each level unlocks bigger rewards and exclusive benefits.`,
            "sr_daily": "🎯 Daily Check-in",
            "bake_expl": "Bake pizza every day to maintain your streak",
            "h_streak": "Higher streaks multiply your point earnings",
            "sr_earn": "💎 Earn Points",
            "sr_level": "🏆 Level Up",
            "sr_level_expl": "Unlock special rewards at milestone streaks",
            "se_lvl": "Streak Level 🔥",
            "se_points": "Points Reward",
            // Add more French translations here
        }
    },
    hi: {
        translation: {
            "welcome": "नमस्ते दोस्त, {{name}}",
            "bakeNow": "अभी बेक करें",
            "streak": "स्ट्रिक: {{count}}",
            "nextBake": "अगला बेक: {{time}}",
            "tagline": "रोज बेक करें। स्ट्रिक बनाएं। टोकन कमाएं।",
            "selectLanguage": "भाषा चुनें",
            "Dashboard": "डैशबोर्ड",
            "Streak": "स्ट्रिक",
            "Tasks": "कार्य",
            "Pizza": "पिज्जा",
            "Betting": "शर्त",
            "Rewards": "पुरस्कार",
            "Wallet": "वॉलेट",
            "StreakConfig": "स्ट्रिक कॉन्फ़िग",
            "InviteFriends": "दोस्तों को आमंत्रित करें",
            "TotalReferrals": "कुल रेफ़रल",
            "ConnectWallet": "वॉलेट कनेक्ट करें",
            "Points": "अंक",
            "Done": "पूरा",
            "Claim": "दावा करें",
            "View": "देखें",
            "Viewing": "देख रहे हैं...",
            "Referral": "संदर्भ",
            "ConnectPhantomWallet": "फैंटम वॉलेट कनेक्ट करें",
            "SolanaWallet": "कृपया एयरड्रॉप आवंटन और अन्य भविष्य के अवसरों के लिए अपना Solana वॉलेट पता जोड़ें।",
            "AddWallet": "Solana वॉलेट जोड़ें",
            "WalletInfo": "Solana वॉलेट जोड़ने के लिए, कृपया अपने मोबाइल डिवाइस पर Telegram मिनी ऐप का उपयोग करें।",
            "BettingHub": "सट्टेबाजी हब",
            "BetSmart": "स्मार्ट दांव लगाओ, बड़ा जीतें!",
            "ExploreBet": "सट्टेबाजी का संदर्भ खोजें",
            "sen1": "टेलीग्राम मिनी ऐप जो उपयोगकर्ताओं के लिए विकेंद्रीकृत भविष्यवाणी बाजार लाने के लिए डिज़ाइन किया गया है।",
            "sen2": "उपयोगकर्ता चयनित घटनाओं के परिणामों के बारे में भविष्यवाणियां करते हैं, अपनी बाजार प्रवृत्तियों की समझ और अंतर्दृष्टि का लाभ उठाते हुए।",
            "sen3": "सफल भविष्यवाणियों को क्रिप्टोकरेंसी के साथ पुरस्कृत किया जाता है, जिसे निकाला जा सकता है या भविष्य के दांव में पुनः निवेश किया जा सकता है।",
            "Disp1": "Ipsum Lupsum Ipsum Lupsum 1",
            "Disp2": "Ipsum Lupsum Ipsum Lupsum 2",
            "Disp3": "Ipsum Lupsum Ipsum Lupsum 3",
            "Disp4": "Ipsum Lupsum Ipsum Lupsum 4",
            "Disp5": "Ipsum Lupsum Ipsum Lupsum 5",
            "Disp6": "Ipsum Lupsum Ipsum Lupsum 6",
            "Disp7": "Ipsum Lupsum Ipsum Lupsum 7",
            "Disp8": "Ipsum Lupsum Ipsum Lupsum 8",
            "Disp9": "Ipsum Lupsum Ipsum Lupsum 9",
            "Disp10": "Ipsum Lupsum Ipsum Lupsum 10",
            "Disp11": "Ipsum Lupsum Ipsum Lupsum 11",
            "Disp12": "Ipsum Lupsum Ipsum Lupsum 12",
            "Disp13": "Ipsum Lupsum Ipsum Lupsum 13",
            "Disp14": "Ipsum Lupsum Ipsum Lupsum 14",
            "Disp15": "Ipsum Lupsum Ipsum Lupsum 15",
            "peplost": "⚠️ $PepeRuney Rewards will be lost if emojis are removed.",
            "pepslice": "Add the slice club  emojis to your telegram name 🐸🍕",
            "morefren": "more fren for your next",
            "preward": "$Peperuney Rewards for your last",
            "rels": "referrals! 🎉",
            "you_earned": "You earned",
            "congrats": "🎉 Congratulations! 🎉",
            "invt": "Invite",
            "lod": "Loading...",
            "spin_earn": "Spin daily and earn",
            "sr_reward": "Streak Rewards 🔥",
            "sr_how": "How Streaks Work",
            "sr_how_explain": `Keep your streak alive by baking pizza daily! The longer your streak, the more points you earn.
                        Each level unlocks bigger rewards and exclusive benefits.`,
            "sr_daily": "🎯 Daily Check-in",
            "bake_expl": "Bake pizza every day to maintain your streak",
            "h_streak": "Higher streaks multiply your point earnings",
            "sr_earn": "💎 Earn Points",
            "sr_level": "🏆 Level Up",
            "sr_level_expl": "Unlock special rewards at milestone streaks",
            "se_lvl": "Streak Level 🔥",
            "se_points": "Points Reward",


            // Add more English translations here
        }
    },
    ch: {
        translation: {
            "welcome": "你好, {{name}}",
            "bakeNow": "立即烤制",
            "streak": "连胜：{{count}}",
            "nextBake": "下次烤制：{{time}}",
            "tagline": "每日烤制，建立连胜，赚取代币。",
            "selectLanguage": "选择语言",
            "Dashboard": "仪表盘",
            "Streak": "连胜",
            "Tasks": "任务",
            "Pizza": "披萨",
            "Betting": "投注",
            "Rewards": "奖励",
            "Wallet": "钱包",
            "StreakConfig": "连胜配置",
            "InviteFriends": "邀请朋友",
            "TotalReferrals": "总推荐人数",
            "ConnectWallet": "连接钱包",
            "Points": "积分",
            "Done": "完成",
            "Claim": "领取",
            "View": "查看",
            "Viewing": "查看中...",
            "Referral": "推荐",
            "ConnectPhantomWallet": "连接 Phantom 钱包",
            "SolanaWallet": "请添加您的 Solana 钱包地址，以接收空投分配和其他未来的机会。",
            "AddWallet": "添加 Solana 钱包",
            "WalletInfo": "要添加 Solana 钱包，请在您的移动设备上使用 Telegram 迷你应用程序。",
            "BettingHub": "投注中心",
            "BetSmart": "聪明投注，赢取大奖！",
            "ExploreBet": "探索投注背景",
            "sen1": "这是一个旨在为用户带来去中心化预测市场的Telegram迷你应用。",
            "sen2": "用户根据他们对市场趋势的理解和洞察，预测所选事件的结果。",
            "sen3": "成功的预测将获得加密货币奖励，可以提现或在未来的投注中再次使用。",
            "Disp1": "Ipsum Lupsum Ipsum Lupsum 1",
            "Disp2": "Ipsum Lupsum Ipsum Lupsum 2",
            "Disp3": "Ipsum Lupsum Ipsum Lupsum 3",
            "Disp4": "Ipsum Lupsum Ipsum Lupsum 4",
            "Disp5": "Ipsum Lupsum Ipsum Lupsum 5",
            "Disp6": "Ipsum Lupsum Ipsum Lupsum 6",
            "Disp7": "Ipsum Lupsum Ipsum Lupsum 7",
            "Disp8": "Ipsum Lupsum Ipsum Lupsum 8",
            "Disp9": "Ipsum Lupsum Ipsum Lupsum 9",
            "Disp10": "Ipsum Lupsum Ipsum Lupsum 10",
            "Disp11": "Ipsum Lupsum Ipsum Lupsum 11",
            "Disp12": "Ipsum Lupsum Ipsum Lupsum 12",
            "Disp13": "Ipsum Lupsum Ipsum Lupsum 13",
            "Disp14": "Ipsum Lupsum Ipsum Lupsum 14",
            "Disp15": "Ipsum Lupsum Ipsum Lupsum 15",
            "peplost": "⚠️ $PepeRuney Rewards will be lost if emojis are removed.",
            "pepslice": "Add the slice club  emojis to your telegram name 🐸🍕",
            "morefren": "more fren for your next",
            "preward": "$Peperuney Rewards for your last",
            "rels": "referrals! 🎉",
            "you_earned": "You earned",
            "congrats": "🎉 Congratulations! 🎉",
            "invt": "Invite",
            "lod": "Loading...",
            "spin_earn": "Spin daily and earn",
            "sr_reward": "Streak Rewards 🔥",
            "sr_how": "How Streaks Work",
            "sr_how_explain": `Keep your streak alive by baking pizza daily! The longer your streak, the more points you earn.
                        Each level unlocks bigger rewards and exclusive benefits.`,
            "sr_daily": "🎯 Daily Check-in",
            "bake_expl": "Bake pizza every day to maintain your streak",
            "h_streak": "Higher streaks multiply your point earnings",
            "sr_earn": "💎 Earn Points",
            "sr_level": "🏆 Level Up",
            "sr_level_expl": "Unlock special rewards at milestone streaks",
            "se_lvl": "Streak Level 🔥",
            "se_points": "Points Reward",
            // Add more English translations here
        }
    },
    ru: {
        translation: {
            "welcome": "Привет, {{name}}",
            "bakeNow": "Печь сейчас",
            "streak": "Серия: {{count}}",
            "nextBake": "Следующая выпечка: {{time}}",
            "tagline": "Пеки каждый день. Создавай серии. Зарабатывай токены.",
            "selectLanguage": "Выбрать язык",
            "Dashboard": "Панель управления",
            "Streak": "Серия",
            "Tasks": "Задачи",
            "Pizza": "Пицца",
            "Betting": "Ставки",
            "Rewards": "Награды",
            "Wallet": "Кошелек",
            "StreakConfig": "Настройки серии",
            "InviteFriends": "Пригласить друзей",
            "TotalReferrals": "Всего рефералов",
            "ConnectWallet": "Подключить кошелек",
            "Points": "Очки",
            "Done": "Готово",
            "Claim": "Забрать",
            "View": "Просмотр",
            "Viewing": "Просмотр...",
            "Referral": "Реферал",
            "ConnectPhantomWallet": "Подключить Phantom кошелек",
            "SolanaWallet": "Пожалуйста, добавьте ваш адрес Solana кошелька для начисления и других будущих возможностей.",
            "AddWallet": "Добавить Solana кошелек",
            "WalletInfo": "Для добавления Solana кошелька используйте мини-приложение Telegram на вашем мобильном устройстве.",
            "BettingHub": "Центр ставок",
            "BetSmart": "Ставь разумно, выигрывай больше!",
            "ExploreBet": "Исследовать контекст ставок",
            "sen1": "Мини-приложение Telegram, созданное для того, чтобы принести децентрализованные рынки предсказаний пользователям.",
            "sen2": "Пользователи делают прогнозы по результатам выбранных событий, используя свои знания и понимание рыночных трендов.",
            "sen3": "Успешные прогнозы вознаграждаются криптовалютой, которую можно вывести или вложить в будущие ставки.",
            "Disp1": "Ипсум Лупсум Ипсум Лупсум 1",
            "Disp2": "Ипсум Лупсум Ипсум Лупсум 2",
            "Disp3": "Ипсум Лупсум Ипсум Лупсум 3",
            "Disp4": "Ипсум Лупсум Ипсум Лупсум 4",
            "Disp5": "Ипсум Лупсум Ипсум Лупсум 5",
            "Disp6": "Ипсум Лупсум Ипсум Лупсум 6",
            "Disp7": "Ипсум Лупсум Ипсум Лупсум 7",
            "Disp8": "Ипсум Лупсум Ипсум Лупсум 8",
            "Disp9": "Ипсум Лупсум Ипсум Лупсум 9",
            "Disp10": "Ипсум Лупсум Ипсум Лупсум 10",
            "Disp11": "Ипсум Лупсум Ипсум Лупсум 11",
            "Disp12": "Ипсум Лупсум Ипсум Лупсум 12",
            "Disp13": "Ипсум Лупсум Ипсум Лупсум 13",
            "Disp14": "Ипсум Лупсум Ипсум Лупсум 14",
            "Disp15": "Ипсум Лупсум Ипсум Лупсум 15",
            "peplost": "⚠️ $PepeRuney Rewards will be lost if emojis are removed.",
            "pepslice": "Add the slice club  emojis to your telegram name 🐸🍕",
            "morefren": "more fren for your next",
            "preward": "$Peperuney Rewards for your last",
            "rels": "referrals! 🎉",
            "you_earned": "You earned",
            "congrats": "🎉 Congratulations! 🎉",
            "invt": "Invite",
            "lod": "Loading...",
            "spin_earn": "Spin daily and earn",
            "sr_reward": "Streak Rewards 🔥",
            "sr_how": "How Streaks Work",
            "sr_how_explain": `Keep your streak alive by baking pizza daily! The longer your streak, the more points you earn.
                        Each level unlocks bigger rewards and exclusive benefits.`,
            "sr_daily": "🎯 Daily Check-in",
            "bake_expl": "Bake pizza every day to maintain your streak",
            "h_streak": "Higher streaks multiply your point earnings",
            "sr_earn": "💎 Earn Points",
            "sr_level": "🏆 Level Up",
            "sr_level_expl": "Unlock special rewards at milestone streaks",
            "se_lvl": "Streak Level 🔥",
            "se_points": "Points Reward",
            // Add more English translations here
        }
    },
    // Add more languages as needed
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en", // Default language
        fallbackLng: "en", // Fallback language
        interpolation: {
            escapeValue: false // React already safes from xss
        }
    });

export default i18n;

import React, { useEffect, useState } from 'react'; // Ensure useEffect and useState are imported
import Footer from './Footer'
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import LoadingSpinner from './LoadingSpinner'; // Ensure LoadingSpinner is imported
import { useTranslation } from 'react-i18next';
import ReactConfetti from 'react-confetti';

export default function Referral({uncompletedTasksCount}) {
    const { t } = useTranslation();
    const API_URL = process.env.REACT_APP_API_URL; // Accessing the API URL from the environment variable
    const [referralCode, setReferralCode] = useState(""); // State for referral code
    const [loading, setLoading] = useState(true); // State for loading effect
    const [totalReferrals, setTotalReferrals] = useState(0); // State for total referrals
    const [showCelebration, setShowCelebration] = useState(false);
    const [windowDimension, setWindowDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [showTooltip, setShowTooltip] = useState(false);
    const [activeReferralPoints, setActiveReferralPoints] = useState(0);
    const [showClaimModal, setShowClaimModal] = useState(false);
    const [claimLoading, setClaimLoading] = useState(false);
    const [claimData, setClaimData] = useState(null);
    const [referredUsers, setReferredUsers] = useState([]);
    const [showReferralSquad, setShowReferralSquad] = useState(false);

    useEffect(() => {
        const fetchReferralCode = async () => { // Changed to async function
            const authToken = Cookies.get('authToken');
            if (!authToken) {
                console.error('No auth token found');
                setLoading(false);
                return;
            }
            try {
                const response = await fetch(`${API_URL}/api/user/referral-code`, { // Fetch referral code
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`, // Include auth token in headers 
                    },
                    retryCount: 3,
                    retryDelay: 1000,
                    retryStatusCodes: [408, 500, 502, 503, 504]
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                console.log('recieved data', data);
                setReferralCode(data.referralCode); // Set the fetched referral code
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setLoading(false); // Ensure loading state is reset
            }
        };

        const fetchReferralStats = async () => { // New function to fetch referral stats
            const authToken = Cookies.get('authToken');
            if (!authToken) {
                setReferredUsers([]); // Ensure we set empty array if no auth
                return;
            }

            try {
                const response = await fetch(`${API_URL}/api/user/referral-stats`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`,
                    },
                    retryCount: 3,
                    retryDelay: 1000,
                    retryStatusCodes: [408, 500, 502, 503, 504]
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                console.log('Referral stats response:', data); // Add logging

                // Ensure we have the correct data structure
                if (data && Array.isArray(data.referred_users)) {
                    setReferredUsers(data.referred_users);
                } else {
                    console.error('Invalid referred_users data structure:', data);
                    setReferredUsers([]); // Fallback to empty array
                }
                
                setTotalReferrals(data.total_referrals || 0);
                setActiveReferralPoints(data.active_referral_points || 0);
            } catch (error) {
                console.error('Error fetching referral stats:', error);
                setReferredUsers([]); // Ensure we set empty array on error
            }
        };

        fetchReferralCode(); // Call the function
        fetchReferralStats(); // Call the new function
        // Initialize Telegram WebApp SDK and configure the back button
        const tg = window.Telegram?.WebApp;
        if (tg) {
            tg.BackButton.show(); // Show the back button
            tg.BackButton.onClick(() => {
                console.log("Back button clicked!");
                window.history.back(); // Go back when the button is clicked
            });

            // Cleanup when the component unmounts
            return () => {
                tg.BackButton.hide();
                tg.BackButton.offClick(); // Remove the event listener
            };
        }
    }, []); // Empty dependency array to run on mount

    useEffect(() => {
        const handleResize = () => {
            setWindowDimension({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    const copyReferralLink = () => {
        navigator.clipboard.writeText(`https://t.me/peperuney_bot/gameapp?startapp=${referralCode}`);
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 2000); // Hide tooltip after 2 seconds
    };

    const getProgressHeight = (totalReferrals) => {
        if (totalReferrals >= 100) return '100%';
        if (totalReferrals >= 90) return '90%';
        if (totalReferrals >= 80) return '80%';
        if (totalReferrals >= 70) return '70%';
        if (totalReferrals >= 60) return '60%';
        if (totalReferrals >= 50) return '50%';
        if (totalReferrals >= 40) return '40%';
        if (totalReferrals >= 30) return '30%';
        if (totalReferrals >= 20) return '20%';
        if (totalReferrals >= 10) return '15%';
        if (totalReferrals >= 5) return '10%';
        return '5%';
    };

    const handleClaimPoints = async () => {
        setClaimLoading(true);
        const authToken = Cookies.get('authToken');
        
        try {
            const response = await fetch(`${API_URL}/api/user/claim-referral-points`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                }
            });

            const data = await response.json();
            
            if (response.ok) {
                setActiveReferralPoints(0);
                setClaimData(data);
                setShowCelebration(true);
                setTimeout(() => setShowCelebration(false), 5000);
            } else {
                console.error('Failed to claim points:', data.error);
            }
        } catch (error) {
            console.error('Error claiming points:', error);
        } finally {
            setClaimLoading(false);
        }
    };

    const getRandomEmoji = () => {
        const emojis = ['🍕', '🎮', '🎲', '🎯', '🎪', '🎨', '🎭', '🎪', '🎫', '🎪'];
        return emojis[Math.floor(Math.random() * emojis.length)];
    };

    if (loading) return (
        <div className="flex justify-center items-center h-screen"> {/* Center the loading spinner */}
            <LoadingSpinner />
        </div>
    ); // Loading effects 

    return (
        <>
            {/* Celebration Modal */}
            {showCelebration && (
                <>
                    <ReactConfetti
                        width={windowDimension.width}
                        height={windowDimension.height}
                        recycle={false}
                        numberOfPieces={200}
                    />
                    <div className="fixed inset-0 bg-black/75 flex items-center justify-center z-40">
                        <div className="bg-gradient-to-br from-[#2f7821] to-[#1a4412] p-8 rounded-2xl 
                                    transform transition-all duration-300 ease-in-out
                                    max-w-md w-11/12 mx-auto shadow-xl border-2 border-[#3d9929]">
                            <div className="text-center">
                                <h2 className="text-3xl font-luckiest-guy text-white mb-4">
                                    {t('CONGRATULATIONS!')}
                                </h2>
                                <div className="space-y-1 mb-4">
                                    <div className="flex items-center justify-between p-3 rounded-lg border-b border-white/20">
                                        <span className="font-luckiest-guy text-white">{t('CLAIMED')}</span>
                                        <span className="text-2xl font-luckiest-guy text-[#00ff00]">
                                            +{claimData?.points_claimed || 0} <img src="/spin-coin.gif" alt="coin" className="inline-block w-6 h-6" />
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-between p-3 rounded-lg border-b border-white/20">
                                        <span className="font-luckiest-guy text-white">{t('TOTAL BALANCE')}</span>
                                        <span className="text-2xl font-luckiest-guy text-white">
                                            {claimData?.total_points || 0} <img src="/spin-coin.gif" alt="coin" className="inline-block w-6 h-6" />
                                        </span>
                                    </div>
                                </div>
                                <button
                                    onClick={() => setShowCelebration(false)}
                                    className="bg-[#00ff00] hover:bg-[#00cc00] text-black py-3 px-8 
                                             rounded-full text-lg font-luckiest-guy transition-all 
                                             duration-300 transform hover:scale-105 
                                             shadow-lg border-2 border-[#00cc00]"
                                >
                                    {t('AWESOME!')}
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {/* Active Referral Points Modal */}
            {activeReferralPoints > 0 && (
                <>
                    <ReactConfetti
                        width={windowDimension.width}
                        height={windowDimension.height}
                        recycle={false}
                        numberOfPieces={200}
                    />
                    <div className="fixed inset-0 bg-black/75 flex items-center justify-center z-50">
                        <div className="bg-gradient-to-br from-[#2f7821] to-[#1a4412] p-8 rounded-2xl 
                                    transform transition-all duration-300 ease-in-out
                                    max-w-md w-11/12 mx-auto shadow-xl border-2 border-[#3d9929]">
                            <div className="text-center">
                                <h2 className="text-3xl font-luckiest-guy text-white mb-4">
                                    {t('Referral Rewards Available!')}
                                </h2>
                                <p className="text-2xl text-white mb-6 font-luckiest-guy">
                                    <span className="text-[#00ff00]">💰{activeReferralPoints}</span> 
                                    {t(' points ready to claim!')}
                                </p>
                                <button
                                    onClick={handleClaimPoints}
                                    disabled={claimLoading}
                                    className="bg-[#00ff00] hover:bg-[#00cc00] text-black py-3 px-8 
                                             rounded-full text-lg font-luckiest-guy transition-all 
                                             duration-300 transform hover:scale-105 
                                             disabled:opacity-50 disabled:cursor-not-allowed
                                             shadow-lg border-2 border-[#00cc00]"
                                >
                                    {claimLoading ? t('CLAIMING...') : t('CLAIM REWARDS')}
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {/* Main Content */}
            <div className="flex flex-col items-center px-4">
                <div className="w-full max-w-2xl">
                    <div className="text-center p-2 rounded-lg m-1 shadow-md">
                        <div className="relative inline-flex items-center flex-col">
                            <div className="flex items-center mb-4">
                                <h1 className="text-3xl font-luckiest-guy text-white">INVITE FRENS</h1>
                                <div className="ml-3 min-w-[2rem] h-7 px-2 bg-[#ed1312] rounded-full flex items-center justify-center">
                                    <span className="font-luckiest-guy text-white text-[1.25rem] whitespace-nowrap">
                                        {totalReferrals}
                                    </span>
                                </div>
                            </div>
                            
                            <div className="relative flex items-center justify-center mt-2 mb-2">
                                <img 
                                    src={"pep_del.gif"} 
                                    className="app-logos inline-block w-48 h-48 mr-8" 
                                    alt="Peperuney Pizza Club" 
                                />
                                
                                {/* Vertical Progress Bar */}
                                <div className="h-48 w-6 bg-white rounded-full overflow-hidden relative">
                                    <div
                                        className="absolute bottom-0 left-0 right-0 lava-progress transition-all duration-500 ease-out flex items-end justify-center"
                                        style={{
                                            height: getProgressHeight(totalReferrals)
                                        }}
                                    >
                                        {((totalReferrals % 5) / 5) * 100 === 100 && <span className="text-sm">🍕</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4 mb-6">
                            <div className="bg-white/10 backdrop-blur-sm p-3 rounded-xl  border-[#2f7821] transform transition-all hover:scale-105">
                                <div className="flex items-center justify-between mb-2">
                                    <span className="text-lg font-luckiest-guy text-white">DIRECT</span>
                                    <span className="text-xl font-luckiest-guy text-[#00ff00]">💰500</span>
                                </div>
                                <p className="text-sm font-palmer-lake text-white/80 leading-tight">
                                    FOR EVERY 5 FRENS YOU INVITE
                                </p>
                            </div>

                            <div className="bg-white/5 backdrop-blur-sm p-3 rounded-xl border-[#2f7821]/50 transform transition-all hover:scale-105">
                                <div className="flex items-center justify-between mb-2">
                                    <span className="text-lg font-luckiest-guy text-white">CROSS</span>
                                    <span className="text-xl font-luckiest-guy text-[#00ff00]">💰100</span>
                                </div>
                                <p className="text-sm font-palmer-lake text-white/80 leading-tight">
                                    FOR EVERY 5 FRENS THEY INVITE
                                </p>
                            </div>
                        </div>

                        <div className="flex flex-col items-center space-y-4 mb-8">
                            <div className="flex justify-center space-x-4">
                                <Link
                                    to={`https://t.me/share/url?url=https://t.me/peperuney_bot/gameapp?startapp=${referralCode}&text=${encodeURIComponent(
                                        "Welcome to PepeRuney 🍕 - The MEME app building bridges between Telegram, Ton and Solana.\n" +
                                        "🔥 First to Connect Solana Phantom wallet to telegram miniapps\n" +
                                        "🍀 Bake pizzas👨‍🍳, play slots🎰, earn rewards 💰\n" +
                                        "🏆 Total prizes 10k+ and airdrop\n" +
                                        "Lockin 🔒 and go All-In --- you are early now is the time! 🎉"
                                    )}`}
                                    className="button-base glow-effect bg-[#2f7821] hover:bg-[#246119] text-white px-8 rounded-full text-lg font-luckiest-guy transition-all duration-300"
                                >
                                    INVITE FRIENDS
                                </Link>
                                <button
                                    onClick={copyReferralLink}
                                    className="button-base glow-effect relative bg-[#2f7821] hover:bg-[#246119] text-white px-8 rounded-full transition-all duration-300"
                                >
                                    <i className="fas fa-copy text-xl"></i>
                                    {showTooltip && (
                                        <div className="absolute -top-10 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs py-1 px-3 rounded-full whitespace-nowrap">
                                            Link copied!
                                        </div>
                                    )}
                                </button>
                            </div>
                            <button
                                onClick={() => referredUsers.length > 0 && setShowReferralSquad(true)}
                                className={`button-base ${
                                    referredUsers.length > 0 
                                        ? "bg-[#ffd700] hover:bg-[#ffed4a]" 
                                        : "bg-gray-400 cursor-not-allowed"
                                } text-black px-8 rounded-full text-lg font-luckiest-guy transition-all duration-300 mt-2`}
                            >
                                {referredUsers.length > 0 ? "YOUR FRENS" : "NO FRENS 😢"}
                            </button>
                        </div>

                    </div>
                </div>
                <Footer uncompletedTasksCount={uncompletedTasksCount} />

            </div>

            {/* Referral Squad Modal */}
            {showReferralSquad && (
                <div 
                    className="fixed inset-0 bg-black/75 flex flex-col z-50"
                    onClick={(e) => {
                        if (e.target === e.currentTarget) {
                            setShowReferralSquad(false);
                        }
                    }}
                >
                    <div className="bg-gradient-to-br from-[#2f7821] to-[#1a4412] h-full w-full relative" onClick={(e) => e.stopPropagation()}>
                        <div className="sticky top-0 p-4 bg-gradient-to-br from-[#2f7821] to-[#1a4412] z-10">
                            <h2 className="text-3xl font-luckiest-guy text-white text-center">
                                YOUR REFERRAL SQUAD
                            </h2>
                        </div>
                        
                        <div className="overflow-y-auto h-[calc(100vh-160px)]">
                            <div className="divide-y divide-[#2f7821]/20">
                                {referredUsers.map((user, index) => (
                                    <div 
                                        key={user.display_name}
                                        className="flex items-center justify-between p-4 hover:bg-white/10 transition-all duration-300"
                                    >
                                        <div className="flex items-center space-x-4">
                                            <div className={`w-8 h-8 flex items-center justify-center rounded-full 
                                                ${index === 0 ? 'bg-yellow-400' : 
                                                  index === 1 ? 'bg-gray-400' : 
                                                  index === 2 ? 'bg-orange-400' : 
                                                  'bg-[#2f7821]/30'} 
                                                font-luckiest-guy text-white`}
                                            >
                                                {index + 1}
                                            </div>
                                            <span className="font-luckiest-guy text-white flex items-center">
                                                {user.display_name.length > 13 
                                                    ? `${user.display_name.slice(0, 13)}...` 
                                                    : user.display_name}
                                                <span className="ml-2 text-xl">{getRandomEmoji()}</span>
                                            </span>
                                        </div>
                                        <div className="flex items-center space-x-2 min-w-[80px] justify-end">
                                            <span className="font-luckiest-guy text-[#00ff00]">
                                                {user.total_referrals}
                                            </span>
                                            <span className="text-white/60 font-luckiest-guy text-sm">REFS</span>
                                        </div>
                                    </div>
                                ))}
                                {referredUsers.length === 0 && (
                                    <div className="p-6 text-center font-luckiest-guy text-white/60">
                                        NO REFERRALS YET
                                    </div>
                                )}
                            </div>
                        </div>
                        
                        <div className="fixed bottom-0 left-0 right-0 p-4 flex justify-center bg-gradient-to-t from-[#1a4412] to-transparent">
                            <button
                                onClick={() => setShowReferralSquad(false)}
                                className="bg-white/20 hover:bg-white/30 text-white py-3 px-8 
                                         rounded-full text-lg font-luckiest-guy transition-all 
                                         duration-300 transform hover:scale-105 
                                         backdrop-blur-sm"
                            >
                                CLOSE
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

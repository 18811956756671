import React from 'react';

function VerificationSection({ isChannelMember, isGroupMember, onVerifyChannel, onVerifyGroup, t }) {
    return (

        <li className="task-item p-4 rounded-lg bg-white shadow-md border border-gray-200">
            <div className="flex flex-col gap-2 bg-white bg-opacity-20 backdrop-blur-md rounded-lg p-4 shadow-lg">
                <div className="flex items-center gap-2 justify-between">
                    <a
                        href="https://t.me/PepeRuneyUpdates"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-800 text-lg font-semibold hover:text-blue-500 transition-colors inline-flex items-center whitespace-nowrap"
                    >
                        <i className="fab fa-telegram text-blue-500 font-semibold mr-1"></i>
                        {t('FOLLOW OUR UPDATES')}
                        <i className="fas fa-external-link-alt text-xs ml-1"></i>
                    </a>
                </div>
                <div className="flex items-center justify-between">
                    <span className="text-green-600 text-2xl font-semibold flex items-center">
                    💰1000
                        <img
                            src="spin-coin.gif"
                            alt="Coin"
                            className="w-6 h-6 ml-1"
                        />
                    </span>
                    {isChannelMember ? (
                        <button
                            disabled
                            className="bg-green-500 text-white rounded-lg px-2 py-1 text-xs opacity-75 flex items-center gap-1"
                        >
                            {t('Verified')} <i className="fas fa-check"></i>
                        </button>
                    ) : (
                        <button
                            onClick={onVerifyChannel}
                            className="bg-black hover:bg-gray-800 text-white rounded-lg px-2 py-1 text-xs transition-colors"
                        >
                            {t('Verify')}
                        </button>
                    )}
                </div>
            </div>

            <div className="flex flex-col gap-2 bg-white bg-opacity-20 backdrop-blur-md rounded-lg p-4 shadow-lg">
                <div className="flex items-center gap-2 justify-between">
                    <a
                        href="https://t.me/+p-IpP9q2H0NlNGEx"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-800 text-lg font-semibold hover:text-blue-500 transition-colors inline-flex items-center whitespace-nowrap"
                    >
                        <i className="fab fa-telegram text-blue-500 font-semibold mr-1"></i>
                        {t('CHAT IN OUR GROUP')}
                        <i className="fas fa-external-link-alt text-xs ml-1"></i>
                    </a>
                </div>
                <div className="flex items-center justify-between">
                    <span className="text-green-600 text-2xl font-semibold flex items-center">
                    💰1000
                        <img
                            src="spin-coin.gif"
                            alt="Coin"
                            className="w-6 h-6 ml-1"
                        />
                    </span>
                    {isGroupMember ? (
                        <button
                            disabled
                            className="bg-green-500 text-white rounded-lg px-2 py-1 text-xs opacity-75 flex items-center gap-1"
                        >
                            {t('Verified')} <i className="fas fa-check"></i>
                        </button>
                    ) : (
                        <button
                            onClick={onVerifyGroup}
                            className="bg-black hover:bg-gray-800 text-white rounded-lg px-2 py-1 text-xs transition-colors"
                        >
                            {t('Verify')}
                        </button>
                    )}
                </div>
            </div>
            <div className="mt-3 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
                                        <p className="text-sm">{t('⚠️$PepeRuney rewards are lost when you leave the group or channel')}</p>
                                    </div>
        </li>
    );
}

export default VerificationSection;
import React from 'react';
import { FaXTwitter, FaTelegram, FaInstagram } from 'react-icons/fa6';

function Conngrats({ onContinue }) {
    return (
        <div className="h-screen flex items-center justify-center p-4">
            <div className="py-4 px-6 rounded-lg shadow-sm w-full max-w-md text-center bg-white mt-2">
                <div className="flex justify-center mb-6">
                    <img 
                        src="/ninja-runey.jpg" 
                        className="app-logos inline-block w-48 h-48 mx-auto" 
                        alt="Ninja Runey" 
                    />
                </div>

                <h1 className="text-2xl font-luckiest-guy">
                    Congratulations🎉
                </h1>
                
                <p className="text-gray-800 mb-2 font-semibold" 
                   style={{ fontFamily: 'Palmer Lake', fontSize: '1.4rem' }}>
                    YOU'RE EARLY TO THE PARTY, GRAB A SLICE!
                </p>

                <button
                    onClick={onContinue}
                    className="w-[200px] py-1 px-6 rounded-[20px] text-white font-luckiest-guy 
                             bg-[#2f7821] hover:bg-[#246119] 
                             transition-all duration-300 mb-3"
                >
                    Continue
                </button>

                <div className="flex justify-center space-x-4 mb-1">
                    <a href="https://x.com/peperuneypizza" 
                       target="_blank" 
                       rel="noopener noreferrer"
                       className="text-[#2f7821] hover:text-[#246119] transition-colors">
                        <FaXTwitter className="text-2xl" />
                    </a>
                    <a href="https://t.me/Peperuney_Pizza" 
                       target="_blank" 
                       rel="noopener noreferrer"
                       className="text-[#2f7821] hover:text-[#246119] transition-colors">
                        <FaTelegram className="text-2xl" />
                    </a>
                    <a href="[instagram.com/PepeRuneyPizza" 
                       target="_blank" 
                       rel="noopener noreferrer"
                       className="text-[#2f7821] hover:text-[#246119] transition-colors">
                        <FaInstagram className="text-2xl" />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Conngrats;
import React, { useEffect, useState, useCallback } from 'react';
import Footer from './Footer';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { Wheel as SpinWheel } from 'spin-wheel';
import Modal from './Modal';
import LoadingSpinner from './LoadingSpinner';
import WheelCelebrationEffect from './WheelCelebrationEffect';


const API_URL = process.env.REACT_APP_API_URL;

const WHEEL_OPTIONS = [
    { points: 1, index: 1 },
    { points: 2, index: 2 },
    { points: 5, index: 3 },
    { points: 10, index: 4 },
    { points: 50, index: 5 },
    { points: 100, index: 6 },
    { points: 500, index: 7 },
    { points: 1000, index: 8 }
];

const pizzaOverlay = new Image();
pizzaOverlay.src = '/pizza.webp';

const pointerOverlay = new Image();
pointerOverlay.src = '/pizzaPointer.png';

export default function WheelGame({uncompletedTasksCount}) {
    const { t } = useTranslation();
    const [wheel, setWheel] = useState(null);
    const [spinStatus, setSpinStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [spinResult, setSpinResult] = useState(null);
    const [isSpinning, setIsSpinning] = useState(false);
    const [error, setError] = useState(null);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [isPointerLoaded, setIsPointerLoaded] = useState(false);

    useEffect(() => {
        let pizzaLoaded = pizzaOverlay.complete;
        let pointerLoaded = pointerOverlay.complete;

        const checkAllLoaded = () => {
            if (pizzaLoaded && pointerLoaded) {
                setIsImageLoaded(true);
            }
        };

        if (!pizzaLoaded) {
            pizzaOverlay.onload = () => {
                console.log('🍕 Pizza overlay loaded');
                pizzaLoaded = true;
                checkAllLoaded();
            };
            pizzaOverlay.onerror = (error) => {
                pizzaOverlay.src = '/pizza.png';
            };
        }

        if (!pointerLoaded) {
            pointerOverlay.onload = () => {
                pointerLoaded = true;
                checkAllLoaded();
            };
            pointerOverlay.onerror = (error) => {
                pointerOverlay.src = '/pizzaPointer.png';
            };
        }

        checkAllLoaded();
    }, []);

    const initializeWheel = useCallback(() => {
        if (!isImageLoaded) {
            return null;
        }

        if (!pizzaOverlay.complete || !pointerOverlay.complete || 
            pizzaOverlay.naturalWidth === 0 || pointerOverlay.naturalWidth === 0) {
            console.log('Images not fully loaded:', {
                pizza: {
                    complete: pizzaOverlay.complete,
                    width: pizzaOverlay.naturalWidth
                },
                pointer: {
                    complete: pointerOverlay.complete,
                    width: pointerOverlay.naturalWidth
                }
            });
            return null;
        }

        try {
            console.log('Initializing wheel with image dimensions:', {
                pizza: {
                    width: pizzaOverlay.naturalWidth,
                    height: pizzaOverlay.naturalHeight
                },
                pointer: {
                    width: pointerOverlay.naturalWidth,
                    height: pointerOverlay.naturalHeight
                }
            });
            const wheelContainer = document.getElementById('wheel-container');

            if (!wheelContainer) {
                console.error('Wheel container not found');
                return null;
            }

            wheelContainer.innerHTML = '';

            const wheelInstance = new SpinWheel(wheelContainer, {
                debug: false,
                items: WHEEL_OPTIONS.map((option) => ({
                    label: `${option.points} Points`,
                    value: option.index - 1,
                    points: option.points
                })),
                itemLabelFont: 'Schoolbell, cursive',
                itemLabelFontSizeMax: 30,
                itemLabelRadius: 0.85,
                itemLabelRadiusMax: 0.2,
                backgroundColor: '#000',
                image: pizzaOverlay.complete ? pizzaOverlay : null,
                overlayImage: pointerOverlay.complete ? pointerOverlay : null,
                lineColor: '#efeeeb',
                lineWidth: 0,
                rotationSpeedMax: 300,
                rotationTimeMin: 4000,
                rotationTimeMax: 4000,
                onStart: () => {
                    console.log('Wheel spin started');
                },
                onProgress: (progress) => {
                    console.log(`Spin progress: ${Math.round(progress * 100)}%`);
                },
                onRest: () => {
                    console.log('Wheel stopped spinning');
                }
            });

            console.log('Wheel initialized successfully');
            return wheelInstance;
        } catch (error) {
            console.error('Error initializing wheel:', error);
            setError('Failed to initialize wheel');
            return null;
        }
    }, [isImageLoaded]);

    const fetchSpinStatus = useCallback(async () => {
        const authToken = Cookies.get('authToken');
        if (!authToken) {
            console.error('No auth token found');
            setError('Authentication required');
            setLoading(false);
            return;
        }

        try {
            console.log('Fetching spin status...');
            const response = await fetch(`${API_URL}/api/user/spin-wheel/status`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                },
                retryCount: 3,
                retryDelay: 1000,
                retryStatusCodes: [408, 500, 502, 503, 504]
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log('Spin status:', data);
            setSpinStatus(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching spin status:', error);
            setError('Failed to fetch spin status');
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        let wheelContainer = null;
        let initAttempts = 0;
        const maxAttempts = 5;
        let initTimer = null;

        const initWheel = async () => {
            try {
                await fetchSpinStatus();
                wheelContainer = document.getElementById('wheel-container');
                
                if (wheelContainer && isImageLoaded) {
                    const wheelInstance = initializeWheel();
                    if (wheelInstance) {
                        setWheel(wheelInstance);
                        console.log('🎯 Wheel initialized successfully');
                    } else if (initAttempts < maxAttempts) {
                        initAttempts++;
                        console.log(`🎯 Retry attempt ${initAttempts} of ${maxAttempts}`);
                        initTimer = setTimeout(initWheel, 1000);
                    } else {
                        console.error('Failed to initialize wheel after maximum attempts');
                        setError('Failed to initialize game');
                    }
                } else {
                    if (initAttempts < maxAttempts) {
                        initAttempts++;
                        console.log(`🎯 Waiting for container and images... Attempt ${initAttempts}`);
                        initTimer = setTimeout(initWheel, 1000);
                    }
                }
            } catch (error) {
                console.error('Error in initialization:', error);
                setError('Failed to initialize game');
            }
        };

        initWheel();

        // Initialize Telegram WebApp SDK
        const tg = window.Telegram?.WebApp;
        if (tg) {
            tg.BackButton.show();
            tg.BackButton.onClick(() => window.history.back());
        }

        return () => {
            if (initTimer) {
                clearTimeout(initTimer);
            }
            if (wheelContainer) {
                wheelContainer.innerHTML = '';
            }
            if (tg) {
                tg.BackButton.hide();
                tg.BackButton.offClick();
            }
        };
    }, [fetchSpinStatus, initializeWheel, isImageLoaded]);

    const handleSpin = async () => {
        if (!spinStatus?.canSpin || isSpinning || !wheel) {
            console.log('Spin prevented:', { canSpin: spinStatus?.canSpin, isSpinning, wheelExists: !!wheel });
            return;
        }

        setIsSpinning(true);
        const authToken = Cookies.get('authToken');

        try {
            const response = await fetch(`${API_URL}/api/user/spin-wheel`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log('🎯 Spin result from API:', data);

            setSpinResult(data);

            const winningOption = WHEEL_OPTIONS.find(opt => opt.index === data.wheelIndex);
            if (!winningOption) {
                throw new Error(`Invalid winning index received: ${data.wheelIndex}`);
            }

            console.log('🎯 Spinning to:', {
                wheelIndex: data.wheelIndex,
                points: winningOption.points,
                expectedPoints: data.pointsWon
            });

            const spinPromise = new Promise((resolve) => {
                wheel.onRest = () => {
                    console.log('🎡 Wheel physically stopped');
                    resolve();
                };
            });

            console.log('🎡 Starting wheel animation...');
            wheel.spinToItem(data.wheelIndex - 1, 4000, true, 2, 1);

            await spinPromise;
            setShowModal(true);
            console.log('🎯show modal:');

            await fetchSpinStatus();
            console.log('🎯 fetchSpinStatus:', spinStatus);
            setShowModal(true);
        } catch (error) {
            console.error('Error during spin:', error);
            setError('Failed to spin the wheel');
        } finally {
            setIsSpinning(false);
        }
    };

    const getButtonText = () => {
        if (isSpinning) return t('Spinning...');
        if (!spinStatus?.canSpin) return `${t('Next Spin')}: ${spinStatus?.timeUntilNextSpin}`;
        return t('Spin Now');
    };

    if (loading) return <LoadingSpinner />;
    if (error) return <div className="text-red-500 text-center p-4">{error}</div>;

    return (
        <div className="h-full overflow-y-auto touch-pan-y">
            <div className="flex-grow flex items-center justify-center p-4">
                <div 
                    className="relative w-full max-w-[500px] mx-auto"
                    style={{ 
                        backgroundImage: 'url(/spinneruney.gif)',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        height: '600px'
                    }}
                >
                    <div 
                        id="wheel-container" 
                        className="absolute"
                        style={{ 
                            width: '230px', 
                            height: '230px',
                            left: '50%',
                            top: '40%',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        {!isImageLoaded && <LoadingSpinner />}
                    </div>

                    {/* Button inside the GIF container */}
                    <button
                        onClick={handleSpin}
                        disabled={!spinStatus?.canSpin || isSpinning}
                        className={`absolute left-1/2 bottom-[32%] transform -translate-x-1/2
                                   w-[200px] py-3 px-4 rounded-xl text-white 
                                   font-luckiest-guy tracking-wider
                                   transition-all duration-300 
                                   ${spinStatus?.canSpin && !isSpinning
                            ? 'bg-[#2f7821] hover:bg-[#246119]'
                            : 'bg-gray-400'}`}
                    >
                        {getButtonText()}
                    </button>
                </div>
            </div>

            {showModal && spinResult && (
                <Modal onClose={() => {
                    console.log('🎯 Closing result modal');
                    setShowModal(false);
                }}>
                    <WheelCelebrationEffect amount={spinResult.pointsWon} />
                    <div className="text-center">
                        <h2 className="text-2xl font-bold mb-4 font-luckiest-guy">
                            🎉 Congratulations! 🎉
                        </h2>
                        <p className="text-lg mb-6 font-luckiest-guy">
                            You earned {
                                spinResult.pointsWon === 1 ? '😢💰1' :
                                spinResult.pointsWon === 2 ? '☹️💰2' :
                                spinResult.pointsWon === 5 ? '😐💰5' :
                                spinResult.pointsWon === 10 ? '🙂💰10' :
                                spinResult.pointsWon === 50 ? '😁💰50' :
                                spinResult.pointsWon === 100 ? '🔥💰100' :
                                spinResult.pointsWon === 500 ? '🤩💰500' :
                                '💎💰1000'
                            } PepeRuney rewards!
                        </p>
                    </div>
                </Modal>
            )}

<Footer uncompletedTasksCount={uncompletedTasksCount} />
</div>
    );
}
import React from 'react';
import { FaXTwitter, FaTelegram, FaInstagram } from 'react-icons/fa6'; // Import icons from react-icons

function WaitlistSuccess() {
    return (
        <div className="h-screen flex items-center justify-center p-4">
            <div className="p-6 rounded-lg w-full max-w-md text-center" style={{ backgroundColor: '#fafafa' }}>
                <div className="flex justify-center">
                    <img src={"peperuney-lambo.webp"} className="app-logos inline-block w-48 h-48 mx-auto" alt="Betting Hub" />
                </div>

                <h1 className="text-3xl font-luckiest-guy mb-4 mt-4 text-center" style={{ fontSize: '17.9pt' }}>THANK YOU FOR SUBMITTING YOUR EMAIL!</h1>                
                <p className="text-black-600 mb-4 text-center"
                    style={{ 
                        fontFamily: 'Palmer Lake',
                        fontSize: '20.9pt',
                        lineHeight: '.8',
                        fontWeight: 'regular'
                    }}>
                    STAY TUNED FOR UPDATES!
                </p>
                <div className="flex justify-center space-x-4 mb-1">
                    <a href="https://x.com/peperuneypizza" 
                       target="_blank" 
                       rel="noopener noreferrer"
                       className="text-[#2f7821] hover:text-[#246119] transition-colors">
                        <FaXTwitter className="text-2xl" />
                    </a>
                    <a href="https://t.me/Peperuney_Pizza" 
                       target="_blank" 
                       rel="noopener noreferrer"
                       className="text-[#2f7821] hover:text-[#246119] transition-colors">
                        <FaTelegram className="text-2xl" />
                    </a>
                    <a href="https://instagram.com/PepeRuneyPizza" 
                       target="_blank" 
                       rel="noopener noreferrer"
                       className="text-[#2f7821] hover:text-[#246119] transition-colors">
                        <FaInstagram className="text-2xl" />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default WaitlistSuccess;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import LoadingSpinner from './LoadingSpinner';
const API_URL = process.env.REACT_APP_API_URL; 

const Transactions = () => {
    const navigate = useNavigate();
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showScrollIndicator, setShowScrollIndicator] = useState(true);

    const handleScroll = (e) => {
        const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 1;
        setShowScrollIndicator(!bottom);
    };

    useEffect(() => {
        const fetchTransactions = async () => {
            const authToken = Cookies.get('authToken');
            try {
                const response = await fetch(`${API_URL}/api/user/transactions`, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                    },
                    retryCount: 3,
                    retryDelay: 1000,
                    retryStatusCodes: [408, 500, 502, 503, 504]
                });
                const data = await response.json();
                setTransactions(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching transactions:', error);
                setLoading(false);
            }
        };

        fetchTransactions();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: '2-digit'
        });
    };

    return (
        <div className="h-screen relative">
            <div className="h-full overflow-y-auto relative" onScroll={handleScroll}>
                <div className="container mx-auto px-4 py-8">
                    {/* Close Button */}
                    <button
                        onClick={() => navigate(-1)}
                        className="fixed top-12 right-12 z-50 text-[#ed1312] text-2xl 
                                 hover:scale-110 transition-transform duration-200"
                    >
                        <i className="fas fa-times"></i>
                    </button>

                    {showScrollIndicator && (
                        <div className="fixed bottom-20 right-6 z-30 bg-white/10 backdrop-blur-sm p-3 rounded-full shadow-lg border border-white/10 animate-bounce cursor-pointer">
                            <svg 
                                className="w-6 h-6 text-gray-400" 
                                fill="none" 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth="2" 
                                viewBox="0 0 24 24" 
                                stroke="currentColor"
                            >
                                <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                            </svg>
                        </div>
                    )}

                    <div className="max-w-3xl mx-auto p-8 rounded-lg shadow-md" 
                         style={{ background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.0), rgba(245, 247, 250, 0.4))' }}>
                        <h1 className="text-2xl font-luckiest-guy text-center text-white mb-8" style={{ fontSize: '1.5rem' }}>
                            RECENT TXs
                        </h1>
                        
                        {loading ? (
                            <LoadingSpinner />
                        ) : (
                            <div className="overflow-x-auto">
                                <table className="w-full text-white">
                                    <thead>
                                        <tr className="border-b border-white/20">
                                            <th className="py-1 px-4 text-left font-luckiest-guy" style={{ fontSize: '28px' }}>Date</th>
                                            <th className="py-1 px-4 text-left font-luckiest-guy" style={{ fontSize: '28px' }}>Type</th>
                                            <th className="py-1 px-4 text-center font-luckiest-guy flex items-center justify-center gap-1" style={{ fontSize: '28px' }}>
                                                <span className="text-white">$</span>
                                                <img
                                                    src="/spin-coin.gif"
                                                    alt="Coin"
                                                    className="h-[28px] w-[28px]"
                                                />
                                            </th>   
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transactions.map((tx) => (
                                            <tr key={tx.transaction_id} className="border-b border-white/10">
                                                <td className="py-2 px-4" style={{ fontFamily: 'Palmer Lake', fontSize: '1.2rem' }}>
                                                    {formatDate(tx.created_at)}
                                                </td>
                                                <td className="py-2 px-4" style={{ fontFamily: 'Palmer Lake', fontSize: '1.2rem' }}>
                                                    {tx.transaction_type}
                                                </td>
                                                <td className="py-2 px-4 text-center" style={{ fontFamily: 'Palmer Lake', fontSize: '1.2rem' }}>
                                                    {tx.points}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Transactions;
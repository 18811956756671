import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getStoredLanguage } from './utils/languageUtils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './components/Home';
import Streak from './components/Streak';
import Layout from './components/Layout';
import Task from './components/Task';
import PizzaShop from './components/PizzaShop';
import Terms from './components/Terms';
import Contest from './components/Contest';
import Betting from './components/Betting';
import Referral from './components/Referral';
import StreakConfig from './components/StreakConfig';
import Wallet from './components/Wallet';
import Twallet from './components/Twallet';
import Wheel from './components/Wheel';
import Waitlist from './components/Waitlist';
import WaitlistSuccess from './components/WaitstSuccess';
import Conngrats from './components/Conngrats';
import LeaderBoard from './components/LeaderBoard';
import TermsAndConditions from './components/TermsAndConditions';
import Transactions from './components/Transactions';
import SocialFi from './components/SocialFi';
import TelegramConfig from './components/TelegramConfig';
import Slots from './components/Slots';





function App() {
  const { i18n } = useTranslation();
  const [uncompletedTasksCount, setUncompletedTasksCount] = useState(0);

  useEffect(() => {
    const storedLanguage = getStoredLanguage();
    i18n.changeLanguage(storedLanguage);
  }, [i18n]);

  const updateTaskCount = (count) => {
    setUncompletedTasksCount(count);
  };

  return (
    <>
      <TelegramConfig />
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home uncompletedTasksCount={uncompletedTasksCount} updateTaskCount={updateTaskCount} />} />
            <Route path="/streak" element={<Streak />} />
            <Route path="/tasks" element={<Task uncompletedTasksCount={uncompletedTasksCount} updateTaskCount={updateTaskCount} />} />
            <Route path="/pizza" element={<PizzaShop />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/contest" element={<Contest />} />
            <Route path="/betting" element={<Betting />} />
            <Route path="/referral" element={<Referral uncompletedTasksCount={uncompletedTasksCount} />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/twallet" element={<Twallet />} />
            <Route path="/wheel" element={<Wheel uncompletedTasksCount={uncompletedTasksCount} />} />
            <Route path="/waitlist" element={<Waitlist />} />
            <Route path="/waitlist-success" element={<WaitlistSuccess />} />
            <Route path="/congrats" element={<Conngrats />} />
            <Route path="/leaderboard" element={<LeaderBoard />} />
            <Route path="/streak-config" element={<StreakConfig />} />
            <Route path="/termsandconditions" element={<TermsAndConditions />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/socialfi" element={<SocialFi />} />
            <Route path="/slots" element={<Slots uncompletedTasksCount={uncompletedTasksCount} />} />
            {/* Add more routes here as needed */}
          </Routes>
        </Layout>
        <ToastContainer />
      </Router>
    </>
  );
}

export default App;

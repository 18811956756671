import React from 'react';
import { Link } from 'react-router-dom';

const MenuOverlay = ({ isOpen, onClose, showBoostTooltip, setShowBoostTooltip }) => {
    if (!isOpen) return null;
    
    const isTelegramDesktop = window.Telegram?.WebApp?.platform === 'tdesktop';

    return (
        <div className="fixed inset-0 bg-black/80 z-50 flex flex-col">
            <div className={`w-full max-w-md mx-auto px-6 menu-content ${isTelegramDesktop ? 'mt-8' : 'mt-16'}`}>
                <div className="bg-white rounded-xl overflow-hidden">
                    <Link 
                        to="/socialfi" 
                        className="flex items-center px-6 py-4 border-b border-gray-200 text-green-700 font-luckiest-guy"
                        onClick={onClose}
                    >
                        <i className="fas fa-users text-green-700 mr-3 text-xl"></i>
                        SOCIALFI
                    </Link>
                    <Link 
                        to="/betting" 
                        className="flex items-center px-6 py-4 border-b border-gray-200 text-green-700 font-luckiest-guy"
                        onClick={onClose}
                    >
                        <i className="fas fa-dice text-green-700 mr-3 text-xl"></i>
                        BETTING
                    </Link>
                    <Link 
                        to="/leaderboard" 
                        className="flex items-center px-6 py-4 border-b border-gray-200 text-green-700 font-luckiest-guy"
                        onClick={onClose}
                    >
                        <i className="fas fa-trophy text-green-700 mr-3 text-xl"></i>
                        LEADERBOARDS
                    </Link>
                    <Link 
                        to="/wallet" 
                        className="flex items-center px-6 py-4 border-b border-gray-200 text-green-700 font-luckiest-guy"
                        onClick={onClose}
                    >
                        <i className="fas fa-wallet text-green-700 mr-3 text-xl"></i>
                        WALLET
                    </Link>
                    <Link 
                        to="/transactions" 
                        className="flex items-center px-6 py-4 border-b border-gray-200 text-green-700 font-luckiest-guy"
                        onClick={onClose}
                    >
                        <i className="fas fa-history text-green-700 mr-3 text-xl"></i>
                        TRANSACTIONS
                    </Link>
                    <div
                        className="relative flex items-center px-6 py-4 border-b border-gray-200 text-green-700 font-luckiest-guy cursor-pointer"
                        onClick={() => {
                            setShowBoostTooltip(true);
                            setTimeout(() => {
                                setShowBoostTooltip(false);
                                onClose();
                            }, 2000);
                        }}
                    >
                        <i className="fas fa-bolt text-green-700 mr-3 text-xl"></i>
                        BOOSTS
                        {showBoostTooltip && (
                            <div className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-black text-white text-sm py-2 px-4 rounded-full whitespace-nowrap z-50">
                                Coming soon!
                            </div>
                        )}
                    </div>
                    <Link
                        to="/termsandconditions"
                        className="flex items-center px-6 py-4 text-green-700 font-luckiest-guy"
                        onClick={onClose}
                    >
                        <i className="fas fa-file-contract text-green-700 mr-3 text-xl"></i>
                        TERMS AND CONDITIONS
                    </Link>
                </div>
                
                <div className="flex justify-center gap-6 mt-8">
                    <a href="https://instagram.com/PepeRuneyPizza" target="_blank" rel="noopener noreferrer">
                        <img src="instagram.webp" alt="Instagram" className="w-10 h-10" />
                    </a>
                    <a href="https://x.com/peperuneypizza" target="_blank" rel="noopener noreferrer">
                        <img src="x-logo.webp" alt="X" className="w-10 h-10" />
                    </a>
                    <a href="https://youtube.com/@peperuneypizza?si=UOSvjND5XL3FrBpK" target="_blank" rel="noopener noreferrer">
                        <img src="youtube.webp" alt="YouTube" className="w-10 h-10" />
                    </a>
                    <a href="https://peperuney.pizza" target="_blank" rel="noopener noreferrer">
                        <img src="point1.png" alt="Point" className="w-10 h-10 rounded-full" />
                    </a>
                </div>
                <button
                    onClick={onClose}
                    className="mt-8 mb-4 mx-auto px-8 py-2 
                        bg-white/10 backdrop-blur-sm
                        text-white font-luckiest-guy
                        rounded-full 
                        hover:bg-white/20 
                        transition-all duration-300
                        flex items-center gap-2
                        border border-white/20"
                >
                    <span>CLOSE MENU</span>
                    <i className="fas fa-times"></i>
                </button>
            </div>
        </div>
    );
};

export default MenuOverlay;
import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Cookies from 'js-cookie';
import LoadingSpinner from './LoadingSpinner';
import { toast } from 'react-toastify'; // Import toast for notifications
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles



const API_URL = process.env.REACT_APP_API_URL; // Accessing the API URL from the environment variable

function Contest() {
    const [loading, setLoading] = useState(false);
    const [showContest, setShowContest] = useState(true); // New state to control visibility of Terms

    const handleAccept = async () => { // Added async keyword
        const authToken = Cookies.get('authToken');
        try {
            const responseView = await fetch(`${API_URL}/api/tasks/view`, { // Use API_URL for view API
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify({ task_id: 3 }),
            });

            const responseClaim = await fetch(`${API_URL}/api/tasks/claim`, { // Use API_URL for claim API
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify({ task_id: 3 }),
                retryCount: 3,
                retryDelay: 1000,
                retryStatusCodes: [408, 500, 502, 503, 504]
            });
            setShowContest(false);
            window.location.reload();
        } catch (error) {
            console.error('Error during API calls:', error); // Log the error
            toast.error('An error occurred while processing your request.'); // Notify user of the error
        }
    };

    return (
        <div className="h-screen relative bg-gradient-to-b from-gray-800 to-gray-900">
            {/* Fixed Pizza Menu Header Image with Background */}
            <div className="fixed top-0 left-0 right-0 z-20 bg-gradient-to-b from-gray-800 to-gray-900">
                <img 
                    src="/pizza-menu.png" 
                    alt="Pizza Menu" 
                    className="w-full object-contain"
                />
                {/* Add a shadow overlay */}
                <div className="absolute bottom-0 left-0 right-0 h-4 bg-gradient-to-t from-gray-800/80 to-transparent"></div>
            </div>

            {/* Scrollable Content Container */}
            <div className="h-full pt-[120px] pb-4 overflow-y-auto relative">
                <div className="container mx-auto px-4">
                    {/* Improved Scroll Indicator */}
                    <div className="fixed bottom-6 right-6 z-30 bg-white/10 backdrop-blur-sm p-3 rounded-full shadow-lg border border-white/10 animate-bounce cursor-pointer">
                        <svg 
                            className="w-6 h-6 text-white" 
                            fill="none" 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth="2" 
                            viewBox="0 0 24 24" 
                            stroke="currentColor"
                        >
                            <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                        </svg>
                    </div>

                    {/* Glassmorphic Container */}
                    <div className="glassmorphic-container bg-gray-800/30 backdrop-blur-md p-6 rounded-xl">
                        <div className="grid grid-cols-1 gap-6">
                            {/* Bake Your Pizzas Card */}
                            <div className="bg-white/10 backdrop-blur-sm p-6 rounded-xl shadow-lg border border-white/10">
                                <h2 className="text-2xl font-luckiest-guy mb-4 text-white">🍕 BAKE YOUR PIZZAS</h2>
                                <p className="text-gray-200" style={{ fontFamily: 'Palmer Lake', fontSize: '27px', lineHeight: '0.8' }}>
                                    BAKE A NEW PIZZA EVERY 6 HOURS AND EARN $PEPERUNEY REWARDS. THE MORE CONSECUTIVE DAILY BAKES, THE HIGHER YOUR STREAK, THE MORE YOU EARN PER BAKE-TO-EARN FRENS.
                                </p>
                            </div>

                            {/* Referrals Card */}
                            <div className="bg-white/10 backdrop-blur-sm p-6 rounded-xl shadow-lg border border-white/10">
                                <h2 className="text-2xl font-luckiest-guy mb-4 text-white">🔗 REFERRALS</h2>
                                <p className="text-gray-200" style={{ fontFamily: 'Palmer Lake', fontSize: '27px', lineHeight: '0.8' }}>                                    FOR EVERY 5 REFERRALS TO THE SLICE CLUB, EARN 300 $PEPERUNEY REWARDS. YOU ARE EARLY BAKERS GET THE MOST REWARDS SO SHARE THIS WITH YOUR FRIENDS.
                                </p>
                            </div>

                            {/* Tasks Card */}
                            <div className="bg-white/10 backdrop-blur-sm p-6 rounded-xl shadow-lg border border-white/10">
                                <h2 className="text-2xl font-luckiest-guy mb-4 text-white">✅ TASKS</h2>
                                <p className="text-gray-200" style={{ fontFamily: 'Palmer Lake', fontSize: '27px', lineHeight: '0.8' }}>
                                    FINISH TASKS AND CLAIM YOUR $PEPERUNEY REWARDS.
                                </p>
                            </div>

                            {/* Casino Card */}
                            <div className="bg-white/10 backdrop-blur-sm p-6 rounded-xl shadow-lg border border-white/10">
                                <h2 className="text-2xl font-luckiest-guy mb-4 text-white">🎰 CASINO</h2>
                                <p className="text-gray-200" style={{ fontFamily: 'Palmer Lake', fontSize: '27px', lineHeight: '.8' }}>
                                    BUY $PEPERUNEY AND PLAY IN THE CASINO. THE ONLY PVP BETTING GAMES AND PREDICTIVE MARKETS NATIVE TO TELEGRAM ON SOLANA.
                                </p>
                            </div>

                            {/* Reminder Card */}
                            <div className="bg-[#f9f7b3] backdrop-blur-sm p-6 rounded-xl shadow-lg border border-white/10">
                                <p className="text-[#ae8d22] text-xl font-regular text-center" style={{ fontFamily: 'Palmer Lake', fontSize: '27px', lineHeight: '.8' }}>
                                    REMEMBER, THE MORE $PEPERUNEY REWARDS YOU HAVE, THE LARGER YOUR AIRDROP REWARDS!
                                </p>
                            </div>

                        {/* Continue Button */}
                        <div className="text-center mt-4">
                            <button
                                onClick={handleAccept}
                                className="bg-[#2f7821] hover:bg-[#246119] text-white py-3 px-12 rounded-full transition-all duration-300 font-luckiest-guy"
                            >
                                CONTINUE
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default Contest;

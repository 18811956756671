import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Cookies from 'js-cookie';
import LoadingSpinner from './LoadingSpinner';
import { toast } from 'react-toastify'; // Import toast for notifications
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import { useContext } from 'react'; // Import useContext for context usage
import Contest from './Contest';


const API_URL = process.env.REACT_APP_API_URL; // Accessing the API URL from the environment variable

function Terms() {
    const [loading, setLoading] = useState(false);
    const [accepted, setAccepted] = useState(false);
    const [proceeding, setProceeding] = useState(false); // New state for proceeding
    const [showTerms, setShowTerms] = useState(true); // New state to control visibility of Terms

    const handleAccept = async () => { // Added async keyword
        const authToken = Cookies.get('authToken');
        if (accepted) {
            setProceeding(true); // Set proceeding to true
            try {
                await fetch(`${API_URL}/api/tasks/view`, { // Use API_URL for view API
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`,
                    },
                    body: JSON.stringify({ task_id: 2 }),
                });

                await fetch(`${API_URL}/api/tasks/claim`, { // Use API_URL for claim API
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`,
                    },
                    body: JSON.stringify({ task_id: 2 }),
                    retryCount: 3,
                    retryDelay: 1000,
                    retryStatusCodes: [408, 500, 502, 503, 504]
                });

                setTimeout(() => {
                    setProceeding(false); // Reset proceeding state
                    setShowTerms(false); // Hide Terms component
                }, 1000); // Adjust time as needed
            } catch (error) {
                console.error("Error during fetch:", error); // Added error handling
                setProceeding(false); // Reset proceeding state on error
            }
        }
    };

    return (
        <>
            {showTerms ? ( // Conditional rendering based on showTerms state
                <div className="h-screen">
                    <div className="task-container p-4 rounded-lg m-1  overflow-y-auto touch-pan-y scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
                        {/* Main Content Box */}
                        <div className="px-4 md:px-8 max-w-4xl mx-auto"> {/* Added padding and max-width with auto margins */}
                            {loading ? (
                                <LoadingSpinner />
                            ) : (
                                <>
                                    <div className="mt-2 flex justify-center items-center">
                                        <img 
                                            src={"neo-runey.jpg"} 
                                            className="app-logos" 
                                            style={{
                                                width: '180px',
                                                height: '180px',
                                                display: 'block',
                                                margin: '10px auto'
                                            }} 
                                            alt="Peperuney Pizza Club" 
                                        />
                                    </div>
                                    <div className="terms-text-box max-h-60 overflow-y-auto p-4 bg-white border border-gray-300 rounded-lg mb-4">
                                        <div className="space-y-6">
                                            <h1 className="text-2xl font-luckiest-guy text-center text-black mb-2">
                                                TERMS AND CONDITIONS
                                            </h1>
                                            
                                            <p className="text-black-700 text-lg" style={{ fontFamily: 'Palmer Lake', fontSize: '1.5rem' }}>
                                                The $PepeRuney token is not affiliated, associated, or endorsed by Matt Furie or his creation, PEPE. This token exists solely to pay homage to a widely recognized meme and is designed purely for entertainment purposes.
                                            </p>

                                            <p className="text-black-700 text-lg" style={{ fontFamily: 'Palmer Lake', fontSize: '1.5rem' }}>
                                                $PepeRuney is a meme coin that holds no intrinsic value, utility, or expectation of financial return. It is not an investment and provides no guarantees of profit or benefit. There is no formal development team or roadmap associated with this token.
                                            </p>

                                            <p className="text-black-700 text-lg" style={{ fontFamily: 'Palmer Lake', fontSize: '1.5rem' }}>
                                                Applications and activities accessed through the $PepeRuney ecosystem & partners, including gambling, are community-developed and not formally endorsed or managed by the creators of $PepeRuney. Participants must ensure compliance with their local laws. Gambling is prohibited in countries where it is illegal, including but not limited to United States, China, India, Singapore, United Arab Emirates, Turkey, South Korea, Australia, and Saudi Arabia. It is the sole responsibility of users to verify and adhere to the legal requirements in their jurisdictions.
                                            </p>

                                            <p className="text-black-700 text-lg" style={{ fontFamily: 'Palmer Lake', fontSize: '1.5rem' }}>
                                                $PepeRuney is provided "as is," without warranties of any kind, express or implied. The creators disclaim all liability for any use or actions associated with $PepeRuney, including any financial loss, legal consequences, or other damages.
                                            </p>

                                            <p className="text-black-700 text-lg" style={{ fontFamily: 'Palmer Lake', fontSize: '1.5rem' }}>
                                                By engaging with $PepeRuney, users acknowledge and agree to these terms in full. Any use of $PepeRuney is undertaken at the user's own risk.
                                            </p>
                                        </div>
                                    </div>

                                    {/* Accept Terms Checkbox */}
                                    <div className="flex flex-col items-center mb-4">
                                        <div className="flex items-center">
                                            <input
                                                type="checkbox"
                                                id="accept-terms"
                                                checked={accepted}
                                                onChange={() => setAccepted(!accepted)}
                                                className="mr-2 w-5 h-5" // Made checkbox larger
                                            />
                                            <label 
                                                htmlFor="accept-terms" 
                                                className="text-2xl text-center" // Increased text size
                                                style={{ fontFamily: 'Palmer Lake' }}
                                            >
                                               <span className="text-[#2f7821]"> I accept the terms and conditions</span>
                                            </label>
                                        </div>
                                    </div>

                                    {/* Proceed Button */}
                                    <div className="text-center">
                                        <button
                                            onClick={handleAccept}
                                            disabled={!accepted || proceeding}
                                            className={`bg-[#2f7821] text-white py-2 px-6 rounded-full hover:bg-[#246119] transition duration-300 text-lg font-luckiest-guy ${!accepted || proceeding ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        >
                                            {proceeding ? <i className="fas fa-spinner fa-spin text-white" /> : 'Proceed'}
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <Contest /> // Render <Context /> when terms are hidden
            )}
        </>
    );
}

export default Terms;

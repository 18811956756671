import React, { useEffect, useRef } from 'react';
import ReactConfetti from 'react-confetti';

const WheelCelebrationEffect = ({ amount }) => {
    const containerRef = useRef(null);
    // Customize emojis based on win amount
    const getEmojis = (amount) => {
        if (amount >= 1000) return ['💎', '🤑', '💰', '🎰', '🎉'];
        if (amount >= 500) return ['🤩', '💰', '🎰', '🎉', '🎊'];
        if (amount >= 100) return ['🔥', '💰', '🎰', '🎉', '🎊'];
        if (amount >= 50) return ['😁', '💵', '🎰', '🎉', '🎊'];
        if (amount >= 10) return ['🙂', '💵', '🎰', '🎉', '🎊'];
        return ['😐', '💵', '🎰', '🎉', '🎊'];
    };
    
    useEffect(() => {
        const container = containerRef.current;
        const elements = [];
        const emojis = getEmojis(amount);
        
        emojis.forEach((emoji) => {
            for (let i = 0; i < 3; i++) {
                const span = document.createElement('span');
                span.textContent = emoji;
                span.className = 'wheel-floating-emoji';
                const scale = 0.8 + Math.random() * 0.4; // Slightly smaller scale
                span.style.cssText = `
                    --delay: ${Math.random() * 0.6}s;
                    --x-start: ${Math.random() * 100}%;
                    --rotation: ${Math.random() * 540 - 270}deg;
                    --scale: ${scale};
                    animation-delay: var(--delay);
                    left: var(--x-start);
                    font-size: ${2.5 * scale}rem;
                    filter: drop-shadow(0 0 8px rgba(255,255,255,0.5));
                `;
                elements.push(span);
                container.appendChild(span);
            }
        });

        return () => elements.forEach(element => element.remove());
    }, [amount]);

    return (
        <>
            <ReactConfetti
                recycle={false}
                numberOfPieces={150}
                tweenDuration={2000}
                gravity={0.2}
                colors={['#FFD700', '#FF6B6B', '#4CAF50', '#FF9800', '#ffffff']}
                confettiSource={{ x: 0, y: 0, w: window.innerWidth, h: 0 }}
            />
            <div ref={containerRef} className="wheel-celebration-container" />
        </>
    );
};

export default WheelCelebrationEffect;
import React, { useEffect, useRef, useState } from 'react';
import { ScratchCard, SCRATCH_TYPE } from 'scratchcard-js';
import Cookies from 'js-cookie';
import LoadingSpinner from './LoadingSpinner'; // Assuming you have a loading spinner component
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import ReactConfetti from 'react-confetti';

const API_URL = process.env.REACT_APP_API_URL; // Accessing the API URL from the environment variable

const ScratchCardModal = ({ onClose, newCards, scratch_card_value: initialScratchValue, onBalanceUpdate }) => {
    console.log('ScratchCardModal initialized with:', { newCards, initialScratchValue });
    const scratchCardRef = useRef(null);
    const [isScratching, setIsScratching] = useState(false);
    const [showCelebration, setShowCelebration] = useState(false);
    const [currentScratchValue, setCurrentScratchValue] = useState(initialScratchValue);
    const [totalScratchCards, setTotalScratchCards] = useState(newCards || 0);

    const frontImages = ['scratch1-min.PNG', 'scratch2-min.PNG', 'scratch3-min.png'];
    const backImages = ['scratchBack1-min.PNG', 'scratchBack2-min.PNG', 'scratchBack3-min.PNG', 'scratchBack4-min.PNG', 'scratchBack5-min.png'];

    const getRandomImage = (images) => {
        return images[Math.floor(Math.random() * images.length)];
    };

    const getWelcomeMessage = (cards) => {
        if (cards === 1) return "WELCOME BACK. SCRATCH AWAY!";
        if (cards === 2) return "ON FIRE! 2 NEW SCRATCH CARDS!";
        if (cards >= 3 && cards <= 5) return `ON A ROLL! ${cards} NEW SCRATCH CARDS!`;
        if (cards > 5) return `LEGEND! ${cards} NEW SCRATCH CARDS!`;
        return `YOU'VE EARNED ${cards} NEW SCRATCH CARDS!`; // fallback
    };

    const processScratchCard = async () => {
        const authToken = Cookies.get('authToken');

        if (!authToken) {
            console.error('No auth token found');
            return null;
        }

        try {
            const response = await fetch(`${API_URL}/api/user/scratch`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}` // Assuming you store token in localStorage
                }
            });

            if (!response.ok) throw new Error('Failed to process scratch card');
            
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error processing scratch card:', error);
            return null;
        }
    };

    const fetchScratchCardValue = async () => {
        const authToken = Cookies.get('authToken');

        if (!authToken) {
            console.error('No auth token found');
            return null;
        }

        try {
            const response = await fetch(`${API_URL}/api/user/scratch`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                retryCount: 3,
                retryDelay: 1000,
                retryStatusCodes: [408, 500, 502, 503, 504]
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            
            if (data.total_scratch_cards !== undefined) {
                setTotalScratchCards(data.total_scratch_cards);
            }
            
            if (data.current_scratch_card_value) {
                return data.current_scratch_card_value;
            }
            
            return null;
        } catch (error) {
            console.error('Error fetching scratch card:', error);
            return null;
        }
    };

    useEffect(() => {
        console.log('useEffect triggered with:', {
            currentScratchValue,
            isScratching,
            hasRef: !!scratchCardRef.current
        });

        const initScratchCard = async () => {
            console.log('Attempting to initialize scratch card');
            if (scratchCardRef.current && !isScratching) {
                console.log('Conditions met for initialization');
                setIsScratching(true);
                try {
                    const sc = new ScratchCard(scratchCardRef.current, {
                        scratchType: SCRATCH_TYPE.LINE,
                        containerWidth: 280,
                        containerHeight: 280,
                        imageForwardSrc: `/${getRandomImage(frontImages)}`,
                        imageBackgroundSrc: '',
                        htmlBackground: `
                            <div style="position: absolute; inset: 0; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; z-index: -2;">
                                <img 
                                    src="/${getRandomImage(backImages)}" 
                                    style="position: absolute; inset: 0; width: 99%; height: 100%;  z-index: -1;" 
                                />
                                <div style="position: relative; z-index: 1; display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;">
                                    <span style="font-family: 'Luckiest Guy', cursive; font-size: 4.5rem; color: white; text-shadow: 2px 2px 4px rgba(0,0,0,0.5);">
                                        💰${currentScratchValue}
                                    </span>
                                </div>
                            </div>
                        `,
                        clearZoneRadius: 30,
                        nPoints: 30,
                        pointSize: 4,
                        enabledPercentUpdate: true,
                        percentToFinish: 50,
                        callback: async function () {
                            console.log('Scratch complete callback triggered');
                            setShowCelebration(true);
                            const result = await processScratchCard();
                            console.log('Process scratch card result:', result);
                            
                            if (result && result.new_balance !== undefined) {
                                onBalanceUpdate(result.new_balance);
                            }
                            
                            await new Promise(resolve => setTimeout(resolve, 3000));
                            
                            setShowCelebration(false);
                            
                            if (result && result.new_total_scratch_cards > 0) {
                                setTotalScratchCards(result.new_total_scratch_cards);
                                console.log('Fetching new scratch card value');
                                const newScratchValue = await fetchScratchCardValue();
                                console.log('New scratch value received:', newScratchValue);
                                
                                if (newScratchValue) {
                                    console.log('Clearing container and updating state');
                                    if (scratchCardRef.current) {
                                        console.log('Container before clear:', scratchCardRef.current.innerHTML);
                                        scratchCardRef.current.innerHTML = '';
                                        console.log('Container after clear:', scratchCardRef.current.innerHTML);
                                    }
                                    setCurrentScratchValue(newScratchValue);
                                    setIsScratching(false);
                                } else {
                                    onClose();
                                }
                            } else {
                                setTotalScratchCards(0);
                                onClose();
                            }
                        }
                    });

                    console.log('Scratch card instance created');
                    await sc.init();
                    console.log('Scratch card initialized successfully');
                } catch (error) {
                    console.error('Error initializing scratch card:', {
                        error,
                        containerState: scratchCardRef.current?.innerHTML,
                        isScratching,
                        currentValue: currentScratchValue
                    });
                }
            }
        };

        initScratchCard();
    }, [currentScratchValue, isScratching]);

    return (
        <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="relative overflow-hidden bg-black/40 rounded-xl p-6 max-w-sm w-full border border-white/20">
                {showCelebration && (
                    <div className="scratch-celebration-container">
                        {[...Array(20)].map((_, i) => (
                            <div
                                key={i}
                                className="scratch-floating-emoji"
                                style={{
                                    '--delay': `${Math.random() * 0.5}s`,
                                    '--duration': `${2 + Math.random() * 1}s`,
                                    '--x': `${-50 + Math.random() * 100}%`,
                                    '--scale': `${0.5 + Math.random() * 0.5}`,
                                    left: `${Math.random() * 100}%`
                                }}
                            >
                                {['🎉', '✨', '💫', '🎊'][Math.floor(Math.random() * 4)]}
                            </div>
                        ))}
                    </div>
                )}
                <div className="relative z-10">
                    <h2 className="text-2xl font-luckiest-guy text-white mb-6 text-center">
                        {getWelcomeMessage(newCards)}
                    </h2>
                    
                    <div className="flex justify-center items-center mb-6">
                        <div 
                            ref={scratchCardRef}
                            id="js--sc--container" 
                            className="sc__wrapper w-[280px] h-[280px] relative overflow-hidden"
                        />
                        {showCelebration && (
                    <div className="scratch-celebration-container">
                        {[...Array(20)].map((_, i) => (
                            <div
                                key={i}
                                className="scratch-floating-emoji"
                                style={{
                                    '--delay': `${Math.random() * 0.5}s`,
                                    '--duration': `${2 + Math.random() * 1}s`,
                                    '--x': `${-50 + Math.random() * 100}%`,
                                    '--scale': `${0.5 + Math.random() * 0.5}`,
                                    left: `${Math.random() * 100}%`
                                }}
                            >
                                {['🎉', '✨', '💫', '🎊'][Math.floor(Math.random() * 4)]}
                            </div>
                            ))}
                        </div>
                    )}  
                    </div>

                <p className="text-white mb-6 font-luckiest-guy text-center">
                    SCRATCH TO REVEAL YOUR PRIZE!
                </p>

                    <button
                        onClick={onClose}
                        className="mt-4 px-8 py-2 mx-auto block
                            bg-white/10 hover:bg-white/20
                            text-white font-luckiest-guy
                            rounded-full 
                            transition-all duration-300
                            flex items-center justify-center gap-2
                            border border-white/20"
                    >
                        CLOSE ×
                    </button>
                </div>
                <div className="absolute inset-0 bg-gradient-to-r from-[#ff44447c] via-[#ffd7007c] to-[#ff44447c] animate-modalFire" />
            </div>
        </div>
    );
};

export default ScratchCardModal;
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'; // Import toast for notifications
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import LoadingSpinner from './LoadingSpinner';

function Waitlist() {
    const [email, setEmail] = useState(''); // State for email input
    const [loading, setLoading] = useState(true); // Set loading to true initially
    const [hasSubmittedEmail, setHasSubmittedEmail] = useState(false); // State for email submission status
    const { t } = useTranslation();
    const API_URL = process.env.REACT_APP_API_URL;

    // New useEffect to check email status on load
    useEffect(() => {
        const checkEmailStatus = async () => {
            const authToken = Cookies.get('authToken');
            try {
                const response = await fetch(`${API_URL}/api/user/email-status`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data.hasSubmittedEmail) {
                        setHasSubmittedEmail(true); // Set state if email has been submitted
                        window.location.href = '/waitlist-success'; // Redirect to success page
                    }
                }
            } catch (error) {
                console.error('Error checking email status:', error);
            } finally {
                setLoading(false); // Set loading to false after the API call
            }
        };

        checkEmailStatus(); // Call the function on component mount
    }, [API_URL]);

    // Show loader while checking email status
    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <LoadingSpinner />
            </div>
        );
    }

    // Prevent rendering if email has been submitted
    if (hasSubmittedEmail) {
        return null; // Don't render anything if already submitted
    }

    const handleJoinWaitlist = async () => {
        // Validate email
        if (!email) {
            toast.error('Email is required.'); // Notify user if email is empty
            return;
        }
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex pattern
        if (!emailPattern.test(email)) {
            toast.error('Please enter a valid email address.'); // Notify user if email is invalid
            return;
        }
        const authToken = Cookies.get('authToken');
        setLoading(true);
        try {
            const response = await fetch(`${API_URL}/api/user/submit-email`, { // Call the submit email API
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify({ email }), // Send email in the request body
                retryCount: 3,
                retryDelay: 1000,
                retryStatusCodes: [408, 500, 502, 503, 504]
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            toast.success('Successfully joined the waitlist!'); // Notify user of success
            window.location.href = '/waitlist-success'; // Redirect using window.location
        } catch (error) {
            console.error('Error joining waitlist:', error); // Log the error
            toast.error('An error occurred while joining the waitlist.'); // Notify user of the error
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="h-screen flex items-center justify-center p-4">
            <div className="p-6 rounded-lg w-full max-w-md text-center shadow-[0_10px_15px_-3px_rgba(250,250,250,0.3)]" style={{ backgroundColor: '#fafafa' }}>

                <div className="flex justify-center mb-6">
                    <img src={"peperuney-chess.jpg"} className="app-logos inline-block w-48 h-48 mx-auto" alt="Betting Hub" />
                </div>

                <h1 className="text-3xl font-luckiest-guy mb-4 text-center" style={{ fontSize: '21.9pt' }}>
                    JOIN THE WAITLIST
                </h1>
                <p className="text-black-600 mb-4 text-center"
                    style={{ 
                        fontFamily: 'Palmer Lake',
                        fontSize: '23pt',
                        lineHeight: '.8',
                        fontWeight: 'regular'
                    }}>
                    FOR THE SECRET SAUCE, LOOK WHERE THE PAPER PLANE LANDS… <strong>X</strong> MARKS THE SPOT… ASK A FRIEND FOR YOUR SLICE! #PEPERUNEY <span style={{ display: 'inline-block', transform: 'scale(0.8)' }}>🍕</span>
                </p>
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="border border-gray-300 px-5 rounded-full w-full mb-4 bg-[#eaeaea]"
                    style={{ 
                        fontFamily: 'Palmer Lake',
                        fontSize: '20.8pt',
                        color: '#838383',
                        fontWeight: 'regular'
                    }}
                    required
                />
                <div className="text-center">
                    <button
                        onClick={handleJoinWaitlist}
                        className={`bg-[#2f7821] hover:bg-[#246119] text-white py-3 px-8 rounded-full transition-all duration-300 font-luckiest-guy ${loading ? 'opacity-50' : ''}`}
                        style={{ fontSize: '12.5pt' }}
                        disabled={loading}
                    >
                        {loading ? 'JOINING...' : 'JOIN WAITLIST'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Waitlist;

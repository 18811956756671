import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import LoadingSpinner from './LoadingSpinner'; // Assuming you have a loading spinner component
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

const API_URL = process.env.REACT_APP_API_URL; // Accessing the API URL from the environment variable

function StreakConfig() {
    const [streakConfigs, setStreakConfigs] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        const authToken = Cookies.get('authToken');

        if (!authToken) {
            console.error('No auth token found');
            setLoading(false);
            return;
        }

        fetch(`${API_URL}/api/user/click-to-bake/config`, { // Use API_URL here
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            retryCount: 3,
            retryDelay: 1000,
            retryStatusCodes: [408, 500, 502, 503, 504]
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setStreakConfigs(data.streakConfigs);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
            });

        // Initialize Telegram WebApp SDK and configure the back button
        const tg = window.Telegram?.WebApp;
        if (tg) {
            tg.BackButton.show(); // Show the back button
            tg.BackButton.onClick(() => {
                console.log("Back button clicked!");
                window.history.back(); // Go back when the button is clicked
            });

            // Cleanup when the component unmounts
            return () => {
                tg.BackButton.hide();
                tg.BackButton.offClick(); // Remove the event listener
            };
        }
    }, []);

    return (
        <div className="h-full overflow-y-auto touch-pan-y relative pb-20">
            <button 
                onClick={() => navigate(-1)} 
                className="absolute top-4 right-4 text-white font-bold text-xl z-10"
            >
                ✕
            </button>

            <div className="mt-4 text-center">
                <img src={"Arcade.gif"} className="app-logos inline-block w-30 h-30" alt="Peperuney Pizza Club" />
            </div>

            <div className="max-w-3xl mx-auto px-4 mb-8 mt-6">
                <h1 className="text-center text-3xl font-luckiest-guy text-white mb-6">{t('sr_reward')}</h1>
                <div className="bg-white/80 rounded-lg p-6 shadow-lg backdrop-blur-sm">
                    <h2 className="text-xl font-luckiest-guy text-gray-900 mb-3">{t('sr_how')}</h2>
                    <p className="text-gray-700 mb-4">
                        {t('sr_how_explain')}
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                        <div className="bg-orange-100/70 p-4 rounded-lg">
                            <h3 className="font-luckiest-guy text-gray-900 mb-2">{t('sr_daily')}</h3>
                            <p className="text-sm">{t('bake_expl')}</p>
                        </div>
                        <div className="bg-green-100/70 p-4 rounded-lg">
                        <h3 className="font-luckiest-guy text-gray-900 mb-2">{t('sr_earn')}</h3>
                            <p className="text-sm">{t('h_streak')}</p>
                        </div>
                        <div className="bg-blue-100/70 p-4 rounded-lg">
                        <h3 className="font-luckiest-guy text-gray-900 mb-2">{t('sr_level')}</h3>
                            <p className="text-sm">{t('sr_level_expl')}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="glassmorphic-container max-w-2xl mx-auto px-4">
                {loading ? (
                    <div className="flex justify-center items-center h-40">
                        <LoadingSpinner />
                    </div>
                ) : (
                    <div className="bg-white/90 rounded-lg shadow-lg backdrop-blur-sm">
                        <div className="max-h-[400px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
                            <table className="w-full">
                                <thead className="sticky top-0 bg-white/95 shadow-sm">
                                    <tr>
                                        <th className="py-4 px-6 text-left text-gray-700">{t('se_lvl')}</th>
                                        <th className="py-4 px-6 text-right text-gray-700">{t('se_points')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {streakConfigs.map((config) => (
                                        <tr key={config.streak_range} className="border-t hover:bg-gray-50/50">
                                            <td className="py-3 px-6 text-left">{config.streak_range}</td>
                                            <td className="py-3 px-6 text-right font-semibold text-green-600">
                                                +{config.points}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>

            <div className="fixed bottom-0 left-0 right-0">
                <Footer />
            </div>
        </div>
    );
}

export default StreakConfig;

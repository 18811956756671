// src/components/TelegramConfig.js
import { useEffect } from 'react';
import TelegramWebApp from '@twa-dev/sdk';

function TelegramConfig() {
    useEffect(() => {
        if (TelegramWebApp) {
            TelegramWebApp.ready();
            TelegramWebApp.expand();
            TelegramWebApp.setHeaderColor('#c6392d');
            TelegramWebApp.setBackgroundColor('#c6392d');

            if (window.Telegram.WebApp) {
                window.Telegram.WebApp.headerColor = '#c6392d';
                window.Telegram.WebApp.backgroundColor = '#c6392d';
                window.Telegram.WebApp.headerTextColor = '#4A4A4A';
            }
            window.Telegram.WebApp.disableVerticalSwipes();
        }
    }, []);

    return null;
}

export default TelegramConfig;
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from './LoadingSpinner';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import Footer from './Footer';

const API_URL = process.env.REACT_APP_API_URL;

function LeaderBoard() {
    const { t } = useTranslation();
    const [userPoints, setUserPoints] = useState(0);
    const [topUsers, setTopUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [topPlayers, setTopPlayers] = useState([]);


    useEffect(() => {
        const authToken = Cookies.get('authToken');

        if (!authToken) {
            setError(t('No auth token found'));
            setLoading(false);
            return;
        }

        // Fetch user points
        fetch(`${API_URL}/api/userleaderboard`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            retryCount: 3,
            retryDelay: 1000,
            retryStatusCodes: [408, 500, 502, 503, 504]
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setUserPoints(data.points);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setError(t('Failed to load user points.'));
                setLoading(false);
            });

        // Fetch user leaderboard
        fetch(`${API_URL}/api/userleaderboard`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setCurrentUser(data.currentUser);
                setTopPlayers(data.topPlayers);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setError(t('Failed to load leaderboard.'));
                setLoading(false);
            });
    }, [t]);

    return (
        <div className="flex flex-col h-full">
            {loading ? (
                <LoadingSpinner />
            ) : error ? (
                <div>{error}</div>
            ) : (
                <div className="flex-1 overflow-y-auto px-4 pb-4">
                    {/* Current User Profile Card */}
                    {currentUser && (
                        <div className="mt-6 bg-gradient-to-br from-indigo-600/90 to-purple-600/90 backdrop-filter backdrop-blur-lg bg-opacity-50 text-white p-6 rounded-2xl shadow-xl mb-6 border border-white/20">
                            <div className="flex flex-col md:flex-row justify-between items-center">
                                {/* User Identity Section */}
                                <div className="text-center md:text-left mb-4 md:mb-0">
                                    <h2 className="text-2xl font-bold mb-1">@{currentUser.telegram_name}</h2>
                                </div>

                                {/* Stats Section */}
                                <div className="flex gap-4">
                                    <div className="text-center px-4 py-2 bg-white/20 backdrop-blur-xl rounded-xl border border-white/10 hover:bg-white/25 transition-all duration-300">
                                        <div className="text-2xl font-bold">{currentUser.total_points}</div>
                                        <div className="text-xs text-indigo-100">{t('Points')}</div>
                                    </div>
                                    <div className="text-center px-4 py-2 bg-white/20 backdrop-blur-xl rounded-xl border border-white/10 hover:bg-white/25 transition-all duration-300">
                                        <div className="text-2xl font-bold">#{currentUser.rank}</div>
                                        <div className="text-xs text-indigo-100">{t('Rank')}</div>
                                    </div>
                                    <div className="text-center px-4 py-2 bg-white/20 backdrop-blur-xl rounded-xl border border-white/10 hover:bg-white/25 transition-all duration-300">
                                        <div className="text-xl font-bold">{currentUser.badge}</div>
                                        <div className="text-xs text-indigo-100">{t('Badge')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Leaderboard Section */}
                    <div className="mb-4">
                        <div className="flex items-center justify-between mb-4">
                            <h3 className="text-xl font-bold text-gray-800">{t('Leaderboard')}</h3>
                            <div className="h-1 w-24 bg-gradient-to-r from-indigo-600 to-purple-600 rounded-full"></div>
                        </div>

                        <div className="bg-white rounded-2xl shadow-lg p-3">
                            <ul className="space-y-2">
                                {topPlayers.map((player, index) => (
                                    <li
                                        key={player.username}
                                        className={`flex justify-between items-center p-3 rounded-xl transition-all duration-300 
                                            ${index === 0 ? 'bg-gradient-to-r from-yellow-100 to-yellow-50 border border-yellow-300' :
                                                index === 1 ? 'bg-gradient-to-r from-gray-100 to-gray-50 border border-gray-300' :
                                                    index === 2 ? 'bg-gradient-to-r from-orange-100 to-orange-50 border border-orange-300' :
                                                        'bg-white hover:bg-gray-50 border border-gray-100'}`}
                                    >
                                        <div className="flex items-center space-x-3 flex-1 min-w-0">
                                            <div className={`w-6 h-6 flex-shrink-0 flex items-center justify-center rounded-full font-bold text-sm
                                                ${index === 0 ? 'bg-yellow-400 text-white' :
                                                    index === 1 ? 'bg-gray-400 text-white' :
                                                        index === 2 ? 'bg-orange-400 text-white' :
                                                            'bg-gray-200 text-gray-700'}`}>
                                                {index + 1}
                                            </div>
                                            <div className="flex flex-col min-w-0">
                                                <span className="font-luckiest-guy" style={{ fontSize: '1.0rem', lineHeight: '1.75' }}>
                                                    {player.badge}
                                                </span>
                                                <span className="text-gray-500 truncate" style={{ fontSize: '0.90rem', lineHeight: '1rem' }}>
                                                    {player.telegram_name}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 ml-4">
                                            <span className="font-luckiest-guy text-gray-700 text-lg">
                                                {player.total_points.toLocaleString()}
                                            </span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
            <Footer />
        </div>
    );
}

export default LeaderBoard;

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { PublicKey } from '@solana/web3.js';
import nacl from 'tweetnacl';
import bs58 from 'bs58';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import Footer from './Footer';
import Twallet from './Twallet';

const API_URL = process.env.REACT_APP_API_URL;

// Browser-compatible decryption helper
const decryptPayload = (data, nonce, sharedSecret) => {
    if (!sharedSecret) throw new Error("missing shared secret");
    const decryptedData = nacl.box.open.after(bs58.decode(data), bs58.decode(nonce), sharedSecret);
    if (!decryptedData) {
        throw new Error("Unable to decrypt data");
    }
    return JSON.parse(new TextDecoder().decode(decryptedData));
};

export default function Wallet() {
    const { t } = useTranslation();
    const [phantomWalletPublicKey, setPhantomWalletPublicKey] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [walletData, setWalletData] = useState(null);
    const [isWalletConnected, setIsWalletConnected] = useState(false);
    const [isCheckingConnection, setIsCheckingConnection] = useState(true);
    const [isDisconnecting, setIsDisconnecting] = useState(false);
    const [showTwalletComponent, setShowTwalletComponent] = useState(false);

    // Generate keypair on component mount
    const [dappKeyPair] = useState(() => {
        const newKeyPair = nacl.box.keyPair();
        // alert('Generated new keypair');
        return newKeyPair;
    });

    // Add new loading state
    const [isLoading, setIsLoading] = useState(true);

    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        let timer;
        if (isWalletConnected) {
            // Start countdown
            timer = setInterval(() => {
                setCountdown((prev) => {
                    if (prev <= 1) {
                        clearInterval(timer);
                        // First send data and redirect
                        if (window.Telegram?.WebApp) {
                            window.Telegram.WebApp.sendData(JSON.stringify({ action: 'refresh' }));
                            window.Telegram.WebApp.openTelegramLink('https://t.me/peperuney_bot/gameapp?startapp');
                            // Attempt to close window after a small delay
                            setTimeout(() => {
                                try {
                                    window.close();
                                } catch (e) {
                                    console.log('Could not close window automatically');
                                }
                            }, 500);
                        } else {
                            window.location.href = 'https://t.me/peperuney_bot/gameapp?startapp';
                            // Attempt to close window after a small delay
                            setTimeout(() => {
                                try {
                                    window.close();
                                } catch (e) {
                                    console.log('Could not close window automatically');
                                }
                            }, 500);
                        }
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [isWalletConnected]);
    const handleDeepLink = useCallback(async (url) => {
        try {
            setError(null);
            setIsCheckingConnection(true);
            const parsedUrl = new URL(url);
            const params = parsedUrl.searchParams;
    
            if (params.get('errorCode')) {
                const error = Object.fromEntries([...params]);
                setError('Connection declined');
                setIsCheckingConnection(false);
                return;
            }
    
            // Try to get the secret key from the combined parameter
            const combinedParam = params.get('combined');
            let secretKeyFromParams;
            
            if (combinedParam) {
                const [authToken, dappSecretKey] = combinedParam.split('|||');
                secretKeyFromParams = dappSecretKey;
                // Store authToken if needed
                if (authToken) {
                    Cookies.set('authToken', authToken);
                }
            }
    
            if (!secretKeyFromParams) {
                throw new Error('No secret key found in URL');
            }
    
            // Rest of the function remains the same
            const secretKey = bs58.decode(secretKeyFromParams);
            const sharedSecretDapp = nacl.box.before(
                bs58.decode(params.get('phantom_encryption_public_key')),
                secretKey
            );
    
            const connectData = decryptPayload(
                params.get('data'),
                params.get('nonce'),
                sharedSecretDapp
            );
    
            if (connectData.public_key) {
                const publicKey = new PublicKey(connectData.public_key);
                setPhantomWalletPublicKey(publicKey.toString());
                
                // Use the authToken we extracted from combined parameter
                const authToken = Cookies.get('authToken');
                if (authToken) {
                    await updateWalletAddress(publicKey.toString(), authToken);
                }
                setIsWalletConnected(true);
            }
        } catch (error) {
            const errorMsg = 'Error in handleDeepLink: ' + error.message;
            setError(errorMsg);
            console.error('Error in handleDeepLink:', error);
        } finally {
            setIsCheckingConnection(false);
        }
    }, []);

    useEffect(() => {
        const initialUrl = window.location.href;
        if (initialUrl.includes('phantom_encryption_public_key')) {
            //alert('Found Phantom data in URL, handling connection...');
            handleDeepLink(initialUrl);
        }
        else {
            setIsCheckingConnection(false); // No wallet connection attempt
        }
        fetchWalletData();
    }, [handleDeepLink]);

    const connect = async () => {
        try {
            setError(null);
            setSubmitting(true);

            const authToken = Cookies.get('authToken');
            if (!authToken) {
                throw new Error('No auth token found');
            }

            // Check specifically for Telegram desktop platform
            if (window.Telegram?.WebApp?.platform === 'tdesktop' ||
                window.Telegram?.WebApp?.platform === 'weba') {
                // Desktop/Web Telegram - show Twallet
                setShowTwalletComponent(true);
            } else {
                // Mobile or other platforms - use Phantom URL flow
                const dappPublicKey = bs58.encode(dappKeyPair.publicKey);
                const dappSecretKey = bs58.encode(dappKeyPair.secretKey);

                const combinedToken = `${authToken}|||${dappSecretKey}`;
                const params = new URLSearchParams({
                    dapp_encryption_public_key: dappPublicKey,
                    cluster: 'mainnet-beta',
                    app_url: window.location.origin,
                    redirect_link: `${window.location.href}?combined=${combinedToken}`,
                });

                const phantomConnectUrl = `https://phantom.app/ul/v1/connect?${params.toString()}`;
                window.Telegram.WebApp.openLink(phantomConnectUrl, {
                    try_instant_view: false,
                });
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setSubmitting(false);
        }
    };
    //Disconecting wallet and updating solana address to null
    const disconnect = async () => {
        try {
            setIsDisconnecting(true);
            const authToken = Cookies.get('authToken');
            if (!authToken) {
                throw new Error('No auth token found');
            }
            const response = await fetch(`${API_URL}/api/update-wallets`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                    solana_wallet_address: '',
                }),
                retryCount: 3,
                retryDelay: 1000,
                retryStatusCodes: [408, 500, 502, 503, 504]
            });

            if (!response.ok) {
                throw new Error('Failed to update wallet address');
            }

            // Refresh wallet data after successful disconnect
            await fetchWalletData();
        } catch (error) {
            setError(error.message);
        } finally {
            setIsDisconnecting(false);  // Reset loading state
            setPhantomWalletPublicKey(null);
            setError(null);
        }
    }

    const updateWalletAddress = async (address, authToken) => {
        try {
            const response = await fetch(`${API_URL}/api/update-wallets`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                    solana_wallet_address: address,
                }),
                retryCount: 3,
                retryDelay: 1000,
                retryStatusCodes: [408, 500, 502, 503, 504]
            });

            if (!response.ok) {
                throw new Error('Failed to update wallet address');
            }
        } catch (error) {
            // alert('Error updating wallet: ' + error.message);
            setError(error.message);
        }
    };
    //fetching wallet info
    const fetchWalletData = async () => {
        const authToken = Cookies.get('authToken');
        if (!authToken) {
            console.error('No auth token found');
            setIsLoading(false);
            return;
        }

        try {
            console.log('Fetching wallet data with token:', authToken);
            const response = await fetch(`${API_URL}/api/wallets`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                retryCount: 3,
                retryDelay: 1000,
                retryStatusCodes: [408, 500, 502, 503, 504]
            });
            console.log('res-for-usr', response);

            const data = await response.json();

            if (!response.ok) {
                console.error('Error response:', data);
                throw new Error(data.error || `HTTP error! status: ${response.status}`);
            }

            console.log('Wallet data:', data);
            setWalletData(data);
        } catch (error) {
            console.error('Error fetching wallet data:', error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="h-full overflow-y-auto touch-pan-y">
            {showTwalletComponent ? (
                <Twallet />
            ) : (
                <div className="flex-grow flex items-start justify-center p-4">
                    {isCheckingConnection ? (
                        // Show loading spinner during connection check
                        <div className="flex justify-center items-center py-4">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                        </div>
                    ) :
                        isWalletConnected ? (
                            <div className="text-center">
                                <div className="flex justify-center mb-6">
                                    <img src={"together-peperuney.jpg"} className="w-60 h-60 rounded-full object-cover" alt="Peperuney Pizza Club" />
                                </div>
                                <h2 className="text-3xl mb-6 font-luckiest-guy shine-text uppercase">
                                    SOLANA WALLET ADDRESS ADDED SUCCESSFULLY
                                </h2>
                                <button
                                    className="btn-primary bg-[#2f7821] hover:bg-[#246119] text-white font-luckiest-guy py-3 px-8 mb-2 rounded-full transition-all duration-300"
                                    disabled
                                >
                                    RETURNING TO MINI APP IN {countdown}s...
                                </button>
                                <div className="mt-2 bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4" role="alert">
                                    <p className="text-sm">
                                        WELCOME TO THE SLICE CLUB, OG! PLEASE WAIT WHILE WE REDIRECT YOU BACK TO THE $PEPERUNEY MINI!
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div className="p-8 rounded-lg shadow-md max-w-md w-full h-full overflow-y-auto touch-pan-y" style={{ background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.0), rgba(245, 247, 250, 0.4))' }}>
                                <h2 className="text-3xl mb-6 font-luckiest-guy text-white text-center" style={{ fontSize: '1.75rem' }}>
                                    {t('AddWalletPrefix')}
                                    <span className="text-[#787eff]">{t('SolanaText')}</span>
                                    {t('AddWalletSuffix')}
                                </h2>
                                <div className="flex justify-center mb-6">
                                    <img 
                                        src={"peace-peperuney.png"} 
                                        className="wallet-pepe-image rounded-full object-cover" 
                                        style={{ width: '12rem', height: '12rem' }}
                                        alt="PepeRuney Slice Club" 
                                    />
                                </div>
                                {isLoading ? (
                                    <div className="flex justify-center items-center py-4">
                                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>

                                    </div>
                                ) : walletData && walletData.solana_wallet_address ? (
                                    <div>
                                        <div className="mb-3 p-3 bg-gray-100 rounded-lg">
                                            <span className="text-black font-luckiest-guy block mb-2">Connected to:</span>
                                            <div className="bg-white p-3 rounded-md break-all font-mono text-sm border border-gray-200 hover:border-gray-300 transition-colors">
                                                {walletData.solana_wallet_address}
                                            </div>
                                        </div>
                                        <p className="font-luckiest-guy text-green-600 mb-6">Status: Connected</p>
                                        <button onClick={disconnect} className="btn-primary w-full bg-gray-800 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded-xl">
                                            {isDisconnecting ? 'Disconnecting...' : 'Disconnect'}
                                        </button>
                                    </div>
                                ) : (
                                    <div>
                                        <button
                                            onClick={connect}
                                            disabled={submitting}
                                            className="btn-primary w-full bg-[#2f7821] hover:bg-[#246119] text-white font-luckiest-guy py-3 px-4 rounded-full transition-all duration-300"
                                        >
                                            {submitting ? 'Connecting...' : t('ConnectPhantomWallet')}
                                        </button>

                                        <p className="mt-6 text-white text-center" style={{ 
                                            fontFamily: 'Palmer Lake', 
                                            fontSize: '24px',
                                            lineHeight: '.77'
                                        }}>
                                            {t('SolanaWallet')}
                                        </p>

                                        {/* New informational message */}
                                        <div className="mt-6 bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-2" role="alert">
                                            <p className="text-sm" style={{ 
                                                fontFamily: 'Palmer Lake', 
                                                fontSize: '24px',
                                                lineHeight: '.77'
                                            }}>
                                                {t('WalletInfo')}
                                            </p>
                                        </div>
                                    </div>
                                )}

                            </div>
                        )}
                    {!isWalletConnected && <Footer />}
                </div>
            )}
        </div>
    );
}
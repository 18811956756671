import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getStoredLanguage, setStoredLanguage } from '../utils/languageUtils';
import { FaGlobeAmericas, FaGlobe, FaShoppingCart, FaShare, FaTwitter } from 'react-icons/fa';
import ScratchCardModal from './ScratchCardModal';
import HeaderScratchModal from './HeaderScratchModal';

const Header = ({ 
    firstName, 
    streakData, 
    canClick, 
    handleButtonClick, 
    pointsData, 
    telegram_name,
    walletData,
    isWalletConnected,
    scratchCardData 
}) => {
    const { t, i18n } = useTranslation();
    const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
    const [clickCount, setClickCount] = useState(0);
    const [clickTimer, setClickTimer] = useState(null);
    const [isClicking, setIsClicking] = useState(false);
    const [floatingPoints, setFloatingPoints] = useState([]);
    const [currentImage, setCurrentImage] = useState("1.png");
    const [isShaking, setIsShaking] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showBoostTooltip, setShowBoostTooltip] = useState(false); // Add this at the top with other state declarations
    const [showScratchModal, setShowScratchModal] = useState(false);
    const [showHeaderScratchModal, setShowHeaderScratchModal] = useState(false);

    // Add this for the pulse animation
    const pulseColor = isWalletConnected ? 'bg-green-500' : 'bg-orange-500';

    useEffect(() => {
        let shakeTimer;

        const startShakeTimer = () => {
            shakeTimer = setInterval(() => {
                if (!isClicking && canClick && currentImage === "1.png") {
                    setIsShaking(true);
                    setTimeout(() => setIsShaking(false), 1000);
                }
            }, 4000);
        };

        if (!isClicking && canClick && currentImage === "1.png") {
            startShakeTimer();
        }

        return () => {
            if (shakeTimer) clearInterval(shakeTimer);
        };
    }, [isClicking, canClick, currentImage]);

    useEffect(() => {
        if (scratchCardData?.new_cards > 0) {
            setShowScratchModal(true);
        }
    }, [scratchCardData]);

    const handleLanguageChange = (lang) => {
        setStoredLanguage(lang);
        i18n.changeLanguage(lang);
        setShowLanguageDropdown(false);
    };

    const getImageForClickCount = (count) => {
        const isAlternateClick = count % 2 === 0;
        if (count <= 4) return isAlternateClick ? "2.png" : "1.png";
        if (count <= 8) return isAlternateClick ? "4.png" : "3.png";
        if (count <= 12) return isAlternateClick ? "6.png" : "5.png";
        if (count <= 16) return isAlternateClick ? "8.png" : "7.png";
        if (count <= 20) return isAlternateClick ? "10.png" : "9.png";
        return "11.png";
    };

    const handleImageClick = (e) => {
        if (!canClick || clickCount >= 24) return;

        // Set clicking state to true when clicks start
        if (clickCount === 0) {
            setIsClicking(true);
        }

        // Clear existing timer if there is one
        if (clickTimer) {
            clearTimeout(clickTimer);
        }

        // Start/restart timer
        const timer = setTimeout(() => {
            setClickCount(0);
            setIsClicking(false);
            setCurrentImage("1.png");
        }, 10000);
        setClickTimer(timer);

        // Create floating emoji effect with random angles
        const rect = e.target.getBoundingClientRect();
        const emojis = ['🍕', '👨‍🍳', '🔥'];
        const newPoints = Array.from({length: 3}, (_, i) => ({
            id: Date.now() + i,
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
            emoji: emojis[i],
            angle: (i * 120 + Math.random() * 30) * (Math.PI / 180) // Spread emojis in different directions
        }));
        
        setFloatingPoints(prev => [...prev, ...newPoints]);

        // Remove floating emojis after animation
        setTimeout(() => {
            setFloatingPoints(prev => prev.filter(point => !newPoints.includes(point)));
        }, 1000);

        setClickCount(prev => {
            const newCount = prev + 1;
            setCurrentImage(getImageForClickCount(newCount));
            if (newCount === 24) {
                clearTimeout(clickTimer);
                setIsClicking(false);
                handleButtonClick(); // Call the API
                setTimeout(() => {
                    setClickCount(0);
                    setCurrentImage("1.png");
                }, 1000); // Reset after success
            }
            return newCount;
        });
    };

    const handleMenuClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    console.log("window.Telegram", window.Telegram);
    const isTelegramMobile = window.Telegram?.WebApp?.platform !== 'tdesktop';
    console.log("isTelegramMobile", isTelegramMobile);

    // Add useEffect for handling clicks/touches outside menu
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if click/touch is outside menu content and menu button
            const menuContent = document.querySelector('.menu-content');
            const menuButton = document.querySelector('.menu-button');
            
            if (isMenuOpen && 
                menuContent && 
                !menuContent.contains(event.target) && 
                menuButton && 
                !menuButton.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        // Add event listeners when menu is open
        if (isMenuOpen) {
            // Mouse events
            document.addEventListener('mousedown', handleClickOutside);
            // Touch events
            document.addEventListener('touchstart', handleClickOutside);
        }

        // Cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [isMenuOpen]);

    return (
        <header className="App-header text-white text-center flex flex-col items-center justify-start">
            <div className="w-full flex items-center justify-between px-4">
            <div className="flex items-center gap-2">
                    {/* Wallet Status Button */}
                    <Link 
                        to="/wallet"
                        className={`flex items-center bg-[#c6392d] p-2 h-12 w-16 justify-center hover:bg-[#a32d24] transition-colors ${!isWalletConnected ? 'wallet-shake' : ''}`}
                    >
                        <div className="flex items-center justify-center gap-2">
                            <i className="fas fa-wallet text-white text-xl"></i>
                            <div className={`w-2 h-2 rounded-full ${isWalletConnected ? 'bg-[#39FF14]' : 'bg-[#FF6B00]'} animate-pulse`}></div>
                        </div>
                    </Link>
                </div>

                <Link to="/leaderboard" className="flex items-center bg-[#2f7821] rounded-full h-10 relative px-0 min-w-[180px] hover:bg-[#246119] transition-colors">
                    <img
                        src="point.png"
                        alt="Profile"
                        className="w-11 h-11 rounded-full"
                        style={{ marginLeft: '0px' }}
                    />
                    <div
                        className="flex flex-col justify-center flex-grow mx-3"
                        style={{ marginTop: isTelegramMobile ? '4px' : '0' }}
                    >
                        <span className="text-white font-luckiest-guy text-xs leading-none">PROFILE</span>
                        <span className="text-white font-luckiest-guy text-sm leading-tight">{telegram_name}</span>
                    </div>
                    <div className="absolute w-3 h-3 rounded-full bg-red-500" style={{ top: '-0.3rem', right: '-0.3rem' }}></div>
                </Link>

                <div
                    className="bg-[#2f7821] p-2 h-12 w-16 flex items-center justify-center cursor-pointer"
                    onClick={() => setShowHeaderScratchModal(true)}
                >
                    <img 
                        src="scratch-icon.PNG" 
                        alt="Scratch" 
                        className="w-8 h-8 object-contain"
                    />
                </div>
            </div>

            <div className="flex items-center justify-center mt-4">
                <div className="inline-flex items-center space-x-2">
                    <div className="flex-shrink-0 w-12 h-12 flex items-center justify-center">
                        <img 
                            src="peperuney-token.png" 
                            alt="Token" 
                            className="w-12 h-12"
                        />
                    </div>
                    <div className="flex items-center h-12">
                        <span 
                            className="text-[#00ff00] font-luckiest-guy text-5xl points-display"
                            style={{
                                lineHeight: 1,
                                height: '48px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: isTelegramMobile ? '15px' : '0'
                            }}
                        >
                            {pointsData?.total_points?.toLocaleString() || '0'}
                        </span>
                    </div>
                </div>
            </div>

            <div className="relative h-[250px] w-full mb-2">
                <div
                    className={`app-logos-container header-component absolute left-0 right-0 top-[-60px] mx-auto w-[300px] ${canClick ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'}`}
                    onClick={handleImageClick}
                >
                    <img
                        src={currentImage}
                        className={`app-logos ${isShaking ? 'shake-animation' : ''}`}
                        alt="logo"
                    />

                    {/* Floating emojis */}
                    {floatingPoints.map(point => (
                        <div
                            key={point.id}
                            className="absolute pointer-events-none text-2xl animate-float-up"
                            style={{ 
                                left: `${point.x}px`, 
                                top: `${point.y}px`,
                                '--angle': `${point.angle}rad` 
                            }}
                        >
                            {point.emoji}
                        </div>
                    ))}

                    {/* Progress bar */}
                    {isClicking && (
                        <div className="progress-bar-container">
                            <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                                <div
                                    className="h-full fire-progress transition-all duration-100"
                                    style={{ width: `${(clickCount / 24) * 100}%` }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="button-container flex justify-center gap-4 mt-4 -mt-8">
                <Link
                    to="/streak-config"
                    className="relative flex-1 bg-white rounded-xl px-6 flex items-center justify-center group hover:bg-white/95 transition-all duration-200 shadow-md"
                >
                    <div className="absolute -top-2 -right-2 w-7 h-7 bg-[#ed1312] rounded-full flex items-center justify-center">
                        <span 
                            className="font-luckiest-guy text-white text-sm"
                            style={{
                                marginTop: isTelegramMobile ? '4px' : '0'
                            }}
                        >
                            {streakData ? streakData.currentStreak : 0}
                        </span>
                    </div>
                    <i className="fas fa-fire text-orange-500 text-xl mr-2"></i>
                    <div className="flex flex-col items-start min-w-0">
                        <span 
                            className="font-luckiest-guy text-[#2f7821] text-sm flex items-center whitespace-nowrap overflow-hidden"
                            style={{
                                marginTop: isTelegramMobile ? '6px' : '0'
                            }}
                        >
                            {t('current streak')}
                            <i className="fas fa-chevron-right text-xs ml-1 opacity-50 group-hover:opacity-100 transition-opacity flex-shrink-0"></i>
                        </span>
                    </div>
                </Link>

                <div className="flex-1 bg-white rounded-xl px-6  flex items-center justify-center shadow-md">
                    <i className="fas fa-clock text-blue-500 text-xl mr-2"></i>
                    <div className="flex flex-col items-start">
                        <span 
                            className="font-luckiest-guy text-[#2f7821] text-sm"
                            style={{
                                marginTop: isTelegramMobile ? '4px' : '0'
                            }}
                        >
                            {t('next bake')}
                        </span>
                        <span className="font-luckiest-guy text-[#ed1312] text-xs">
                            {streakData ? streakData.timeUntilNextClick : 'BAKE NOW'}
                        </span>
                    </div>
                </div>
            </div>
            {isTelegramMobile ? (
                <div 
                    className="fixed w-full max-w-[400px] mx-auto z-30"
                    style={{ 
                        bottom: '80px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        pointerEvents: isMenuOpen ? 'none' : 'auto'
                    }}
                >
                    <div className="w-full block relative">
                        <img 
                            src="half-pizza.png" 
                            alt="Follow Updates" 
                            className="w-full object-contain"
                            style={{
                                height: '180px',
                                objectPosition: 'bottom'
                            }}
                        />
                        <div className="absolute inset-x-0 bottom-[23%] flex justify-center">
                            <Link
                                to="https://t.me/+p-IpP9q2H0NlNGEx"
                                target="_blank"
                                className="bg-white px-6 py-1 rounded-full shadow-md"
                            >
                                <span 
                                    className="font-luckiest-guy text-[#2f7821] text-lg flex items-center gap-2"
                                    style={{
                                        marginTop: isTelegramMobile ? '7px' : '0'
                                    }}
                                >
                                    JOIN THE SLICE CLUB
                                    <i className="fas fa-arrow-right"></i>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            ) : (
                <button
                    className="mt-4 mb-8 mx-auto px-8 py-1 text-center text-xl 
                    bg-white text-[#2f7821] 
                    rounded-full hover:scale-105 transition-all duration-300 
                    shadow-lg hover:shadow-xl 
                    flex items-center gap-2"
                    onClick={() => window.open('https://t.me/+p-IpP9q2H0NlNGEx', '_blank')}
                >
                    <span className="font-luckiest-guy">JOIN THE SLICE CLUB</span>
                    <i className="fas fa-arrow-right"></i>
                </button>
            )}

            {/* Menu overlay */}
          

            {showHeaderScratchModal && (
                <HeaderScratchModal 
                    onClose={() => setShowHeaderScratchModal(false)}
                    scratch_card_value={scratchCardData?.current_scratch_card_value}
                    total_scratch_cards={scratchCardData?.total_scratch_cards}
                    onBalanceUpdate={(newBalance) => {
                        if (pointsData) {
                            pointsData.total_points = newBalance;
                        }
                    }}
                />
            )}
            {showScratchModal && scratchCardData && (
                <ScratchCardModal 
                    onClose={() => setShowScratchModal(false)}
                    newCards={scratchCardData.new_cards}
                    scratch_card_value={scratchCardData.current_scratch_card_value}
                    onBalanceUpdate={(newBalance) => {
                        // Update pointsData directly with the new balance
                        if (pointsData) {
                            pointsData.total_points = newBalance;
                        }
                    }}
                />
            )}
        </header>
    );

};

export default Header;
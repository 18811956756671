import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Footer from './Footer'; // Importing the Footer component
import { useTranslation } from 'react-i18next';

const Streak = () => {
    const { t } = useTranslation();
    const [pointsData, setPointsData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPointsData = async () => {
            const authToken = Cookies.get('authToken');

            if (!authToken) {
                console.error('No auth token found');
                setLoading(false);
                return;
            }

            try {
                const response = await fetch('https://api.peperuney.pizza/api/points/scores', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`,
                    },
                    retryCount: 3,
                    retryDelay: 1000,
                    retryStatusCodes: [408, 500, 502, 503, 504]
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setPointsData(data);
                setLoading(false);
            } catch (error) {
                console.error('Error:', error);
                setLoading(false);
            }
        };

        fetchPointsData();
    }, []);

    return (
        <div className="streak-info flex flex-col min-h-screen"> {/* Removed background color */}
            <div className="flex-grow p-4 bg-white rounded-lg shadow-lg m-4"> {/* Added padding, background, and margin */}
                <h2 className="text-2xl font-bold mb-2">Streak Information</h2>
                <p>Here you can display detailed information about the user's streak.</p>
                {loading ? (
                    <p>{t('lod')}</p>
                ) : (
                    pointsData && (
                        <div className="mt-4">
                            <p className="text-lg"><strong>Current Active Tokens:</strong> {pointsData.points_to_pay_out}</p>
                            <p className="text-lg"><strong>Tokens Paid Out:</strong> {pointsData.points_paid_out}</p>
                            <p className="text-lg"><strong>Lifetime Points Earned:</strong> {pointsData.total_points}</p>
                        </div>
                    )
                )}
            </div>
            <Footer /> {/* Including the Footer component */}
        </div>
    );
};

export default Streak;

import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import { useTranslation } from 'react-i18next'

export default function Betting() {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        // Initialize Telegram WebApp SDK and configure the back button
        const tg = window.Telegram?.WebApp;
        if (tg) {
            tg.BackButton.show(); // Show the back button
            tg.BackButton.onClick(() => {
                window.history.back(); // Go back when the button is clicked
            });

            // Cleanup when the component unmounts
            return () => {
                tg.BackButton.hide();
                tg.BackButton.offClick(); // Remove the event listener
            };
        }
    }, []);

    return (
        <div className="h-full overflow-y-auto touch-pan-y">
            <div className="flex-grow flex items-center justify-center p-4 pb-20">
                {/* Scroll Indicator - Fixed position relative to viewport but above footer */}
                <div className="fixed bottom-20 right-6 z-30 bg-white/10 backdrop-blur-sm p-3 rounded-full shadow-lg border border-white/10 animate-bounce cursor-pointer">
                    <svg 
                        className="w-6 h-6 text-gray-400" 
                        fill="none" 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth="2" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor"
                    >
                        <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                    </svg>
                </div>

                <div className="betting-container p-8 rounded-lg shadow-md max-w-md w-full relative" style={{ background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.0), rgba(245, 247, 250, 0.4))' }}>
                    <h2 className="text-3xl mb-6 font-luckiest-guy text-white text-center">{t('BettingHub')}</h2>
                    <div className="flex justify-center mb-6">
                        <img src={"Poker.gif"} className="app-logos inline-block w-48 h-48 mx-auto" alt="Betting Hub" />
                    </div>
                    <p className="text-white text-center mb-2" style={{ 
                        fontFamily: 'Palmer Lake', 
                        fontSize: '30px',
                        lineHeight: '.8'
                    }}>{t('ExploreBet')}</p>
                    <button
                        onClick={() => setShowModal(true)}
                        className="btn-primary w-full bg-[#2f7821] hover:bg-[#246119] text-white font-luckiest-guy py-3 px-4 rounded-full transition-all duration-300">
                        {t('BetSmart')}
                    </button>

                    {/* Informational message */}
                    <div className="mt-6 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
                        <p className="text-sm pb-3">
                            {t('sen1')}
                        </p>
                        <p className="text-sm pb-3">
                            {t('sen2')}
                        </p>
                        <p className="text-sm pb-3">
                            {t('sen3')}
                        </p>
                    </div>
                </div>
            </div>

            {/* Modal */}
            {showModal && (
                <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50 
                              motion-safe:animate-[fadeIn_0.3s_ease-out]">
                    <div className="bg-gradient-to-br from-white/95 to-gray-50/90 rounded-3xl p-8 max-w-md w-full 
                                  shadow-2xl motion-safe:animate-[slideUp_0.4s_ease-out]
                                  relative overflow-hidden">
                        {/* Decorative elements */}
                        <div className="absolute -top-4 -left-4 w-20 h-20 bg-blue-500/20 rounded-full blur-2xl"></div>
                        <div className="absolute -bottom-4 -right-4 w-20 h-20 bg-blue-400/20 rounded-full blur-2xl"></div>

                        {/* Content */}
                        <div className="relative space-y-4">
                            <div className="flex items-center justify-center gap-2 mb-2">
                                <span className="text-2xl">🍕</span>
                                <h3 className="text-2xl font-luckiest-guy text-center text-black">
                                {t('sliceClubModalTitle')}
                                </h3>
                            </div>

                            <p className="text-black text-center" style={{ 
                                fontFamily: 'Palmer Lake', 
                                fontSize: '30px',
                                lineHeight: '.8'
                            }}>{t('sliceClubModal')}</p>

                            {/* Close button */}
                            <button
                                onClick={() => setShowModal(false)}
                                className="mt-6 w-3/4 mx-auto block bg-[#2f7821] hover:bg-[#246119] 
                                           text-white font-luckiest-guy py-2 px-4 rounded-full 
                                           transition-all duration-300 transform hover:scale-[1.02] 
                                           active:scale-[0.98] text-sm">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <Footer />
        </div>
    )
}

import React, { useEffect, useState } from 'react';
import TelegramWebApp from '@twa-dev/sdk';
import Cookies from 'js-cookie';
import Header from './Header';
import Footer from './Footer';
import LoadingSpinner from './LoadingSpinner';
import PizzaShop from './PizzaShop'; // Import PizzaShop component
import Terms from './Terms';
import Contest from './Contest';
import Modal from './Modal'; // We'll create this component
import { FaCopy } from 'react-icons/fa'; // For the copy icon
import { useTranslation } from 'react-i18next';
import Conngrats from './Conngrats'; // Import Congrats component
import Waitlist from './Waitlist';


const API_URL = process.env.REACT_APP_API_URL; // Accessing the API URL from the environment variable

function Home({ uncompletedTasksCount, updateTaskCount }) {
    const { t } = useTranslation();
    const [username, setUsername] = useState('');
    const [userId, setUserId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [initDataFetched, setInitDataFetched] = useState(false);
    const [streakData, setStreakData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [canClick, setCanClick] = useState(false);
    const [pointsData, setPointsData] = useState(null);
    const [pointsLoading, setPointsLoading] = useState(true);
    const [tasksViewed, setTasksViewed] = useState(false); // Default to false
    const [task1Viewed, setTask1Viewed] = useState(false); // Track task 1 viewed status
    const [task2Viewed, setTask2Viewed] = useState(false); // Track task 2 viewed status
    const [task3Viewed, setTask3Viewed] = useState(false); // Track task 3 viewed status
    const [showModal, setShowModal] = useState(false);
    const [referralLink, setReferralLink] = useState('');
    const [clickResponse, setClickResponse] = useState(null);
    const [randomMessage, setRandomMessage] = useState('');
    const [tasksLoading, setTasksLoading] = useState(true);
    const [showWaitlist, setShowWaitlist] = useState(false);
    const [showCongrats, setShowCongrats] = useState(false);
    const [telegram_name, setTelegramName] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [walletData, setWalletData] = useState(null);
    const [isWalletConnected, setIsWalletConnected] = useState(false);
    const [scratchCardData, setScratchCardData] = useState(null);
    const [totalPoints, setTotalPoints] = useState(0);

    useEffect(() => {
        if (TelegramWebApp && !initDataFetched) {
            TelegramWebApp.ready();
            TelegramWebApp.expand();
            TelegramWebApp.setHeaderColor('#c6392d');
            TelegramWebApp.setBackgroundColor('#c6392d');

            // Set text color using the proper WebApp method
            if (window.Telegram.WebApp) {
                window.Telegram.WebApp.headerColor = '#c6392d';
                window.Telegram.WebApp.backgroundColor = '#c6392d';
                window.Telegram.WebApp.headerTextColor = '#4A4A4A';
            }
            window.Telegram.WebApp.disableVerticalSwipes()
            const initDataString = window.Telegram.WebApp.initData;
            const startapp = TelegramWebApp.initDataUnsafe?.start_param;
            // console.log('initDataString', JSON.stringify({ initData: initDataString }));
            // console.log('startapp parameter:', startapp);

            const user = TelegramWebApp.initDataUnsafe?.user;
            if (user) {
                setUsername(user.username);
                setUserId(user.id);
                setFirstName(user.first_name);
            }
            fetch(`${API_URL}/api/user/telegram-mini-app-auth`, { // Updated API call
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ initData: initDataString }),
                retryCount: 3,
                retryDelay: 1000,
                retryStatusCodes: [408, 500, 502, 503, 504]
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    console.log(data);
                    setInitDataFetched(true);
                    Cookies.set('authToken', data.token, { secure: true, sameSite: 'Strict' });
                    setTelegramName(data.telegram_name); // set telegram name
                    setReferralCode(data.referral_code); // Store the referral code
                    if (data.hasReferral && data.hasPoints) {
                        setShowCongrats(false); // Hide Congrats if no tasks have been viewed
                        setShowWaitlist(false); // Hide Waitlist if no tasks have been viewed
                    } else if (data.hasReferral && !data.hasPoints) {
                        setShowCongrats(true); // Show Congrats if hasReferral is true and tasks have been viewed
                        setShowWaitlist(false); // Ensure Waitlist is hidden
                    } else {
                        setShowWaitlist(true); // Show Waitlist if hasReferral is false
                    }
                    fetchStreakData();
                    fetchPointsData();
                    fetchWalletData();
                    fetchScratchCardData();
                    checkTasksViewed(); // Check tasks viewed status
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                });

            if (startapp) {
                addReferral(startapp); // Pass the 'startapp' as the referral code
            }

            TelegramWebApp.onEvent('userChanged', (user) => {
                setUsername(user.username);
                setUserId(user.id);
                setFirstName(user.first_name);
            });
        }
    }, [initDataFetched]);

    const addReferral = (referralCode) => {
        const authToken = Cookies.get('authToken');

        if (!authToken) {
            console.error('No auth token found');
            return;
        }

        fetch(`${API_URL}/api/user/add-referral`, { // Call to add referral
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            body: JSON.stringify({ referralCode }),
            retryCount: 3,
            retryDelay: 1000,
            retryStatusCodes: [408, 500, 502, 503, 504] // Send startapp as the referral code
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                console.log('Referral response:', data); // Log success message
            })
            .catch((error) => {
                console.error('Error adding referral:', error); // Log error message
            });
    };

    const checkTasksViewed = () => {
        const authToken = Cookies.get('authToken');

        if (!authToken) {
            console.error('No auth token found');
            setTasksLoading(false);
            setLoading(false);
            return;
        }

        fetch(`${API_URL}/api/tasks`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            retryCount: 3,
            retryDelay: 1000,
            retryStatusCodes: [408, 500, 502, 503, 504]
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                const task1 = data.find(task => task.task_id === 1);
                const task2 = data.find(task => task.task_id === 2);
                const task3 = data.find(task => task.task_id === 3);
                const isTask1Viewed = task1 && task1.viewed;
                const isTask2Viewed = task2 && task2.viewed;
                const isTask3Viewed = task3 && task3.viewed;
                setTask1Viewed(isTask1Viewed);
                setTask2Viewed(isTask2Viewed);
                setTask3Viewed(isTask3Viewed);
                setTasksViewed(isTask1Viewed && isTask2Viewed && isTask3Viewed);
                const uncompletedCount = data.filter(task => !task.viewed).length;
                updateTaskCount(uncompletedCount);
                setTasksLoading(false);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setTasksLoading(false);
                setLoading(false);
            });
    };

    const fetchStreakData = () => {
        const authToken = Cookies.get('authToken');

        if (!authToken) {
            console.error('No auth token found');
            setLoading(false);
            return;
        }

        setLoading(true);

        fetch(`${API_URL}/api/user/click-to-bake/streak`, { // Updated API call
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            retryCount: 3,
            retryDelay: 1000,
            retryStatusCodes: [408, 500, 502, 503, 504]
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                // console.log('Streak data:', data);
                setStreakData(data);
                setLoading(false);

                const canClickCondition = data.timeUntilNextClick === "Bake Now";
                setCanClick(canClickCondition);
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
            });
    };

    const fetchPointsData = () => {
        const authToken = Cookies.get('authToken');

        if (!authToken) {
            console.error('No auth token found');
            setPointsLoading(false);
            return;
        }

        setPointsLoading(true);

        fetch(`${API_URL}/api/points/scores`, { // Updated API call
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            retryCount: 3,
            retryDelay: 1000,
            retryStatusCodes: [408, 500, 502, 503, 504]
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                // console.log('Points data:', data);
                setPointsData(data);
                setPointsLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setPointsLoading(false);
            });
    };

    const fetchWalletData = () => {
        const authToken = Cookies.get('authToken');

        if (!authToken) {
            console.error('No auth token found');
            return;
        }

        fetch(`${API_URL}/api/wallets`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            retryCount: 3,
            retryDelay: 1000,
            retryStatusCodes: [408, 500, 502, 503, 504]
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setWalletData(data);
                // console.log('Wallet data:', data);
                setIsWalletConnected(!!data?.solana_wallet_address);
            })
            .catch((error) => {
                console.error('Error fetching wallet data:', error);
            });
    };

    const fetchScratchCardData = () => {
        const authToken = Cookies.get('authToken');

        if (!authToken) {
            console.error('No auth token found');
            return;
        }

        fetch(`${API_URL}/api/user/scratch`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                setScratchCardData(data);
            })
            .catch(error => {
                console.error('Error fetching scratch card data:', error);
            });
    };

    const handleButtonClick = () => {
        if (!canClick) {
            console.error('You cannot click yet');
            return;
        }

        const authToken = Cookies.get('authToken');

        if (!authToken) {
            console.error('No auth token found');
            return;
        }

        fetch(`${API_URL}/api/user/click-to-bake`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            body: JSON.stringify({ user_id: userId }),
            retryCount: 3,
            retryDelay: 1000,
            retryStatusCodes: [408, 500, 502, 503, 504]
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                // console.log('Click-to-bake response:', data);
                setClickResponse(data);
                setRandomMessage(getRandomMessage());
                fetchStreakData();
                fetchPointsData();
                setReferralLink(`https://t.me/peperuney_bot/gameapp?startapp=${referralCode}`);
                setShowModal(true);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const handleCopyReferralLink = () => {
        navigator.clipboard.writeText(referralLink)
            .then(() => {
                alert('Referral link copied to clipboard!');
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    const getRandomMessage = () => {
        const messageKeys = ['Disp1', 'Disp2', 'Disp3', 'Disp4', 'Disp5', 'Disp6'];
        const randomKey = messageKeys[Math.floor(Math.random() * messageKeys.length)];
        return t(randomKey);
    };

    const handleContinue = () => {
        setLoading(true); // Set loading to true to indicate loading state
        setShowCongrats(false); // Hide the Congrats component
        setShowWaitlist(false); // Ensure Waitlist is hidden
        setTimeout(() => {
            // After the operation is complete, set loading to false
            setLoading(false);
        }, 1000); // Simulate a 1-second loading time
    };

    const handleBalanceUpdate = (newBalance) => {
        setTotalPoints(newBalance);
    };

    return (
        <div className="flex flex-col min-h-screen">
            {loading || tasksLoading ? (
                <LoadingSpinner />
            ) : showWaitlist ? (
                <Waitlist /> // Render Waitlist component
            ) : showCongrats ? (
                <Conngrats onContinue={handleContinue} /> // Pass the continue handler
            ) : (task1Viewed && task2Viewed && task3Viewed ? (
                <div className="flex flex-col flex-grow mt-1 mb-1">
<Header 
    firstName={firstName} 
    streakData={streakData} 
    canClick={canClick} 
    handleButtonClick={handleButtonClick} 
    pointsData={pointsData} 
    telegram_name={telegram_name}
    walletData={walletData}
    isWalletConnected={isWalletConnected}
    scratchCardData={scratchCardData}
/>                    <Footer uncompletedTasksCount={uncompletedTasksCount} />
                </div>
            ) : (
                !task1Viewed ? <PizzaShop /> :
                    !task2Viewed ? <Terms /> :
                        !task3Viewed ? <Contest /> :
                            <PizzaShop />
            ))}
            {showModal && clickResponse && (
                <Modal onClose={() => setShowModal(false)}>
                    <p className="text-xl mb-4 text-center font-luckiest-guy text-white">
                        {randomMessage}
                    </p>
                    <div className="space-y-1 mb-2">
                        <div className="flex items-center justify-between p-2 rounded-lg border-b border-white/20">
                            <span className="font-luckiest-guy text-white">{t('Points')}</span>
                            <span className="text-2xl font-luckiest-guy text-white">+{clickResponse.pointsEarned}</span>
                        </div>
                        <div className="flex items-center justify-between p-3 rounded-lg border-b border-white/20">
                            <span className="font-luckiest-guy text-white">{t('Streak')}</span>
                            <span className="text-2xl font-luckiest-guy text-white">🔥 {clickResponse.currentStreak}</span>
                        </div>
                    </div>
                    <div className="p-2 rounded-lg flex items-center gap-2">
                        <input
                            type="text"
                            value={referralLink}
                            readOnly
                            className="flex-1 bg-white/10 text-white p-2 rounded-md text-sm border border-white/20"
                        />
                        <button
                            onClick={handleCopyReferralLink}
                            className="bg-white/10 hover:bg-white/20 p-3 rounded-md transition-colors duration-200 
                                flex items-center justify-center min-w-[48px] border border-white/20"
                        >
                            <FaCopy className="text-white text-lg" />
                        </button>
                    </div>

                </Modal>
            )}
        </div>
    );
}

export default Home;

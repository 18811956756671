import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MenuOverlay from './MenuOverlay';

const Footer = ({ uncompletedTasksCount }) => {
    const { t } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showBoostTooltip, setShowBoostTooltip] = useState(false);

    const isTelegramMobile = window.Telegram?.WebApp?.platform !== 'tdesktop';

    return (
        <>
            <footer className="footer-menu flex justify-between px-3 py-3 bg-[#c6392d] fixed bottom-0 w-full">
                <NavLink 
                    to="/" 
                    className={({ isActive }) => `flex flex-col items-center text-[6px] ${isActive ? 'text-white' : 'text-white/60'}`}
                >
                    <i className="fas fa-home text-[12px] mb-0.5"></i>
                    <span>{t('Home')}</span>
                </NavLink>
                <NavLink 
                    to="/tasks" 
                    className={({ isActive }) => `flex flex-col items-center text-[6px] relative ${isActive ? 'text-white' : 'text-white/60'}`}
                >
                    <i className="fas fa-tasks text-[12px] mb-0.5"></i>
                    <span>{t('Tasks')}</span>
                    {uncompletedTasksCount > 0 && (
                        <div className="absolute -right-1 bg-[#8b0000] text-white rounded-full w-[1.10rem] h-[1.10rem] flex items-center justify-center text-[12px] font-luckiest-guy task-count-bounce"
                            style={{
                                top: isTelegramMobile ? '-.35rem' : '-.35rem',
                                paddingTop: isTelegramMobile ? '0.15rem' : '0'
                            }}
                        >
                            {uncompletedTasksCount}
                        </div>
                    )}
                </NavLink>
                <NavLink 
                    to="/wheel" 
                    className={({ isActive }) => `flex flex-col items-center text-[6px] ${isActive ? 'text-white' : 'text-white/60'}`}
                >
                    {({ isActive }) => (
                        <>
                            <img 
                                src={isActive ? "/wheel-dark.png" : "/wheel-light.png"} 
                                alt="wheel" 
                                className="w-[25px] h-[25px] mb-0.5" 
                            />
                            <span className='pt-1'>{t('Spin')}</span>
                        </>
                    )}
                </NavLink>
                <NavLink 
                    to="/slots" 
                    className={({ isActive }) => `flex flex-col items-center text-[6px] ${isActive ? 'text-white' : 'text-white/60'}`}
                >
                    <i className="fas fa-coins text-[12px] mb-0.5"></i>
                    <span>{t('Betting')}</span>
                </NavLink>
                <NavLink 
                    to="/referral" 
                    className={({ isActive }) => `flex flex-col items-center text-[6px] ${isActive ? 'text-white' : 'text-white/60'}`}
                >
                    <i className="fas fa-user-friends text-[12px] mb-0.5"></i>
                    <span>{t('Referral')}</span>
                </NavLink>
                {/* Replace wallet with menu button */}
                <button 
                    onClick={() => setIsMenuOpen(true)}
                    className={`flex flex-col items-center text-[6px] text-white/60 hover:text-white`}
                    style={{ padding: '0', border: 'none', background: 'none' }} // Reset button styles
                >
                    <i className="fas fa-bars text-[12px] mb-0.5"></i>
                    <span className="">{t('Menu')}</span>
                </button>
            </footer>

            <MenuOverlay 
                isOpen={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
                showBoostTooltip={showBoostTooltip}
                setShowBoostTooltip={setShowBoostTooltip}
            />
        </>
    );
};

export default Footer;

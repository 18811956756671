import React, { useEffect, useRef } from 'react';
import ReactConfetti from 'react-confetti';

const CelebrationEffect = () => {
    const containerRef = useRef(null);
    const emojis = ['🎉', '🔥', '💰', '👨‍🍳', '🍕'];
    
    useEffect(() => {
        const container = containerRef.current;
        const elements = [];
        
        // Create floating emojis with more dynamic animations
        emojis.forEach((emoji) => {
            for (let i = 0; i < 4; i++) { // Increased to 4 of each emoji
                const span = document.createElement('span');
                span.textContent = emoji;
                span.className = 'modal-floating-emoji';
                const scale = 1 + Math.random() * 0.5; // Random size variation
                span.style.cssText = `
                    --delay: ${Math.random() * 0.8}s;
                    --x-start: ${Math.random() * 100}%;
                    --rotation: ${Math.random() * 720 - 360}deg;
                    --scale: ${scale};
                    animation-delay: var(--delay);
                    left: var(--x-start);
                    font-size: ${3 * scale}rem;
                    filter: drop-shadow(0 0 10px rgba(255,255,255,0.5));
                `;
                elements.push(span);
                container.appendChild(span);
            }
        });

        return () => elements.forEach(element => element.remove());
    }, []);

    return (
        <>
            <ReactConfetti
                recycle={false}
                numberOfPieces={200}
                tweenDuration={2500}
                gravity={0.15}
                colors={['#FFD700', '#FF6B6B', '#4CAF50', '#FF9800', '#ffffff']}
                confettiSource={{ x: 0, y: 0, w: window.innerWidth, h: 0 }}
            />
            <div ref={containerRef} className="modal-celebration-container" />
        </>
    );
};

export default CelebrationEffect;
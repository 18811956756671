import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const TermsAndConditions = () => {
    const navigate = useNavigate();
    const [showScrollIndicator, setShowScrollIndicator] = useState(true);

    const handleScroll = (e) => {
        const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 1;
        setShowScrollIndicator(!bottom);
    };

    return (
        <div className="h-screen relative">
            <div className="h-full overflow-y-auto relative" onScroll={handleScroll}>
                <div className="container mx-auto px-4 py-8">
                    {showScrollIndicator && (
                        <div className="fixed bottom-20 right-6 z-30 bg-white/10 backdrop-blur-sm p-3 rounded-full shadow-lg border border-white/10 animate-bounce cursor-pointer">
                            <svg 
                                className="w-6 h-6 text-gray-400" 
                                fill="none" 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth="2" 
                                viewBox="0 0 24 24" 
                                stroke="currentColor"
                            >
                                
                                <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                            </svg>
                        </div>
                    )}

                    <div className="max-w-3xl mx-auto p-8 rounded-lg shadow-md" 
                         style={{ background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.0), rgba(245, 247, 250, 0.4))' }}>
                        <h1 className="text-2xl font-luckiest-guy text-center text-white mb-8" style={{ fontSize: '1.5rem' }}>
                            Terms and Conditions
                        </h1>
                        
                        <div className="space-y-8">
                            <p className="text-white" style={{ fontFamily: 'Palmer Lake', fontSize: '1.5rem', lineHeight: '1.2' }}>
                                The $PepeRuney token is not affiliated, associated, or endorsed by Matt Furie or his creation, PEPE. This token exists solely to pay homage to a widely recognized meme and is designed purely for entertainment purposes.
                            </p>

                            <p className="text-white" style={{ fontFamily: 'Palmer Lake', fontSize: '1.5rem', lineHeight: '1.2' }}>
                                $PepeRuney is a meme coin that holds no intrinsic value, utility, or expectation of financial return. It is not an investment and provides no guarantees of profit or benefit. There is no formal development team or roadmap associated with this token.
                            </p>

                            <p className="text-white" style={{ fontFamily: 'Palmer Lake', fontSize: '1.5rem', lineHeight: '1.2' }}>
                                Applications and activities accessed through the $PepeRuney ecosystem & partners, including gambling, are community-developed and not formally endorsed or managed by the creators of $PepeRuney. Participants must ensure compliance with their local laws. Gambling is prohibited in countries where it is illegal, including but not limited to United States, China, India, Singapore, United Arab Emirates, Turkey, South Korea, Australia, and Saudi Arabia. It is the sole responsibility of users to verify and adhere to the legal requirements in their jurisdictions.
                            </p>

                            <p className="text-white" style={{ fontFamily: 'Palmer Lake', fontSize: '1.5rem', lineHeight: '1.2' }}>
                                $PepeRuney is provided "as is," without warranties of any kind, express or implied. The creators disclaim all liability for any use or actions associated with $PepeRuney, including any financial loss, legal consequences, or other damages.
                            </p>
                        </div>

                        <button
                            onClick={() => navigate(-1)}
                            className="mt-8 w-3/4 mx-auto block bg-[#2f7821] hover:bg-[#246119] 
                                     text-white font-luckiest-guy py-3 px-6 rounded-full 
                                     transition-all duration-300 transform hover:scale-[1.02] 
                                     active:scale-[0.98]"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Cookies from 'js-cookie';
import LoadingSpinner from './LoadingSpinner';
import { toast } from 'react-toastify'; // Import toast for notifications
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import Footer from './Footer';
import { useTranslation } from 'react-i18next'; // Add this import
import VerificationSection from './VerificationSection';


const API_URL = process.env.REACT_APP_API_URL; // Accessing the API URL from the environment variable

function Task({ uncompletedTasksCount, updateTaskCount }) {
    const { t } = useTranslation();
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [processingTaskId, setProcessingTaskId] = useState(null);
    const [nameStatus, setNameStatus] = useState('unverified');
    const [waitingTimes, setWaitingTimes] = useState({});
    const [membershipStatus, setMembershipStatus] = useState({
        isGroupMember: false,
        isChannelMember: false
    });

    useEffect(() => {
        const authToken = Cookies.get('authToken');
        const initDataString = window.Telegram?.WebApp?.initData;

        if (!authToken) {
            setError(t('No auth token found'));
            setLoading(false);
            return;
        }

        fetch(`${API_URL}/api/tasks`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            retryCount: 3,
            retryDelay: 1000,
            retryStatusCodes: [408, 500, 502, 503, 504]

        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setTasks(data);
                setLoading(false);
                const uncompletedCount = data.filter(task => !task.viewed).length;
                updateTaskCount(uncompletedCount);
            })
            .catch((error) => {
                console.error('Error:', error);
                setError(t('Failed to load tasks. Please try again.'));
                setLoading(false);
            });

        fetch(`${API_URL}/api/user/name-status`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            retryCount: 3,
            retryDelay: 1000,
            retryStatusCodes: [408, 500, 502, 503, 504]
        })
            .then(response => response.json())
            .then(data => {
                setNameStatus(data.status);
            })
            .catch(error => {
                console.error('Error checking name status:', error);
            });

        if (authToken && initDataString) {
            fetch(`${API_URL}/api/user/membership-status`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                retryCount: 3,
                retryDelay: 1000,
                retryStatusCodes: [408, 500, 502, 503, 504]
            })
                .then(response => response.json())
                .then(data => {
                    setMembershipStatus({
                        isGroupMember: data.isGroupMember === "true",
                        isChannelMember: data.isChannelMember === "true"
                    });
                })
                .catch(error => {
                    console.error('Error checking membership status:', error);
                });
        }
        console.log('membershipStatus', membershipStatus);
        // Initialize Telegram WebApp SDK and configure the back buttons
        const tg = window.Telegram?.WebApp;
        if (tg) {
            tg.BackButton.show(); // Show the back button
            tg.BackButton.onClick(() => {
                console.log("Back button clicked!");
                window.history.back(); // Go back when the button is clicked
            });

            // Cleanup when the component unmounts
            return () => {
                tg.BackButton.hide();
                tg.BackButton.offClick(); // Remove the event listener
            };
        }
    }, [t, updateTaskCount]);

    const handleViewClick = (taskId, taskUrl) => {
        const authToken = Cookies.get('authToken');

        if (!authToken) {
            console.error('No auth token found');
            return;
        }

        setProcessingTaskId(taskId);

        // Ensure the URL is properly formatted for external links
        const formattedUrl = taskUrl.startsWith('http') ? taskUrl : `https://${taskUrl}`;
        window.open(formattedUrl, '_blank', 'noopener,noreferrer');

        fetch(`${API_URL}/api/tasks/view`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            body: JSON.stringify({ task_id: taskId }),
            retryCount: 3,
            retryDelay: 1000,
            retryStatusCodes: [408, 500, 502, 503, 504]
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                toast.success('Task viewed successfully');
                // Update the task with current timestamp
                const currentTime = new Date().toISOString();
                setTasks(tasks.map(task =>
                    task.task_id === taskId
                        ? { ...task, viewed: true, viewed_at: currentTime }
                        : task
                ));
            })
            .catch((error) => {
                toast.error('Error viewing task');
                console.error('Error:', error);
            })
            .finally(() => {
                setProcessingTaskId(null);
            });
    };

    const handleClaimClick = (taskId) => {
        const authToken = Cookies.get('authToken');

        if (!authToken) {
            console.error('No auth token found');
            return;
        }

        setProcessingTaskId(taskId);
        // Call the claim API
        fetch(`${API_URL}/api/tasks/claim`, { // Use API_URL for claim API
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            body: JSON.stringify({ task_id: taskId }),
            retryCount: 3,
            retryDelay: 1000,
            retryStatusCodes: [408, 500, 502, 503, 504]
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                toast.success('Points claimed successfully');
                setTasks(tasks.map(task => task.task_id === taskId ? { ...task, claimed: true } : task));
            })
            .catch((error) => {
                toast.error('Error claiming task');
                console.error('Error:', error);
            })
            .finally(() => {
                setProcessingTaskId(null);
            });
    };

    const handleVerification = () => {
        const initDataString = window.Telegram.WebApp.initData;
        const authToken = Cookies.get('authToken');

        if (!authToken) {
            toast.error(t('No auth token found'));
            return;
        }

        fetch(`${API_URL}/api/user/verify-name`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            body: JSON.stringify({ initData: initDataString }),
            retryCount: 3,
            retryDelay: 1000,
            retryStatusCodes: [408, 500, 502, 503, 504]
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    toast.success(data.message || t('Verification successful'));
                    // Update the status to trigger re-render
                    setNameStatus('verified');
                } else {
                    toast.error(data.message || t('Verification failed'));
                }
            })
            .catch(error => {
                console.error('Error verifying name:', error);
                toast.error(t('Error during verification'));
            });
    };

    const renderVerifyButton = () => {
        switch (nameStatus) {
            case 'verified':
                return (
                    <button
                        disabled
                        className="bg-green-500 text-white rounded-lg px-4 py-2 text-sm opacity-75 flex items-center gap-2"
                    >
                        {t('Verified')} <i className="fas fa-check"></i>
                    </button>
                );
            case 'unverified':
                return (
                    <button
                        onClick={() => handleVerification()}
                        className="bg-black text-white rounded-lg px-4 py-2 text-sm hover:bg-gray-800 transition-colors"
                    >
                        {t('Verify')}
                    </button>
                );
            default:
                return (
                    <button
                        disabled
                        className="bg-red-500 text-white rounded-lg px-4 py-2 text-sm opacity-75"
                    >
                        Rekt
                    </button>
                );
        }
    };

    const sortTasks = (tasks) => {
        return tasks.sort((a, b) => {
            // First priority: Viewed but not claimed (claimable)
            if (a.viewed && !a.claimed && !(b.viewed && !b.claimed)) return -1;
            if (b.viewed && !b.claimed && !(a.viewed && !a.claimed)) return 1;

            // Second priority: Not viewed (incomplete)
            if (!a.viewed && b.viewed) return -1;
            if (!b.viewed && a.viewed) return 1;

            // Last: Completed tasks
            if (a.claimed && !b.claimed) return 1;
            if (b.claimed && !a.claimed) return -1;

            return 0;
        });
    };

    const canClaim = (viewedAt) => {
        if (!viewedAt) return false;
        const viewedTime = new Date(viewedAt);
        const currentTime = new Date();
        const timeDiff = currentTime - viewedTime;
        const minutesWaited = timeDiff / (1000 * 60);
        return minutesWaited >= 10;
    };

    const getRemainingTime = (viewedAt) => {
        if (!viewedAt) return "0:00";

        const viewedTime = new Date(viewedAt);
        const targetTime = new Date(viewedTime.getTime() + 10 * 60 * 1000);
        const currentTime = new Date();
        const timeDiff = targetTime - currentTime;

        // Return "0:00" if time difference is negative
        if (timeDiff < 0) return "0:00";

        const minutes = Math.floor(timeDiff / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setWaitingTimes(prev => ({ ...prev })); // Force re-render to update timers
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const handleTelegramVerification = () => {
        const authToken = Cookies.get('authToken');
        const initDataString = window.Telegram?.WebApp?.initData;

        fetch(`${API_URL}/api/user/verify-telegram-membership`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            body: JSON.stringify({ initData: initDataString }),
            retryCount: 3,
            retryDelay: 1000,
            retryStatusCodes: [408, 500, 502, 503, 504]
        })
            .then(response => response.json())
            .then(data => {
                console.log('API Response:', data);
                if (data.isChannelMember) {
                    toast.success(data.message || t('Verification successful'));
                    setMembershipStatus(prev => ({
                        ...prev,
                        isChannelMember: true
                    }));
                } else {
                    toast.error(t('Please join our Telegram first'));
                }
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error(t('Error during verification'));
            });
    };

    const handleTelegramVerificationGroup = () => {
        const authToken = Cookies.get('authToken');
        const initDataString = window.Telegram?.WebApp?.initData;

        fetch(`${API_URL}/api/user/verify-telegram-membership`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            body: JSON.stringify({ initData: initDataString }),
            retryCount: 3,
            retryDelay: 1000,
            retryStatusCodes: [408, 500, 502, 503, 504]
        })
            .then(response => response.json())
            .then(data => {
                console.log('API Response:', data);
                if (data.isGroupMember) {
                    toast.success(data.message || t('Verification successful'));
                    setMembershipStatus(prev => ({
                        ...prev,
                        isGroupMember: true
                    }));
                } else {
                    toast.error(t('Please join our Telegram first'));
                }
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error(t('Error during verification'));
            });
    };

    return (
        <div className="relative h-screen">
            <div className="absolute top-0 left-0 right-0 bottom-[60px] overflow-y-auto">
                <div className="task-container p-4 rounded-lg m-4">
                    {loading ? (
                        <div className="flex justify-center items-center h-64">
                            <LoadingSpinner />
                        </div>
                    ) : error ? (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                            <strong className="font-bold">{t('Error')}:</strong>
                            <span className="block sm:inline"> {error}</span>
                        </div>
                    ) : tasks.length === 0 ? (
                        <div className="text-center text-gray-600">
                            {t('No tasks available at the moment.')}
                        </div>
                    ) : (
                        <ul className="space-y-4">
                            {/* Show at top if either condition is false */}
                            {(!membershipStatus.isChannelMember || !membershipStatus.isGroupMember) && (
                                <VerificationSection
                                    isChannelMember={membershipStatus.isChannelMember}
                                    isGroupMember={membershipStatus.isGroupMember}
                                    onVerifyChannel={handleTelegramVerification}
                                    onVerifyGroup={handleTelegramVerificationGroup}
                                    t={t}
                                />
                            )}
                            {(nameStatus === 'unverified' || nameStatus === 'rekt') && (
                                <li className="task-item p-6 rounded-lg bg-white shadow-md border border-gray-200">
                                    <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
                                        <div className="space-y-2">
                                            <p className="text-gray-800 font-semibold text-lg">{t('pepslice')}</p>
                                            <div className="flex items-center gap-3">
                                                <button
                                                    onClick={() => navigator.clipboard.writeText('🐸🍕')}
                                                    className="bg-gray-100 hover:bg-gray-200 text-sm px-3 py-1.5 rounded-md flex items-center gap-2"
                                                >
                                                    <span className="text-base">🐸🍕</span>
                                                    <i className="fas fa-copy"></i>
                                                </button>
                                                <span className="text-green-600 font-semibold">
                                                💰5000
                                                </span>
                                                <img
                                                    src="spin-coin.gif"
                                                    alt="Coin"
                                                    className="w-6 h-6 ml-1"
                                                />
                                            </div>
                                        </div>
                                        {renderVerifyButton()}
                                    </div>
                                    <div className="mt-3 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
                                        <p className="text-sm">{t('peplost')}</p>
                                    </div>
                                </li>
                            )}

                            {/* Existing sorted tasks */}
                            {sortTasks([...tasks]).map(task => (
                                <li key={task.task_id}
                                    className="task-item rounded-lg bg-white shadow-md border border-gray-200 hover:shadow-lg transition-shadow relative"
                                    style={{ padding: '0.60rem', paddingLeft: '0.70rem' }}
                                >
                                    <div className="flex flex-col space-y-2">
                                        <p className="text-gray-800 font-semibold text-sm pr-24">
                                            {task.description}
                                        </p>
                                        <div className="flex items-center">
                                            <span className="text-green-600 font-semibold" style={{ fontSize: '1rem', lineHeight: '1.05rem' }}>
                                                +💰{task.points}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="absolute right-3 top-1/2 -translate-y-1/2">
                                        {!task.viewed ? (
                                            <button
                                                onClick={() => handleViewClick(task.task_id, task.url)}
                                                className={`bg-blue-500 text-white rounded-2xl px-4 py-1.5 text-sm flex items-center gap-2 min-w-[80px] justify-center
                                                    ${processingTaskId === task.task_id ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
                                                disabled={processingTaskId === task.task_id}
                                            >
                                                {processingTaskId === task.task_id ?
                                                    <i className="fas fa-spinner fa-spin" /> :
                                                    t('View')
                                                }
                                            </button>
                                        ) : task.claimed ? (
                                            <button
                                                className="bg-green-500 text-white rounded-2xl px-4 py-1.5 text-sm cursor-not-allowed flex items-center gap-2 min-w-[80px] justify-center"
                                                disabled
                                            >
                                                {t('Done')} <i className="fas fa-check ml-1"></i>
                                            </button>
                                        ) : !canClaim(task.viewed_at) ? (
                                            <button
                                                className="bg-gray-500 text-white rounded-2xl px-4 py-1.5 text-sm cursor-not-allowed flex items-center gap-2 min-w-[80px] justify-center"
                                                disabled
                                            >
                                                {`${t('Wait')} ${getRemainingTime(task.viewed_at)}`}
                                            </button>
                                        ) : (
                                            <button
                                                onClick={() => handleClaimClick(task.task_id)}
                                                className={`bg-black text-white rounded-2xl px-4 py-1.5 text-sm flex items-center gap-2 min-w-[80px] justify-center
                                                    ${processingTaskId === task.task_id ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-800'}`}
                                                disabled={processingTaskId === task.task_id}
                                            >
                                                {processingTaskId === task.task_id ?
                                                    <i className="fas fa-spinner fa-spin" /> :
                                                    t('Claim')
                                                }
                                            </button>
                                        )}
                                    </div>
                                </li>
                            ))}

                            {/* Show at bottom only if both conditions are true */}
                            {membershipStatus.isChannelMember && membershipStatus.isGroupMember && (
                                <VerificationSection
                                    isChannelMember={membershipStatus.isChannelMember}
                                    isGroupMember={membershipStatus.isGroupMember}
                                    onVerifyChannel={handleTelegramVerification}
                                    onVerifyGroup={handleTelegramVerificationGroup}
                                    t={t}
                                />
                            )}

                            {(nameStatus === 'verified') && (
                                <li className="task-item p-6 rounded-lg bg-white shadow-md border border-gray-200">
                                    <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
                                        <div className="space-y-2">
                                            <p className="text-gray-800 font-medium text-lg">{t('pepslice')}</p>
                                            <div className="flex items-center gap-3">
                                                <button
                                                    onClick={() => navigator.clipboard.writeText('🐸🍕')}
                                                    className="bg-gray-100 hover:bg-gray-200 text-sm px-3 py-1.5 rounded-md flex items-center gap-2"
                                                >
                                                    <span className="text-base">🐸🍕</span>
                                                    <i className="fas fa-copy"></i>
                                                </button>
                                                <span className="text-green-600 font-semibold">
                                                💰5000
                                                </span>
                                                <img
                                                    src="spin-coin.gif"
                                                    alt="Coin"
                                                    className="w-6 h-6 ml-1"
                                                />
                                            </div>
                                        </div>
                                        {renderVerifyButton()}
                                    </div>
                                    <div className="mt-3 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
                                        <p className="text-sm">{t('peplost')}</p>
                                    </div>
                                </li>
                            )}
                        </ul>
                    )}
                </div>
            </div>
            <div className="absolute bottom-0 left-0 right-0">
                <Footer uncompletedTasksCount={uncompletedTasksCount} />
            </div>
        </div>
    );
}

export default Task;

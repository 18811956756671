import React, { useState, useEffect } from 'react';
import CelebrationEffect from './CelebrationEffect';

const Modal = ({ children, onClose }) => {
    const [showCelebration, setShowCelebration] = useState(true);
    const isTelegramMobile = window.Telegram?.WebApp?.platform !== 'tdesktop';
    
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowCelebration(false);
        }, 2500); // Duration of celebration effect
        
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="fixed inset-0 bg-black/80 flex items-center justify-center p-4 z-[60]">
            {showCelebration && <CelebrationEffect />}
            <div 
                className={`modal-content backdrop-blur-sm bg-black/40 rounded-xl p-6 max-w-sm w-full border border-white/20 
                    ${isTelegramMobile ? 'mb-24' : 'mb-4'}`}
            >
                <div className="text-white">
                    {children}
                    <div className="flex justify-center">
                        <button
                            onClick={onClose}
                            className="mt-8 px-8 py-2 
                                bg-white/10 backdrop-blur-sm
                                text-white font-luckiest-guy
                                rounded-full 
                                hover:bg-white/20 
                                transition-all duration-300
                                flex items-center gap-2
                                border border-white/20"
                        >
                            <span>CLOSE</span>
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;

import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import Footer from './Footer'; // Importing the Footer component

const Twallet = () => {
    const { t } = useTranslation();
    return (
        <div className="h-full overflow-y-auto touch-pan-y">
            <div className="flex-grow flex items-center justify-center p-4">
                <div className="betting-container p-8 rounded-lg shadow-md max-w-md w-full" style={{ background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.6), rgba(245, 247, 250, 0.6))' }}>
                    <div className="flex justify-center mb-6">
                        <img src={"walletDesktop.jpg"} className="w-60 h-60 rounded-full object-cover" alt="Betting Hub" />
                    </div>
                    <div className="mt-6 bg-white/80 backdrop-blur-sm border-l-4 border-blue-500 rounded-lg shadow-lg p-6 transition-all hover:shadow-xl" role="alert">
                        <p className="text-lg font-medium text-gray-800 leading-relaxed">
                            {t('WalletInfo')}
                        </p>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Twallet;

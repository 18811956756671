import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaXTwitter, FaTelegram, FaYoutube, FaReddit, FaTiktok, FaInstagram } from 'react-icons/fa6';
import Footer from './Footer';
import Cookies from 'js-cookie';

const API_URL = process.env.REACT_APP_API_URL;

const SocialFi = () => {
    const [activeTab, setActiveTab] = useState('posts');
    const [submitted, setSubmitted] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        platform: '',
        content_type: '',
        url: ''
    });
    const [isOpen, setIsOpen] = useState(false);
    const [isContentTypeOpen, setIsContentTypeOpen] = useState(false);
    const [submissions, setSubmissions] = useState([]);
    const [submissionsLoading, setSubmissionsLoading] = useState(false);

    const platformIcons = {
        youtube: <FaYoutube className="text-2xl" style={{ color: '#FF0000' }} />,
        telegram: <FaTelegram className="text-2xl" />,
        x: <FaXTwitter className="text-2xl" style={{ color: '#000000' }} />,
        reddit: <FaReddit className="text-2xl" style={{ color: '#FF4500' }} />,
        tiktok: <FaTiktok className="text-2xl" style={{ color: '#000000' }} />,
        instagram: <FaInstagram className="text-2xl" style={{ color: '#E4405F' }} />
    };

    const POINTS_MAP = {
        'post': 250,
        'meme': 1000,
        'art': 1500,
        'video': 3000
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const authToken = Cookies.get('authToken');

        try {
            const response = await fetch(`${API_URL}/api/user/socialfi/submit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();

            if (response.ok) {
                toast.success('Submission successful!', {
                    className: 'font-luckiest-guy',
                    position: "top-center",
                    autoClose: 3000
                });
                setSubmitted(true);
            } else {
                let errorMessage = 'Submission failed';
                
                switch (response.status) {
                    case 400:
                        errorMessage = data.error || 'Invalid submission data';
                        break;
                    case 409:
                        errorMessage = 'This content has already been submitted';
                        break;
                    case 429:
                        errorMessage = 'Daily submission limit reached';
                        break;
                    default:
                        errorMessage = data.error || 'Something went wrong';
                }
                
                toast.error(errorMessage, {
                    className: 'font-luckiest-guy',
                    position: "top-center",
                    autoClose: 5000
                });
            }
        } catch (error) {
            toast.error('Network error - Please try again', {
                className: 'font-luckiest-guy',
                position: "top-center",
                autoClose: 5000
            });
        }
    };

    const handleReset = () => {
        setSubmitted(false);
        setFormData({
            platform: '',
            content_type: '',
            url: ''
        });
    };

    const fetchSubmissions = async () => {
        const authToken = Cookies.get('authToken');
        setSubmissionsLoading(true);
        
        try {
            const response = await fetch(`${API_URL}/api/user/socialfi/submissions`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                }
            });
            
            if (!response.ok) throw new Error('Failed to fetch submissions');
            
            const data = await response.json();
            setSubmissions(data);
        } catch (error) {
            toast.error('Failed to load submissions', {
                className: 'font-luckiest-guy',
                position: "top-center"
            });
        } finally {
            setSubmissionsLoading(false);
        }
    };

    useEffect(() => {
        if (activeTab === 'submissions') {
            fetchSubmissions();
        }
    }, [activeTab]);

    useEffect(() => {
        if (activeTab === 'posts') {
            setSubmitted(false);
        }
    }, [activeTab]);

    const renderForm = () => (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div>
                <label className="block font-luckiest-guy text-white mb-2">PLATFORM</label>
                <div className="relative">
                    <button
                        type="button"
                        onClick={() => setIsOpen(!isOpen)}
                        className="w-full p-3 rounded-lg bg-white/10 backdrop-blur-sm text-white font-luckiest-guy border border-white/20 flex items-center justify-between"
                    >
                        {formData.platform ? (
                            <div className="flex items-center">
                                {platformIcons[formData.platform]}
                                <span className="ml-2">{formData.platform.toUpperCase()}</span>
                            </div>
                        ) : (
                            <span className="font-luckiest-guy text-white">Select Platform</span>
                        )}
                    </button>
                    
                    {isOpen && (
                        <div className="absolute z-50 w-full mt-1 bg-white rounded-lg shadow-lg">
                            {[
                                { value: 'youtube', label: 'YOUTUBE', icon: platformIcons.youtube },
                                { value: 'tiktok', label: 'TIKTOK', icon: platformIcons.tiktok },
                                { value: 'x', label: 'X (TWITTER)', icon: platformIcons.x },
                                { value: 'reddit', label: 'REDDIT', icon: platformIcons.reddit },
                                { value: 'instagram', label: 'INSTAGRAM', icon: platformIcons.instagram }
                            ].map((option) => (
                                <button
                                    key={option.value}
                                    type="button"
                                    onClick={() => {
                                        setFormData({...formData, platform: option.value});
                                        setIsOpen(false);
                                    }}
                                    className="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center"
                                >
                                    {option.icon}
                                    <span className="ml-2 text-black font-luckiest-guy">{option.label}</span>
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <div>
                <label className="block font-luckiest-guy text-white mb-2">CONTENT TYPE</label>
                <div className="relative">
                    <button
                        type="button"
                        onClick={() => setIsContentTypeOpen(!isContentTypeOpen)}
                        className="w-full p-3 rounded-lg bg-white/10 backdrop-blur-sm text-white font-luckiest-guy border border-white/20 flex items-center justify-between"
                    >
                        {formData.content_type ? (
                            <div className="flex items-center justify-between w-full">
                                <span>
                                    {formData.content_type === 'post' && '📝 POST'}
                                    {formData.content_type === 'meme' && '🤡 MEME'}
                                    {formData.content_type === 'art' && '🎨 ART'}
                                    {formData.content_type === 'video' && '🎥 VIDEO'}
                                </span>
                                <div className="flex items-center">
                                    <img src="/spin-coin.gif" alt="coin" className="w-6 h-6 mx-2" />
                                    <span className="text-[#2f7821]">+${POINTS_MAP[formData.content_type]}</span>
                                </div>
                            </div>
                        ) : (
                            <span className="font-luckiest-guy text-white">Select Content Type</span>
                            
                        )}
                    </button>
                    
                    {isContentTypeOpen && (
                        <div className="absolute z-50 w-full mt-1 bg-white rounded-lg shadow-lg">
                            {Object.entries(POINTS_MAP).map(([type, points]) => (
                                <button
                                    key={type}
                                    type="button"
                                    onClick={() => {
                                        setFormData({...formData, content_type: type});
                                        setIsContentTypeOpen(false);
                                    }}
                                    className="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center justify-between"
                                >
                                    <span className="font-luckiest-guy text-black">
                                        {type === 'post' && '📝 POST'}
                                        {type === 'meme' && '🤡 MEME'}
                                        {type === 'art' && '🎨 ART'}
                                        {type === 'video' && '🎥 VIDEO'}
                                    </span>
                                    <div className="flex items-center">
                                        <img src="/spin-coin.gif" alt="coin" className="w-6 h-6 mx-2" />
                                        <span className="font-luckiest-guy text-[#2f7821]">+${points}</span>
                                    </div>
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <div>
                <label className="block font-luckiest-guy text-white mb-2">URL</label>
                <div className="relative">
                    {formData.platform && (
                        <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white">
                            {platformIcons[formData.platform]}
                        </div>
                    )}
                    <input
                        type="url"
                        value={formData.url}
                        onChange={(e) => setFormData({...formData, url: e.target.value})}
                        placeholder="Enter content URL"
                        className={`w-full p-3 ${formData.platform ? 'pl-12' : 'pl-4'} rounded-lg bg-white/10 backdrop-blur-sm text-white font-luckiest-guy border border-white/20 white-placeholder`}
                        required
                    />
                </div>
            </div>

            <button
                type="submit"
                className="w-full bg-[#2f7821] hover:bg-[#246119] text-white font-luckiest-guy py-3 px-4 rounded-full transition-all duration-300"
            >
                SUBMIT
            </button>
        </form>
    );

    const renderSuccess = () => (
        <div className="text-center space-y-6">
            <div className="w-32 h-32 mx-auto rounded-full overflow-hidden">
                <img 
                    src="/peperuney-lambo.webp" 
                    alt="Success" 
                    className="w-full h-full object-cover"
                />
            </div>
            <h2 className="font-luckiest-guy text-white text-2xl">
                YOU ARE ON 🔥. KEEP SUBMITTING AND PRINT!
            </h2>
            <button
                onClick={handleReset}
                className="w-full bg-[#2f7821] hover:bg-[#246119] text-white font-luckiest-guy py-3 px-4 rounded-full transition-all duration-300"
            >
                SUBMIT AGAIN
            </button>
        </div>
    );

    return (
        <div className="min-h-screen flex flex-col bg-gray-900">
            {/* Fixed position header with tabs */}
            <div className="fixed top-0 left-0 right-0 bg-gray-900 z-10">
                <div className="flex justify-around items-center pt-8 pb-4">
                    {[
                        { name: ' POSTS ', key: 'posts', emoji: '📝' },
                        { name: 'SUBMISSIONS', key: 'submissions', emoji: '🎨' },
                        { name: ' INFO ', key: 'info', emoji: '📜' }
                    ].map((tab) => (
                        <button
                            key={tab.key}
                            onClick={() => setActiveTab(tab.key)}
                            className={`font-luckiest-guy text-white text-xl relative px-4 py-2
                                ${activeTab === tab.key ? 'after:content-[""] after:absolute after:bottom-0 after:left-0 after:w-full after:h-1 after:bg-[#ed1312]' : ''}`}
                        >
                            <span className="mr-2">{tab.emoji}</span>
                            {tab.name}
                        </button>
                    ))}
                </div>
            </div>

            {/* Scrollable content area with padding for header and footer */}
            <div className="flex-1 overflow-y-auto pt-28 pb-20">
                <div className="p-4">
                    {activeTab === 'posts' && (
                        <div 
                            key="posts-section"
                            className="backdrop-blur-sm rounded-lg p-4 animate-fadeIn h-[calc(100vh-12rem)] overflow-y-auto"
                            style={{
                                opacity: activeTab === 'posts' ? 1 : 0,
                                display: activeTab === 'posts' ? 'block' : 'none',
                                transition: 'opacity 0.3s ease-in-out',
                                willChange: 'opacity',
                                scrollbarWidth: 'thin',
                                scrollbarColor: 'rgba(255, 255, 255, 0.3) transparent'
                            }}
                        >
                            <div className="space-y-4">
                                {!submitted && (
                                    <h2 className="font-luckiest-guy text-2xl mb-4 whitespace-normal p-2 rounded-lg flex items-center flex-wrap gap-1">
                                        <span className="fire-text">EARN FOR SOCIAL POSTS</span> 📱, 
                                        <span className="fire-text">MEMES</span> 🤡, 
                                        <span className="fire-text">ART</span> 🎨, 
                                        <span className="fire-text">AND VIDEOS</span> 🎥 
                                        <span className="fire-text">.WE</span> ❤️ 
                                        <span className="fire-text">OUR COMMUNITY!</span>
                                    </h2>
                                )}
                                {submitted ? renderSuccess() : renderForm()}
                            </div>
                        </div>
                    )}

                    {activeTab === 'submissions' && (
                        <div 
                            key="submissions-section"
                            className="bg-[#fafafa]/30 backdrop-blur-sm rounded-lg p-4 animate-fadeIn"
                        >
                            <h2 className="font-luckiest-guy text-white text-2xl mb-4">
                                SUBMISSIONS
                            </h2>
                            <div className="overflow-x-auto">
                                <table className="w-full text-white">
                                    <thead>
                                        <tr className="border-b border-white/20">
                                            <th className="py-1 px-2 text-center font-luckiest-guy text-sm w-16">#</th>
                                            <th className="py-1 px-2 text-center font-luckiest-guy w-20">
                                                <span className="text-xl">📱</span>
                                            </th>
                                            <th className="py-1 px-2 text-center font-luckiest-guy w-20">
                                                <span className="text-xl">📝</span>
                                            </th>
                                            <th className="py-1 px-4 text-center font-luckiest-guy text-sm">STATUS</th>
                                            <th className="py-1 px-4 text-center font-luckiest-guy text-sm">DATE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {submissions.map((sub) => (
                                            <tr key={sub.submission_id} className="border-b border-white/10">
                                                <td className="py-2 px-2 font-luckiest-guy">
                                                    {sub.submission_id.substring(0, 5)}
                                                </td>
                                                <td className="py-2 px-2 font-luckiest-guy">
                                                    {platformIcons[sub.platform]}
                                                </td>
                                                <td className="py-2 px-2 font-luckiest-guy">
                                                    {sub.content_type === 'post' && '📝'}
                                                    {sub.content_type === 'meme' && '🤡'}
                                                    {sub.content_type === 'art' && '🎨'}
                                                    {sub.content_type === 'video' && '🎥'}
                                                </td>
                                                <td className="py-2 px-4 font-luckiest-guy">
                                                    <span className={`px-2 py-1 rounded-full text-[0.7em] ${
                                                        sub.status === 'approved' ? 'bg-green-500' :
                                                        sub.status === 'rejected' ? 'bg-red-500' :
                                                        'bg-yellow-500'
                                                    }`}>
                                                        {sub.status.toUpperCase()}
                                                    </span>
                                                </td>
                                                <td className="py-2 px-4 font-luckiest-guy">
                                                    {new Date(sub.created_at).toLocaleDateString('en-GB', {
                                                        day: '2-digit',
                                                        month: '2-digit'
                                                    })}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}

                    {activeTab === 'info' && (
                        <div
                            key="info-section"
                            className="bg-[#fafafa]/30 backdrop-blur-sm rounded-lg p-4 animate-fadeIn h-[calc(100vh-12rem)] overflow-y-auto"
                            style={{
                                scrollbarWidth: 'thin',
                                scrollbarColor: 'rgba(255, 255, 255, 0.3) transparent'
                            }}
                        >
                            <div className="grid grid-cols-1 gap-6">
                                {/* First Card */}
                                <div className="bg-white/10  p-6 rounded-xl shadow-lg border border-white/10">
                                    <h2 className="text-2xl font-luckiest-guy mb-4 text-white">📋 GUIDELINES</h2>
                                    <p className="text-gray-200" style={{ fontFamily: 'Palmer Lake', fontSize: '27px', lineHeight: '0.8' }}>
                                    🔥CONTENT IS APPROVED ON A ROLLING BASIS BY AMBASSADORS. GREAT CONTENT WILL BE REPOSTED TO OUR CHANNEL AND WE MIGHT JUST REACHOUT! TAKE YOUR TIME TO READ THROUGH OUR CONTENT TO LEARN MORE ABOUT $PEPERUNEY AND OUR COMMUNITY. PLEASE SEE OUR <a href="https://drive.google.com/drive/folders/1TiDZHSUuF2JHyu9x5AILKhVCZRSKMVJX?usp=drive_link" target="_blank" rel="noopener noreferrer" className="text-[#ed1312] hover:text-[#ff1312] underline">HEADS AND CONTENT GUIDELINES IN OUR DRIVE FOR ART, VIDEOS AND MEMES</a>.
</p>
                                </div>

                                {/* Second Card */}
                                <div className="bg-white/10 p-6 rounded-xl shadow-lg border border-white/10">
                                    <h2 className="text-2xl font-luckiest-guy mb-4 text-white">📝 POSTS</h2>
                                    <p className="text-gray-200" style={{ fontFamily: 'Palmer Lake', fontSize: '27px', lineHeight: '0.8' }}>
                                        SPREAD THE WORD ABOUT PEPERUNEY CULT. SHARE OUR APP WITH YOUR FRIENDS. SHARE OUR CONTENT AND MAKE LIT POSTS ABOUT OUR COMMUNITY. RETWEET TO YOUR AUDIENCE. SCHILL US.  
                                    </p>
                                </div>

                                {/* Third Card */}
                                <div className="bg-white/10 p-6 rounded-xl shadow-lg border border-white/10">
                                    <h2 className="text-2xl font-luckiest-guy mb-4 text-white">🤡 MEMES</h2>
                                    <p className="text-gray-200" style={{ fontFamily: 'Palmer Lake', fontSize: '27px', lineHeight: '0.8' }}>
                                        LIT MEMES ARE WELCOME. KILL IT SQUAD. USE A MEME MAKER AND MEME THE FUCK OUT OF PEPERUNEY. GRAB OUR CHARACTER HEADS TO MAKE YOUR LIFE EASIER.
                                    </p>
                                </div>

                                {/* Third Card */}
                                <div className="bg-white/10 p-6 rounded-xl shadow-lg border border-white/10">
                                    <h2 className="text-2xl font-luckiest-guy mb-4 text-white">🎨 ARTISTS</h2>
                                    <p className="text-gray-200" style={{ fontFamily: 'Palmer Lake', fontSize: '27px', lineHeight: '0.8' }}>
                                        WE LOVE OUR COMMUNITY ARTISTS AND POST ABOUT THEM EVERY WEEK. IF YOU MAKE GREAT ART, WE WILL POST YOU ON OUR MAIN CHANNEL. ITS A GREAT WAY TO JUMP START YOUR OPPORTUNITIES!
                                    </p>
                                </div>

                                {/* Third Card */}
                                <div className="bg-white/10 p-6 rounded-xl shadow-lg border border-white/10">
                                    <h2 className="text-2xl font-luckiest-guy mb-4 text-white">🎥 VIDEOS</h2>
                                    <p className="text-gray-200" style={{ fontFamily: 'Palmer Lake', fontSize: '27px', lineHeight: '0.8' }}>
                                        VIDEOS ARE A GREAT WAY TO SHOWCASE YOUR CREATIVITY. WE POST THE BEST VIDEOS ON OUR MAIN CHANNEL. 
                                    </p>
                                </div>


                                {/* Reminder Card */}
                                <div className="bg-[#f9f7b3] backdrop-blur-sm p-6 rounded-xl shadow-lg border border-white/10">
                                    <p className="text-[#ae8d22] text-xl font-regular text-center" style={{ fontFamily: 'Palmer Lake', fontSize: '27px', lineHeight: '.8' }}>
IT CAN TAKE SOME TIME TO GET APPROVED (7-10 DAYS). YOUR CONTENT IS YOUR CONTENT AND WE WILL MAKE SURE TO PROMOTE IT AS MUCH AS WE CAN! THIS INCLUDES ALL ATTRIBUTIONS!                                   </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Fixed position footer */}
            <div className="fixed bottom-0 left-0 right-0">
                <Footer />
            </div>
        </div>
    );
};

export default SocialFi;
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from './Footer';
import Cookies from 'js-cookie';
import ReactConfetti from 'react-confetti';
import useWindowSize from '../hooks/useWindowSize';

const API_URL = process.env.REACT_APP_API_URL;

const Slots = ({ uncompletedTasksCount }) => {
    const { t } = useTranslation();
    const [isSpinning, setIsSpinning] = useState(false);
    const [slotsStatus, setSlotsStatus] = useState({
        current_points: 0,
        spins_remaining: 100,
        spins_used: 0,
        can_spin: true
    });
    const [loading, setLoading] = useState(true);
    const [spinResult, setSpinResult] = useState(null);
    const [error, setError] = useState(null);
    const { width, height } = useWindowSize();
    const [showWinAnimation, setShowWinAnimation] = useState(false);
    const [showLossAnimation, setShowLossAnimation] = useState(false);
    const [payoutAmount, setPayoutAmount] = useState(0);
    const [showPayTable, setShowPayTable] = useState(false);

    useEffect(() => {
        const fetchSlotsStatus = async () => {
            const authToken = Cookies.get('authToken');

            if (!authToken) {
                console.error('No auth token found');
                setLoading(false);
                return;
            }

            try {
                const response = await fetch(`${API_URL}/api/user/slots/status`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`,
                    },
                    retryCount: 3,
                    retryDelay: 1000,
                    retryStatusCodes: [408, 500, 502, 503, 504]
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setSlotsStatus(data);
                setLoading(false);
            } catch (error) {
                console.error('Error:', error);
                setLoading(false);
            }
        };

        fetchSlotsStatus();
    }, []);

    useEffect(() => {
        console.log('Slots Status Updated:', slotsStatus?.current_points);
    }, [slotsStatus]);

    // Define slot items for each reel
 
    const slotItems = {
        reel1: ['/slots1.png', '/slots2.png', '/slots3.png', '/slots4.png', '/slots5.png', '/slots6.png', '/slots7.png'],
        reel2: ['/slots2.png', '/slots4.png', '/slots1.png', '/slots7.png', '/slots6.png', '/slots3.png', '/slots5.png'],
        reel3: ['/slots6.png', '/slots3.png', '/slots7.png', '/slots4.png', '/slots2.png', '/slots1.png', '/slots5.png']
    };
    

    const handleSpin = async () => {
        if (isSpinning) return;
        
        // Check if user has enough points
        if (slotsStatus.current_points < 50) {
            setError(`Insufficient points. Need 50, you have ${slotsStatus.current_points}`);
            return;
        }

        setIsSpinning(true);
        setError(null);

        try {
            const authToken = Cookies.get('authToken');
            const response = await fetch(`${API_URL}/api/user/slots/spin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                }
            });

            const data = await response.json();

            if (!response.ok) {
                if (response.status === 400) {
                    setError(data.error);
                } else if (response.status === 401) {
                    setError('Please log in again');
                } else {
                    setError('An error occurred. Please try again.');
                }
                setIsSpinning(false);
                return;
            }

            setSpinResult(data);
            animateSlots(data.outcome_name, data);

            // Update points and spins info after animation completes
            setTimeout(() => {
                setSlotsStatus(prev => ({
                    ...prev,
                    current_points: data.new_balance,
                    spins_remaining: data.spins_remaining,
                    spins_used: data.spins_used
                }));
            }, 9000);

        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred. Please try again.');
            setIsSpinning(false);
        }
    };

    // useEffect(() => {
    //     // Initialize Telegram WebApp SDK and configure the back button
    //     const tg = window.Telegram?.WebApp;
    //     if (tg) {
    //         tg.BackButton.show();
    //         tg.BackButton.onClick(() => {
    //             // Only navigate if we're actually on the slots page
    //             if (window.location.pathname === '/slots') {
    //                 window.history.back();
    //             }
    //         });

    //         return () => {
    //             tg.BackButton.hide();
    //             tg.BackButton.offClick();
    //         };
    //     }
    // }, []);

    const getReelImages = (outcome_name) => {
        const outcomes = {
            symbol1_single: ['/slots1.png', getRandomNonSymbol([1,3]), getRandomNonSymbol([1,3])],
            symbol1_double: ['/slots1.png', '/slots1.png', getRandomNonSymbol([1,3])],
            symbol1_triple: ['/slots1.png', '/slots1.png', '/slots2.png'],
            symbol1_triple_mega: ['/slots1.png', '/slots1.png', '/slots1.png'],
            symbol2_double: ['/slots2.png', '/slots2.png', getRandomNonSymbol([1,2,3])],
            symbol2_triple: ['/slots2.png', '/slots2.png', '/slots2.png'],
            symbol3_triple: ['/slots3.png', '/slots3.png', '/slots3.png'],
            symbol3_double: ['/slots3.png', '/slots3.png', getRandomNonSymbol([1,2,3])],
            symbol3_single: ['/slots3.png', getRandomNonSymbol([1,2,3]), getRandomNonSymbol([1,2,3])],
            everything_else: [getRandomNonSymbol([1,2,3]), getRandomNonSymbol([1,2,3]), getRandomNonSymbol([1,2,3])]
        };

        return outcomes[outcome_name] || outcomes.everything_else;
    };

    const getRandomNonSymbol = (excludeNumbers) => {
        const availableSymbols = [4, 5, 6, 7].map(num => `/slots${num}.png`);
        return availableSymbols[Math.floor(Math.random() * availableSymbols.length)];
    };

    const animateSlots = (outcome_name, result) => {
        const finalImages = getReelImages(outcome_name);
        const slots = document.querySelectorAll('.slot-wrapper');
        
        slots.forEach((slot, index) => {
            // Initial fast spin
            setTimeout(() => {
                slot.classList.add('spinning');
            }, index * 250);
            
            // Slow down phase
            setTimeout(() => {
                slot.classList.remove('spinning');
                slot.classList.add('slowing-down');
            }, 2500 + (index * 250));
            
            // Final stopping phase
            setTimeout(() => {
                slot.classList.remove('slowing-down');
                slot.classList.add('stopping');
                
                // Calculate final position based on the outcome image
                const finalImageIndex = slotItems[`reel${index + 1}`]
                    .findIndex(img => img === finalImages[index]);
                const finalPosition = -(finalImageIndex * 86); // Using symbolHeight
                
                slot.style.transform = `translateY(${finalPosition}px)`;
            }, 4500 + (index * 250));
        });
        
        // Reset spinning state earlier
        setTimeout(() => {
            setIsSpinning(false);
            slots.forEach(slot => {
                slot.classList.remove('stopping');
            });
        }, 5500);
        
        // Show animations after a brief delay
        setTimeout(() => {
            if (result) {
                setPayoutAmount(result.net_payout);
                if (result.net_payout > 0) {
                    setShowWinAnimation(true);
                    setTimeout(() => setShowWinAnimation(false), 4000);
                } else {
                    setShowLossAnimation(true);
                    setTimeout(() => setShowLossAnimation(false), 4000);
                }
            }
        }, 6000);
    };

    const WinAnimation = ({ amount }) => {
        const containerRef = useRef(null);
        
        useEffect(() => {
            // Create floating elements
            const container = containerRef.current;
            const elements = [];
            
            // Create 10 coin gifs
            for (let i = 0; i < 10; i++) {
                const coin = document.createElement('img');
                coin.src = '/spin-coin.gif';
                coin.style.cssText = `
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    left: ${Math.random() * 100}%;
                    animation: slotFloatUp 4s ease-out;
                    animation-fill-mode: forwards;
                `;
                elements.push(coin);
            }
            
            // Create emojis
            ['🔥', '💰'].forEach((emoji, i) => {
                const span = document.createElement('span');
                span.textContent = emoji;
                span.style.cssText = `
                    position: absolute;
                    font-size: 24px;
                    left: ${20 + (i * 60)}%;
                    top: 50%;
                    animation: slotFloatUpEmoji 4s ease-out;
                    animation-fill-mode: forwards;
                `;
                elements.push(span);
            });
            
            // Create payout text with corrected animation
            const payout = document.createElement('div');
            payout.textContent = `+${amount}`;
            payout.style.cssText = `
                position: absolute;
                font-family: 'Luckiest Guy', cursive;
                font-size: 48px;
                color: #00ff00;
                left: 50%;
                top: 50%;
                transform: translateX(-50%);
                animation: slotFloatAndFade 4s ease-out;
                animation-fill-mode: forwards;
                white-space: nowrap;
            `;
            elements.push(payout);
            
            elements.forEach(el => container.appendChild(el));
            
            return () => elements.forEach(el => el.remove());
        }, [amount]);
        
        return (
            <div className="relative h-full">
                <ReactConfetti
                    width={346} // Match slot machine width
                    height={346} // Match slot machine height
                    recycle={false}
                    numberOfPieces={200}
                    colors={['#FFD700', '#00ff00', '#ffffff']}
                    confettiSource={{
                        x: 173,
                        y: 173
                    }}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                />
                <div ref={containerRef} className="absolute inset-0 overflow-hidden" />
            </div>
        );
    };

    const LossAnimation = ({ amount }) => {
        const containerRef = useRef(null);
        
        useEffect(() => {
            const container = containerRef.current;
            const elements = [];
            
            // Create skull and warning emojis
            for (let i = 0; i < 8; i++) {
                const emoji = document.createElement('span');
                emoji.textContent = i % 2 === 0 ? '💀' : '⚠️';
                emoji.style.cssText = `
                    position: absolute;
                    font-size: 24px;
                    left: ${Math.random() * 100}%;
                    top: 50%;
                    animation: slotFloatDown 4s ease-out;
                    animation-fill-mode: forwards;
                `;
                elements.push(emoji);
            }
            
            // Create loss text
            const loss = document.createElement('div');
            loss.textContent = `-${amount}`;
            loss.style.cssText = `
                position: absolute;
                font-family: 'Luckiest Guy', cursive;
                font-size: 48px;
                color: #ff0000;
                left: 50%;
                top: 50%;
                transform: translateX(-50%);
                animation: slotFloatAndFade 4s ease-out;
                animation-fill-mode: forwards;
            `;
            elements.push(loss);
            
            elements.forEach(el => container.appendChild(el));
            
            return () => elements.forEach(el => el.remove());
        }, [amount]);
        
        return (
            <div className="relative h-full">
                <div ref={containerRef} className="absolute inset-0 overflow-hidden" />
            </div>
        );
    };

    const PayTable = () => {
        const winningCombinations = [
            { name: "MEGA RUNEY", images: ['/slots1.png', '/slots1.png', '/slots1.png'], payout: 500 },
            { name: "PIZZA RUNEY", images: ['/slots1.png', '/slots1.png', '/slots2.png'], payout: 200 },
            { name: "DOUBLE RUNEY", images: ['/slots1.png', '/slots1.png'], payout: 80 },
            { name: "TRIPLE PIZZA", images: ['/slots2.png', '/slots2.png', '/slots2.png'], payout: 50 },
            { name: "DOUBLE PIZZA", images: ['/slots2.png', '/slots2.png'], payout: 40 },
            { name: "SINGLE WIN", images: ['/slots1.png'], payout: 20 },
            { name: "TRIPLE REKT", images: ['/slots3.png', '/slots3.png', '/slots3.png'], payout: -200 },
            { name: "DOUBLE REKT", images: ['/slots3.png', '/slots3.png'], payout: -100 },
            { name: "SINGLE REKT", images: ['/slots3.png'], payout: -20 },
            { name: "NO MATCH", images: ['/slots4.png', '/slots5.png', '/slots6.png'], payout: -50 }
        ];

        return (
            <div className="flex flex-col h-[346px] relative">
                <div className="overflow-y-auto flex-grow px-6" 
                     style={{ maxHeight: 'calc(100% - 100px)' }}>
                    <div className="space-y-4 py-4">
                        {winningCombinations.map((combo, index) => (
                            <div key={index}>
                                <h3 className="text-white font-luckiest-guy text-base mb-2 text-center">
                                    {combo.name}
                                </h3>
                                <div className="flex items-center justify-between bg-black bg-opacity-50 p-4 rounded">
                                    <div className="flex items-center gap-4">
                                        {combo.images.map((img, i) => (
                                            <img 
                                                key={i} 
                                                src={img} 
                                                alt={combo.name} 
                                                className="w-12 h-12 object-contain"
                                            />
                                        ))}
                                    </div>
                                    <span className={`font-luckiest-guy text-2xl ${combo.payout > 0 ? 'text-[#00ff00]' : 'text-red-500'}`}>
                                        {combo.payout > 0 ? '+' : ''}{combo.payout}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="absolute bottom-[30px] left-0 right-0 p-3 bg-transparent">
                    <button
                        onClick={() => setShowPayTable(false)}
                        className="w-full bg-[#2f7821] hover:bg-[#246119] text-white font-luckiest-guy 
                                 py-2 px-6 rounded-full transition-all duration-300"
                    >
                        BACK TO GAME
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className="h-full overflow-y-auto touch-pan-y">
            <div className="flex-grow flex items-center justify-center p-4">
                <div className="py-6 px-1 rounded-lg shadow-md max-w-md w-full" 
                    style={{ background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.0), rgba(245, 247, 250, 0.0))' }}>
                    
                    <h2 className="text-3xl font-luckiest-guy text-center text-white mb-4">
                        PEPERUNEY SLOTS
                    </h2>

                    <div className="flex justify-center mb-6">
                        <div className="inline-flex items-center space-x-2">
                            <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center">
                                <img 
                                    src="peperuney-token.png" 
                                    alt="Token" 
                                    className="w-8 h-8"
                                />
                            </div>
                            <div className="flex items-center h-8">
                                <span 
                                    className="text-[#00ff00] font-luckiest-guy text-3xl points-display"
                                    style={{
                                        lineHeight: 1,
                                        height: '32px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    {slotsStatus?.current_points?.toLocaleString() || '0'}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center mb-6">
                        <div className="slot-machine-container relative">
                            <div className="slot-machine-bg"></div>
                            <div id="slot-machine" className="relative z-20">
                                {showPayTable ? (
                                    <PayTable />
                                ) : (
                                    <>
                                        <div className="slots-container">
                                            <div className="slot">
                                                <div className="slot-wrapper">
                                                    {slotItems.reel1.map((imagePath, index) => (
                                                        <div key={index} className="slot-item">
                                                            <img 
                                                                src={imagePath} 
                                                                alt={`Slot Item ${index + 1}`} 
                                                                className="w-full h-full object-contain" 
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="slot">
                                                <div className="slot-wrapper">
                                                    {slotItems.reel2.map((imagePath, index) => (
                                                        <div key={index} className="slot-item">
                                                            <img 
                                                                src={imagePath} 
                                                                alt={`Slot Item ${index + 1}`} 
                                                                className="w-full h-full object-contain" 
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="slot">
                                                <div className="slot-wrapper">
                                                    {slotItems.reel3.map((imagePath, index) => (
                                                        <div key={index} className="slot-item">
                                                            <img 
                                                                src={imagePath} 
                                                                alt={`Slot Item ${index + 1}`} 
                                                                className="w-full h-full object-contain" 
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mb-2">
                                            <span className="text-white" style={{ fontFamily: 'Palmer Lake', fontSize: '32px' }}>
                                                Daily Spins: {slotsStatus.spins_remaining} / 100
                                            </span>
                                        </div>
                    
                                        <button 
                                            onClick={handleSpin}
                                            disabled={isSpinning}
                                            className="spin-button bg-[#2f7821] hover:bg-[#246119] text-white font-luckiest-guy 
                                                   py-2 px-6 rounded-full transition-all duration-300 mt-2"
                                        >
                                            {isSpinning ? 'SPINNING...' : 'SPIN'}
                                        </button>

                                        <div className="absolute top-[17rem] right-4">
                                            <button 
                                                onClick={() => setShowPayTable(true)}
                                                className="bg-[#ffd700] hover:bg-[#ffed4a] text-[#4a3810] font-palmer-lake 
                                                         py-1.5 px-4 rounded-full transition-all duration-300 text-base"
                                            >
                                                PAY TABLES
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="absolute inset-0 z-30 pointer-events-none">
                                {showWinAnimation && <WinAnimation amount={payoutAmount} />}
                                {showLossAnimation && <LossAnimation amount={Math.abs(payoutAmount)} />}
                            </div>
                        </div>
                    </div>

                    {error && (
                        <div className="text-red-500 text-center mb-4 font-luckiest-guy">
                            {error}
                        </div>
                    )}
                </div>
            </div>
            <Footer uncompletedTasksCount={uncompletedTasksCount} />
            </div>
    );
};

export default Slots;